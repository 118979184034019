const riding = {
  selected: 'selected',
  ridingCategories: 'cycling',
  name: 'workout',
  setCourse: 'set course',
  fewCourse: 'Courses',
  addCourses: 'add Courses',
  formRecordChoose: 'select from record workouts',
  formPreviousChoose: 'select from past workouts',
  sort: 'sort',
  courseSort: 'sort',
  shelf2: 'Hidden',
  shelf1: 'Show up',
  shelf0: 'Hidden',
  shelf: 'no Status',
  freeTrial: 'free',
  specialCourses: 'special Courses',
  abdominalWheel: '健腹轮',
  name1: 'name1',
  cover: 'cover',
  creatTime:'creat date',
  pleaseInput: 'input ',
  inputUserName: 'input user name',
  creat: 'Add',
  creatRidingCourse: 'Creat cycling Course',
  courseName: 'workout:',
  status: 'Status:',
  duration: 'duration:',
  creatCourse: 'Creat Course',
  distributionLanguages: "Distribution languages",
  superClearAddress: 'Ultra-clear image quality address',
  HD_address: 'HD quality address',
  SD_address: 'SD address',
  smoothAddress: 'Smooth picture quality address',
  disLanguageMsg: 'Please select the language of distribution',
  free: 'free',
  recordCourseImage: 'Picture',
  superClearAddressPlaceholder: 'Ultra clear video  address',
  HD_addressPlaceholder: 'HD video address',
  SD_addressPlaceholder: 'SD video address',
  smoothAddressPlaceholder: 'Fluent video address',
  tableData: [
    { className: 'Exercise' },
    { className: 'Yoga' },
    { className: 'cycling' },
    { className: 'running' }
  ],
  tableColumn: [
    { label: 'Id', prop: 'id', type: 'normal' },
    { label: 'Fitness type', prop: 'categoryName', type: 'normal' },
    { label: 'Workout', prop: 'courseName', type: 'normal' },
    { label: 'Level', prop: 'courseLevel', type: 'normal' },
    { label: 'Duration', prop: 'courseDuration', type: 'normal' },
    { label: 'Create Time', prop: 'createTime', type: 'normal' }
  ],
  searchData: [
    {
      label: 'level',
      prop: 'level',
      type: 'select',
      option: [
        { label: 'all', value: '' },
        { label: 'primary', value: 1 },
        { label: 'intermediate', value: 2 },
        { label: 'senior', value: 3 }
      ]
    },
    {
      label: 'Status',
      prop: 'shelf',
      type: 'select',
      option: [
        { label: 'all', value: '' },
        { label: 'Hidden', value: 2 },
        { label: 'Show up', value: 1 },
        { label: 'Hidden', value: 0 }
      ]
    },
    {
      label: 'Payment method',
      prop: 'group_type',
      type: 'select',
      option: [
        { label: 'all', value: '' },
        { label: 'members', value: 1 },
        { label: 'free', value: 2 }
      ]
    },
    { label: 'id', prop: 'id' },
    { label: 'workout', prop: 'group_name' },
    {
      label: 'distributed',
      prop: 'distrib_typeList',
      type: 'select',
      option: [
        { label: 'all', value: '' },
        { label: 'free', value: 1 },
        { label: 'special course', value: 2 },
        { label: '健腹轮', value: 3 }
      ]
    }
  ],
  tableColumn_2: [
    { label: 'workout', prop: 'name', type: 'normal' },
    { label: 'Status', prop: 'status', type: 'normal' },
    { label: 'Distribution languages', prop: 'languageAreas', type: 'tag' },
    { label: 'duration', prop: 'duration', type: 'normal' },
    {
      label: 'operation',
      prop: 'action',
      type: 'button',
      button: [
        { label: 'check', type: 'primary' },
		{label: "edit", type: "primary"},
      ],
      method: ["_check","_edit"]
    }
  ],
  message: {
    selectAddData: 'Please select the data first',
    inputUserName: 'input user name',
    cantelDelete: 'Cancel Delete'
  },
  confirm: {
    title: 'info',
    deleteAllCourses: 'Are you sure to delete the selected course?'
  }
}
export default riding
