import Layout from '@/layout'
const EmotionalCopywriting = [
	{
		path: '/emotionalCopywriting',
		name: 'EMOTIONAL_COPYWRITING',
		component: Layout,
		redirect: '/emotionalCopywriting/list',
		meta: {
			title: 'emotionalCopywriting',
			icon: 'table'
		},
		children: [{
			path: '/emotionalCopywriting/list',
			name: 'EMOTIONAL_COPYWRITING_LIST',
			hidden: true,
			meta: {
				title: 'emotionalCopywritingList',
				icon: 'table'
			},
			component: () => import('@/views/emotionalCopywriting/list')
		}]
	}
]

export default EmotionalCopywriting