import Layout from '@/layout'
const Record = [
  // {
  //   path: '/record',
  //   name: 'RECORD_COURSE',
  //   // component: Layout,
  //   meta: {
  //     title: 'record',
  //     icon: 'user'
  //   },
  //   redirect: '/record/list',

   
  // },
  // children: [
    {
      path: '/record/list',
      name: 'RECORD_LIST',
      meta: {
        title: 'record',
        icon: 'user',
        noCache: true
      },
      component: () => import('@/views/record'),
    },
    {
      path: '/record/create',
      name: 'RECORD_CREATE_COURSE',
      hidden: true,
      meta: {
        title: 'createcourse',
        icon: 'user',
        noCache: true
      },
      component: () =>
        import('@/views/livecourses/createcourse/index'),
    },
    {
      path: '/record/check',
      name: 'RECORD_COURSE_DETAIL',
      hidden: true,
      meta: {
        title: 'viewCourse',
        icon: 'user',
        noCache: true
      },
      component: () =>
        import('@/views/reviewedcourses/coursedetails/index'),
    },
    {
      path: '/record/edit',
      name: 'LIVE_COURSES_EDIT',
      hidden: true,
      component: () =>
      import('@/views/livecourses/editcourse/index'),
      meta: {
      title: 'editcourse',
      icon: 'table'
      }
    },
    {
      path:'/record/addCourseListUsed/:id',
      name:'ADD_THEME_COURSE_USED',
      hidden:true,
      meta:{
          title: 'addThemeCourseUsed',
          icon: 'user',
          noCache: true
        },
      component:()=> import('@/views/record/addCourseListUsed'),
    },
  // ]
]

export default Record
