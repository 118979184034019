import Layout from '@/layout'
const Bannermanagement = [{
	path: '/bannermanagement',
	name: 'BANNER_MANAGER',
	component: Layout,
	redirect: '/homepagebanner',
	meta: {
		title: 'bannermanagement',
		icon: 'table'
	},
	children: [{
			path: '/homepagebanner',
			name: 'HOMEPAGEBANNER',
			meta: {
				title: 'homepagebanner',
				icon: 'table'
			},
			component: () => import('@/views/bannermanagement/homepagebanner/index'),
			redirect: '/homepagebanner/list'
		},
		{
			path: '/homepagebanner/list',
			name: 'HOMEPAGEBANNER',
			meta: {
				title: 'homepagebanner',
				icon: 'table'
			},
			hidden: true,
			component: () => import('@/views/bannermanagement/homepagebanner/bannerlist/index')
		},
		{
			path: '/homepagebanner/creatbanner',
			name: 'CREATBANNER',
			hidden: true,
			component: () => import('@/views/bannermanagement/homepagebanner/creatbanner/index'),
			meta: {
				title: 'creatbanner',
				icon: 'table'
			}
		},
		{
			path: '/homepagebanner/createlivebanner',
			name: 'CREATLIVEBANNER',
			hidden: true,
			component: () => import('@/views/bannermanagement/homepagebanner/creatbanner/createlivebanner'),
			meta: {
				title: 'creatbanner',
				icon: 'table'
			}
		},
		{
			path: '/homepagebanner/editbanner',
			name: 'EDITBANNER',
			hidden: true,
			component: () => import('@/views/bannermanagement/homepagebanner/editbanner/index'),
			meta: {
				title: 'editbanner',
				icon: 'table'
			}
		},
		{
			path: '/homepagebanner/bannerdetail',
			name: 'BANNERDETAIL',
			hidden: true,
			component: () => import('@/views/bannermanagement/homepagebanner/detail/index'),
			meta: {
				title: 'bannerdetail',
				icon: 'table'
			}
		},
		// {
		// 	path: '/articlemanagement',
		// 	name: 'ARTICLEMANAGEMENT',
		// 	meta: {
		// 		title: 'articlemanagemer',
		// 		icon: 'table'
		// 	},
		// 	component: () => import('@/views/bannermanagement/article/index'),
		// 	redirect: '/articlemanagement/list',
		// 	children: [

		// 	]
		// },
		// {
		// 	path: '/articlemanagement/list',
		// 	name: 'ARTICLELIST',
		// 	meta: {
		// 		title: 'articlelist',
		// 		icon: 'table'
		// 	},
		// 	hidden: true,
		// 	component: () => import('@/views/bannermanagement/article/list/index')
		// },
		// {
		// 	path: '/articlemanagement/created',
		// 	name: 'CREATARTICLE',
		// 	hidden: true,
		// 	component: () => import('@/views/bannermanagement/article/create/index'),
		// 	meta: {
		// 		title: 'creatarticle',
		// 		icon: 'table'
		// 	}
		// },
		// {
		// 	path: '/articlemanagement/edit',
		// 	name: 'EDITEARTICLE',
		// 	hidden: true,
		// 	component: () => import('@/views/bannermanagement/article/edit/index'),
		// 	meta: {
		// 		title: 'editarticle',
		// 		icon: 'table'
		// 	}
		// },
		// {
		// 	path: '/articlemanagement/detail',
		// 	name: 'ARTICLEDETAIL',
		// 	hidden: true,
		// 	component: () => import('@/views/bannermanagement/article/detail/index'),
		// 	meta: {
		// 		title: 'articledetail',
		// 		icon: 'table'
		// 	}
		// },
		{
			path: '/articlemanagement/creattab',
			name: 'ADVERTISEMENT_CREAT',
			/*创建tab*/
			hidden: true,
			meta: {
				title: 'creatarticle',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/bannermanagement/homepagebanner/creattab/index'),
		},
		{
			path: '/articlemanagement/edittab',
			name: 'ADVERTISEMENT_EDIT',
			/*编辑tab*/
			hidden: true,
			meta: {
				title: 'editarticle',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/bannermanagement/homepagebanner/edittab/index'),
		},
		// {
		// 	path: '/userstory/list',
		// 	name: 'USER_STORY_LIST',
		// 	component: () => import('@/views/bannermanagement/userStory/index'),
		// 	meta: {
		// 		title: 'userstory',
		// 		/*用户故事*/
		// 		icon: 'example'
		// 	}
		// },
		// {
		// 	path: '/userstory/creat',
		// 	name: 'USER_STORY_CREAT',
		// 	hidden: true,
		// 	component: () => import('@/views/bannermanagement/userStory/creat/index'),
		// 	meta: {
		// 		title: 'creatUserStory',
		// 		icon: 'example'
		// 	}
		// },
		// {
		// 	path: '/userstory/edit',
		// 	name: 'USER_STORY_EDIT',
		// 	hidden: true,
		// 	component: () => import('@/views/bannermanagement/userStory/edit/index'),
		// 	meta: {
		// 		title: 'editUserStory',
		// 		icon: 'example'
		// 	}
		// },
		// {
		// 	path: '/qrcode/list',
		// 	name: 'QR_CODE_LIST',
		// 	component: () => import('@/views/bannermanagement/qrCode/index'),
		// 	meta: {
		// 		title: 'qrCodeManagement',
		// 		/*二维码管理*/
		// 		icon: 'example'
		// 	}
		// },
		// {
		// 	path: '/qrcode/creat',
		// 	name: 'QR_CODE_CREAT',
		// 	component: () => import('@/views/bannermanagement/qrCode/creat/index'),
		// 	hidden: true,
		// 	meta: {
		// 		title: 'qrCodeCreat',
		// 		icon: 'example'
		// 	}
		// },
		// {
		// 	path: '/qrcode/edit/:id',
		// 	name: 'QR_CODE_EDIT',
		// 	component: () => import('@/views/bannermanagement/qrCode/edit/index'),
		// 	hidden: true,
		// 	meta: {
		// 		title: 'qrCodeEdit',
		// 		icon: 'example'
		// 	}
		// },
	]
}, ]

export default Bannermanagement
