import Layout from "@/layout";
//内容分发

const RecommendedCourse = [
  {
    path: '/contentDistribution',
    name: 'CONTENT_DISTRIBUTION',
    component: Layout,
    meta: {
      title: 'ContentDistribution',
      icon: 'table'
    },
    redirect: '/recommendedCourse/list',
    children: [
      {
        path: '/recommendedCourse/list',
        name: 'Recommended_Course',
        meta: {
          title: 'recommended_courses',
          icon: 'table'
        },
        hidden: false,
        component: () => import('@/views/RecommendedCourse/list/index')
      },
      {
        path: '/recommendedCourse/import_course',
        name: 'Recommended_Course',
        meta: {
          title: 'import_course',
          icon: 'table'
        },
        hidden: true,
        component: () => import('@/views/RecommendedCourse/importCourse/index')
      },
      /*{
        path: '/themePackage',
        name: 'THEME_CHECK_COURSE',
        hidden: false,
        meta: {
          title: 'themePackage',
          icon: 'table',
          noCache: true
        },
        component: () =>
          import('@/views/themePackage/list/index'),
      },
      {
        path: '/themePackage/create',
        name: 'THEME_CHECK_COURSE',
        hidden: true,
        meta: {
          title: 'createPackageCourse',
          icon: 'table',
          noCache: true
        },
        component: () =>
          import('@/views/themePackage/create/index'),
      },
      {//专题课包-配置课程
        path: '/themePackage/addCourse',
        name: 'THEME_CHECK_COURSE',
        hidden: true,
        meta: {
          title: 'addCourse',
          icon: 'table',
          noCache: true
        },
        component: () =>
          import('@/views/themePackage/importCourse/addCourse'),
      },
      {//专题课包-配置课程-导入
        path: '/themePackage/importCourse',
        name: 'THEME_CHECK_COURSE',
        hidden: true,
        meta: {
          title: 'import_course',
          icon: 'table',
          noCache: true
        },
        component: () =>
          import('@/views/themePackage/importCourse/importCourse'),
      },
      {//专题课包-编辑
        path: '/themePackage/edit',
        name: 'THEME_CHECK_COURSE',
        hidden: true,
        meta: {
          title: 'edit_course',
          icon: 'table',
          noCache: true
        },
        component: () =>
          import('@/views/themePackage/edit/index'),
      }*/
    ]
  }
]

export default RecommendedCourse
