import Layout from '@/layout'
const UpdatePush = [
	{
		path: '/updatePush',
		name: 'UPDATE_PUSH',
		component: Layout,
		redirect: '/updatePush/list',
		meta: {
			title: 'updatePush',
			icon: 'table'
		},
		children: [{
			path: '/updatePush/list',
			name: 'UPDATE_PUSH_LIST',
			hidden: true,
			meta: {
				title: 'updatePushList',
				icon: 'table'
			},
			component: () => import('@/views/updatePush/list')
		}]
	}
]

export default UpdatePush