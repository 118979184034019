import {
  getLevelList
} from "@/api/common"
import i18n from '@/lang'


const state = {
  levelType: []
}

const mutations = {
  SET_LEVEL_TYPE: (state, data) => {
    state.levelType = data
  },
}

const actions = {
  getLevelType ({ commit }) {
    if (state.levelType.length) {
      return new Promise(resolve => {
        resolve(state.levelType)
      })
    } else {
      return new Promise((resolve, reject) => {
        getLevelList().then(res => {
          if (res.code == 'success') {
            res.data.items.unshift({
              code: i18n.t('actionbuttons.allSelect'),
              id: ''
            })
            commit("SET_LEVEL_TYPE", res.data.items)
            resolve(res.data.items)
          }
        })
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
