import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasRoles = false// store.getters.roles && store.getters.roles.length > 0
      const rolesMenu=await store.dispatch('permission/getRolesMenu');
      console.log('roles后台获取',rolesMenu);
      const accessRoutes = await store.dispatch('permission/generateRoutes', rolesMenu);
      console.log('路由过滤', accessRoutes) 
       // store.dispatch('theme/getClassTypeData')
       store.dispatch('common/getLevelType');
      next()
      NProgress.done()
      // if (hasRoles) {
      //   next()
      // } else {
      //   try {
      //     // get user info
      //     // const { items } = await store.dispatch('user/getInfo')
      //     // console.log('user role==========', items)
      //     const roles = info // items 权限数据
      //     const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
      //     console.log(accessRoutes)
      //     next()
      //     // router.addRoutes(accessRoutes)
      //     // next({ ...to, replace: true })
      //   } catch (error) {
      //     // remove token and go to login page to re-login
      //     await store.dispatch('user/resetToken')
      //     Message.error(error || 'Has Error')
      //     next(`/login?redirect=${to.path}`)
      //     NProgress.done()
      //   }
      // }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
