import request from '@/utils/request'
import { base } from './userBase'

export function login(data) {
  const params = {
    userName: data.user_name,
    password: data.password,
    timestamp: data.timestamp
  }

  return request({
    url: `${base}/users/login`,
    method: 'post',
    data: params
  })
}

export function getInfo(token) {
  return request({
    url: '/user/info',
    // url: 'http://192.168.1.223:9081/v0.1/authorizations/resources?biz=ALL',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}
/* 获取菜单数据 */
export function getRolesMenu(params){
  return request({
    url:'/authorizations/resources',
    method: 'get',
    params
  })
}
/* 角色增删改 */
export function getRolesList(params){
  return request({
    url:'/roles',
    method: 'get',
    params
  })
}

export function addRolesList(data){
  return request({
    url:'/roles',
    method: 'post',
    data
  })
}

export function putRoleList(data,id){
  return request({
    url:`/roles/${id}`,
    method:'put',
    data
  })
}

export function deleteRoleList(id){
  return request({
    url:`/roles/${id}`,
    method:'delete'
  })
}

/*角色关联资源相关接口*/

export function getRoleMenuList(id){
  return request({
    url:`/role_resources/${id}`,
    method: 'get'
  })
}

export function getAllRoleMenuList(id){
  return request({
    url:`/role_resources/${id}`,
    method: 'get',
    params:{
    type:"stack"
    }
  })
}

export function setRoleMenu(data){
  return request({
    url:'/role_resources',
    method: 'put',
    data
   })
}


/* 管理员增删改 */

export function getManagerList(params){
  return request({
    url:'/sysadmins',
    method: 'get',
    params
  })
}

export function addManagerList(data){
   return request({
    url:'/sysadmins',
    method: 'post',
    data
   })
}

export function putManagerList(data,id){
  return request({
    url:`/sysadmins/${id}`,
    method:'put',
    data
   })
}

export function deleteManagerList(id){
  return request({
    url:`/sysadmins/${id}`,
    method:'delete'
  })
}

/* 资源增删改 */

export function getMenuList(params){
  return request({
    url:'/resources',
    method: 'get',
    params
  })
}

export function addMenuList(data){
  return request({
    url:'/resources',
    method: 'post',
    data
   })
}

export function deleteMenuList(id){
  return request({
    url:`/resources/${id}`,
    method:'delete'
  })
}
export function putMenuList(data,id){
  return request({
    url:`/resources/${id}`,
    method:'put',
    data
  })
}

/*用户关联角色接口*/
export function getUserRoleList(id){
  return request({
    url:`/user_roles/${id}/roles`,
    method: 'get'
  })
}

export function addUserRoleList(data,id){
  return request({
    url:`/user_roles/${id}`,
    method: 'post',
    data
  })
}

export function deleteUserRoleList(data,id){
  return request({
    url:`/user_roles/${id}`,
    method: 'patch',
    data
  })
}

export function resetPassword(id){
  return request({
    url:`/sysadmins/reset/${id}`,
    method: 'patch'
  })
}

/*用户关联资源*/

export function getUserMenuList(params,id){
  return request({
    url:`/user_resources/${id}/resources`,
    method: 'get',
    params
  })
}

export function setAccountMenu(data){
  return request({
    url:'/user_resources',
    method: 'put',
    data
   })
}

/*用户修改密码*/
export function putPassword(data){
  return request({
    url:'/sysadmins/set/pwd',
    method: 'patch',
    data
   })
}
