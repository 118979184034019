import Layout from '@/layout'
const Permission = [
  {
    path: '/manager',
    name: 'PERMISSION',
    component: Layout,
    meta: {
      title: 'system',
      icon: 'user'
    },
    redirect: '/manager/accountManager',
    children: [
      {
        path: 'accountManager',
        name: 'ACCOUNT_MANAGER',
        meta: {
          title: 'account',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/manager/accountManager'),
      },
      {
        path: 'permissionManager',
        name: 'PERMISSION_MANAGER',
        meta: {
          title: 'permission',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/manager/permissionManager'),
      },
      {
        path: 'menuManager',
        name: 'MENU_MANAGER',
        meta: {
          title: 'menuManager',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/manager/menuManager'),
      }
    ]
  }
]

export default Permission
