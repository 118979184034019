/* eslint-disable no-dupe-keys */
import RecommendedCourse from "./zh_model/RecommendedCourse";
import reducedFatModel from "../lang/zh_model/fatburn";
import bbsqianlongModel from "../lang/zh_model/bbsqianlong";
import programList from "../lang/en_model/programList";
import faqmaneger from "../lang/en_model/faqmaneger";
import riding from "../lang/en_model/riding";

export default {
	app: {
	  title: 'Y_Drag'
	},
	header: {
	  chineseDocument: '中文文档',
	  englishDocument: '英文文档',
	  chat: '交流群', //  Discussion Group
	  logout: '退出登录'
	},
	sidebar: {
	  myWorks: '我的作品',
	  dataCenter: '数据中心',
	  basicData: '基础数据',
	  templateCenter: '模板中心',
	  freeTemplates: '模板列表',
	  accountCenter: '账号中心'
	},
	editor: {
	  sidebar: {
	    components: '组件',
	    pages: '页面',
	    templates: '模板',
	    tree: '组件树',
	    preferences: '偏好'
	  },
	  pageManager: {
	    title: '第{index}页面',
	    action: {
	      add: '新增页面',
	      copy: '复制页面',
	      delete: '删除页面'
	    }
	  },
	  header: {
	    preview: '预览',
	    save: '保存',
	    publish: '发布',
	    setAsTemplate: '设置为模板'
	  },
	  centerPanel: {
	    mode: {
	      edit: 'Edit',
	      preview: 'Preview'
	    },
	    contextMenu: {
	      copy: '复制',
	      delete: '删除',
	      moveToTop: '置顶',
	      moveToBottom: '置底',
	      moveUp: '上移',
	      moveDown: '下移',
	      showOnlyButton: '只有按钮才显示该选项',
	      showExcludePicture: '除了图片都显示该选项'
	    }
	  },
	  fixedTool: {
	    undo: '撤销{hotkey}',
	    redo: '重做{hotkey}',
	    preview: '预览',
	    copyCurrentPage: '复制当前页面',
	    copyCurrentElement: '复制当前元素',
	    importPSD: '导入PSD',
	    zoomIn: '缩小{hotkey}',
	    zoomOut: '放大{hotkey}',
	    issues: '常见问题',
	    poster: '下载海报'
	  },
	  editPanel: {
	    tab: {
	      prop: '属性',
	      animation: '动画',
	      action: '动作',
	      script: '脚本',
	      page: '页面'
	    },
	    animation: {
	      add: '添加动画',
	      run: '运行动画',
	      type: '动画类型',
	      duration: '动画时长',
	      delay: '动画延时',
	      iteration: '运行次数',
	      inifinite: '循环播放',
	      list: '选择动画',
	      title: 'Animation {index}'
	    },
	    method: {
	      add: '使用技能(动作)',
	      run: '运行动作',
	      type: '动作类型',
	      duration: '动作时长',
	      delay: '动作延时',
	      iteration: '运行次数',
	      inifinite: '循环播放',
	      list: '选择动作',
	      title: '动作 {index}'
	    },
	    common: {
	      empty: 'Select an element first please!',
	      ConfigProps: '属性设置',
	      commonStyle: '通用样式',
	      pageMode: '页面模式'
	    }
	  },
	  tip: {
	    componentUsage: '使用提示: {0} 组件即可',
	    click: '点击或拖拽'
	  }
	},
	messages: {
		fileUploadError: "Fail to upload"
	},
	route: {
		dragMailbox:'动态拖拽',
		dragMailboxList:'动态拖拽列表',
		editor:'拖拽',
		login: "Login",
		paymentStrategy:'付费策略配置',//2.3.1新增
		exchangeCode:'兑换码',//2.3.2新增
		paidInterestManagement:"会员权益配置管理",//2.2.7
		equityManagement:"订阅/商品配置管理",//2.2.7  2.3.1变更为 =>  订阅/商品配置管理
		discountActivities:"权益包折扣活动管理",//2.2.7
		userTagManagement:"用户社交TAG管理", //2.2.7
		userTagManagementList:"TAG列表", //2..7
		emotionalCopywriting:"情感化文案-配置",//2.2.7
		emotionalCopywritingList:"情感化文案列表",//2.2.7
		updatePush:"更新推送",//2.2.7
		updatePushList:"更新推送",//2.2.7
		pushNotice:"PUSH通知",//2.3.3新增
		pushNoticeList:"PUSH通知",//2.3.3新增
		courseLabel:"课程标签",//2.2.8
		courseLabelDimension:"课程标签维度",//2.2.8
		courseLabelList:"课程标签列表",//2.2.8
		dashboard: "Home page",
		recommended_courses: "推荐课程管理",
		documentation: "documentation",
		theme: "Theme workouts",
		sharemaneger: "share",
		shareImage: "Image",
		shareText: "Text",
		permission: "Permission",
		courseConfig: "Course config",
		checkTheme: "check the theme",
		riding: "Live-action riding",
		menuManager: "Menu manage",
		addThemeCourseRecord: "Add course form record workouts",
		addThemeCourseUsed: "Add from previous lessions",
		addDetailType: "Add type",
		editDetailType: "Edit type",
		record: "record workouts",
		account: "Account",
		system: "System manage",
		baseConfig: "Base config",
		target: "Traget",
		type: "Type",
		eduAction: "workout ontes action",
		classType: "Class type",
		guide: "Guide page",
		userManeger: "User Manage",
		putPassword: "Edit password",
		tagsMnager: "Tag manage",
		usertag: "User tag",
		Series: "Series",
		classtag: "Class tag",
		classtaglist: "Class tag list",
		coachmanager: "Coach manage",
		coachlist: "Coach list",
		coachAdd: "Add coach",
		configmanager: "Coach manage",
		editmanager: "Edit coach",
		coachingstafflist: "Coach group",
		creatcoachingstaff: "Add coach group",
		editcoachingstaff: "Edit the coach group",
		coachdetails: "Coach details",
		exchangecodemaneger: "Exchange code manage",
		autoBikeManagement: "自由骑行管理",
		exchangecodelist: "Exchange code list",
		exchangecodeentry: "Add exchange code",
		defaultSettingsTitle: "yesoul admin",
		sncodemaneger: "SN manage",
		memberCommodityManagement:"Member commodity management",//2.2.4新增
		memberDiscountActivities:"Member discount activities",//2.2.4新增
		sncodelist: "SN list",
		sncodeentry: "Add SN",
		pushmanager: "Push manage",
		grouplist: "Group list",
		getGroupList: "Check the group list",
		creatGroup: "Add group",
		groupDetail: "Group detail",
		pushlist: "Push list",
		push_list: "Check push list",
		pushcreated: "Add push",
		seeThePush: "Check the push",
		pushdatalist: "Push data",
		userlist: "User list",
		userdetail: "User detail",
		editGroup: "Edit userfile",
		addcoursecategory: "Add class type",
		typeview: "check the type",
		editclassify: "Edit the workout type",
		livecourses: "Class manage",
		livecourselist: "Live workouts list",
		createcourse: "Add live workout ",
		editcourseRiding: 'Edit the live workout',
		recyclielist: "Recyclie list",
		viewCourse: "Check the workout",
		programManagement: "Schedule",
		reviewedCourses: "Past workouts",
		reviewedlist: "Past workouts list",
		subtitleConfig:'字幕配置',//2.3.2新增
		creatreviewedcourse: "Add past workout",
		editreviewedcourse: "Edit past workout",
		/*coursemanagement: 'Course manage',*/
		typeofcourse: "Course type",
		coursemanagementlist: "Course list",
		creatOldCourse: "Add workout",
		coursePhase: "workout Phase:",
		coursemanagement: "workout manage",
		editcourse: "Edit ",
		teachingCourse: "Tutorial",
		newcomer: "workout for new user",
		creatNewcomer: "Add",
		editNewcomer: "Edit",
		instrumentTutorials: "Direction for use",
		faqmaneger: "Feedback",
		faqmanegerOld: "Feedback（old）",
		faqmanegerNew: "Feedback（new）",
		recycleBin: "Recycle bin",
		bannermanagement: "Operations manage",
		homepagebanner: "Banner manage",
		articlemanagemer: "Content manage",
		userstory: "User story",
		creatUserStory: "Add user story",
		editUserStory: "Edit the story",
		creatbanner: "Add banner",
		editbanner: "Edit the banner",
		bannerdetail: "banner detail",
		creatarticle: "Add content",
		editarticle: "Edit the content",
		articledetail: "Content detail",
		articlelist: "Content list",
		visualmanagement: "数据分析",
		retainedanalysis: "留存分析",
		activeanalysis: "活跃分析",
		payforanalysis: "付费分析",
		lossanalysis: "流失分析",
		creatEquipmentCourse: "器械教程-创建",
		editEquipmentCourse: "器械教程-编辑",
		scoreSet: "得分范围设置",
		qrCodeManagement: "QR Code Manage",
		qrCodeCreat: "add QR code",
		qrCodeEdit: "edit QR code",
		challengeActivities:"Challenge",//2.2.5新增
		activityManagement:"Event",//2.2.5新增
		createChallengeActivity:'Create Challenge Event Management'//2.2.5新增
	},
	btn: {
		ok: "Confirm",
		Cancel: "Cancel", //$t('btn.Cancel')
		save: "Submit", //$t('btn.ok')
		shelf1: "Show up",
		shelf0: "Hidden",
		reset: "Reset",
		submit: "Submit",
		search: "Search",
		/*through: 'Pass',
		noThrough: 'Fail',*/
		reply: "reply",
		viewReply: "Check the reply",
		delete: "Delete",
		return: "Return"
	},
	timeUnit: {
		weekmap: [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday"
		],
		minutesAgo: "minutes ago",
		hoursAgo: "hours ago",
		justNow: "Just now",
		oneDayBefore: "one day before",
		year: "year",
		month: "month",
		day: "day",
		hour: "hour",
		minutes: "minute",
		seconds: "second",
		hours: "hours",
		minutesUnit: " min"
	},
	components: {
		accountDialog: {
			userName: "User name",
			inputUserName: "Input the user name",
			remarks: "remarks",
			inputPosition: "input the Position",
			phoneNumber: "Phone number:",
			inputPhoneNumber: "input the phone number",
			inputPhoneNumber2: "input the phone number",
			inputCorrectPhoneNumber: "check the phone number",
			status: "status",
			selectStatus: "select the status",
			superAdministrator: "Super Administrator",
			pleaseSelect: "choose one",
			operation: "operation",
			SNCodeManager: "SN code Manager",
			enable: "enable",
			disable: "disable",
			yes: "yes",
			no: "no"
		},
		accountmenuDialog: {
			userResource: "userResource"
		},
		accountroleDialog: {
			add: "Add",
			delete: "delete",
			unlinkedRole: "unlinked Role",
			associatedRoles: "associated Roles",
			data: [{
					id: 1,
					label: "一label 1"
				},
				{
					id: 2,
					label: "一label 2"
				},
				{
					id: 3,
					label: "一label 3"
				}
			]
		},
		menuDialog: {
			businessType: "type",
			inputBusinessType: "input the type",
			resourceLocator: "Locator",
			inputResourceLocator: "input the locator",
			resourceName: "resource name",
			inputResourceName: "input the name",
			remarks: "remarks",
			inputRemarks: "input the remarks",
			isRestricted: "restrictions",
			pleaseSelectStatus: "choose the restrictions",
			parentResourceId: "parent Resource Id",
			inpoutParentResourceId: " inpout ParentResource Id",
			sort: "sort",
			inputSort: "input the sort",
			limitOptions: [{
					value: 1,
					label: "受限"
				},
				{
					value: 0,
					label: "非受限"
				}
			]
		},
		passwordDialog: {
			oldPwd: "old passowrd",
			inputOldPwd: "input oldpassword",
			newPwd: "new password",
			inputNewPwd: "input new password",
			confirmTheNewPwd: "confirm new password",
			phoneNumber: "input phone number",
			reset: "reset"
		},
		permissionDialog: {
			roleName: "role name",
			inputRoleName: "input role name",
			remarks: "remarks"
		},
		rolemenuDialog: {
			roleResource: "Resource"
		},
		vueDraggableElUpload: {
			currentUpload: "limit",
			images: "images"
		},
		message: {
			addUserSuccess: "Success",
			pleaseSelectData: "please choose the Data",
			deleteSuccess: "Success",
			airPwdError: "input the password",
			inputPwdAgainError: "input the password again",
			pwdInconsistentError: " the two passwords do not match!"
		}
	},
	activitymanager: {
		defatting: {
			phoneNumber: "phoneNumber",
			inputPhone: "input the phoneNumber",
			auditStatus: "auditStatus",
			pleaseChoose: "pleaseChoose",
			userName: "user name",
			signUpSnCode: "the SN code for sign up",
			signUpTime: "sign up date",
			options: "options",
			viewDetails: "Detail",
			through: "Pass",
			noThrough: "Fail",
			signUpDetails: "Sign Up Details",
			gender: "sex",
			height: "height",
			weight: "weight",
			bmi: "BMI",
			age: "age",
			images: "Picture",
			search: "search",
			male: "male",
			female: "female",
			careerCharacteristics: "Career Characteristics",
			signPicture: "sign up Picture",
			challengePicture: "challenge Picture",
			commitTime: "commit date",
			commitReason: "commit Reason",
			mentalWorker: "mental Worker",
			serviceworker: "service worker",
			manualWorkers: "manual Workers",
			/*actsocial*/
			planName: "Project Name",
			inputPlanName: "input the project name",
			replayStatu: "Statu",
			pleaseSelect: "please choose",
			submitTime: "submit date",
			challengeDays: "challenge days",
			submitContent: "Submitted Content",
			messageDetails: "message",
			submitPerson: "Submitter",
			messageContent: "message",
			replyContent: "reply",
			pleaseEnterContent: "input the content",
			registerInfo: "register Info",
			newInfo: "new info",
			currentPlan: "current Plan",
			targetWeightLoss: "target Weight Loss：",
			targetWeightLossTo: " target Weight Loss To：",
			weightRegistration: "Current weight：",
			basalMetabolism: "Basal metabolism：",
			historyMessages: "history Messages",
			content: "content",
			reply: "reply",
			all: "all",
			waitAudit: "wait Audit",
			responseTime: "response date",

			/*defatting-add*/
			activityName: "activity Name",
			activityDetails: "activity Details",
			activityBackgrountImage: "activity Back grount Image",
			activityDetailImage: "activity Detail Image",
			activityRobotOptions: "activity Robot Options",
			activityRobotNumber: "activity Robot Number",
			investBeansNumber: "invest Beans count",
			allRobotNumber: "all invest beans count",
			completeRobotNumber: "complete Robot Number",
			activityStartSignUpTime: "activity sign up start time",
			activityEndSignUpTime: "activity sign up end time",
			activityStartTime: "activity Start Time",
			ctivityEndTime: "activity end Time",
			challengeDay: "challenge Days",
			selectSignUpStartTime: "select Sign Up Start date",
			selectSignUpEndTime: "select Sign Up end date",
			selectActivityStartTime: "select Activity Start date",
			selectActivityEndTime: "select Activity end date",
			days: "days",
			uploadImageNumber: "upload Image",
			activityCycle: "activity Cycle",
			referralProgram: "referral Program",
			lotterySettings: "lottery Settings",
			challengeSuccess: "Complete the challenge：",
			challengeError: "Fail the challenge：",
			lotteryNumber: "lottery number",
			selectLottery: "select Lottery",
			rewardBeans: "reward Beans",
			rewardBeanNumber: "reward Bean Number",
			/*defatting_list*/
			createActivity: "add Activity",
			activityStatus: "activity Status",
			URLaddress: "URL",
			challenge: "the",
			day: "day"
		},
		btn: {
			success: "success",
			error: "Fail",
			replyNow: "replyNow",
			shutDown: "Close",
			delete: "delete",
			addTo: "add To",
			ok: "confirm",
			cancel: "cancel",
			signUpAudit: "sign Up Audit",
			challengeAudit: "challenge Audit",
			interactiveMessage: "inter active Message",
			lotteryList: "lottery List"
		},
		actapplyState: [{
				value: -1,
				text: "未报名"
			},
			{
				value: 0,
				text: "审核Pass"
			},
			{
				value: 1,
				text: "报名成功"
			},
			{
				value: 2,
				text: "报名失败"
			},
			{
				value: 3,
				text: "报名审核中"
			},
			{
				value: 4,
				text: "审核不通过"
			},
			{
				value: 5,
				text: "提交挑战结果审核"
			}
		],
		actresultState: [{
				value: -1,
				text: "未报名"
			},
			{
				value: 0,
				text: "报名审核通过"
			},
			{
				value: 1,
				text: "挑战成功"
			},
			{
				value: 2,
				text: "挑战失败"
			},
			{
				value: 3,
				text: "报名审核中"
			},
			{
				value: 4,
				text: "报名审核不通过"
			},
			{
				value: 5,
				text: "结果审核中"
			}
		],
		actresultFiltersResult: {
			"-1": "未报名", //'未报名'
			"0": "报名审核通过", //'报名审核通过'
			"1": "结果审核成功", //'结果审核成功',
			"2": "结果审核失败", //'结果审核失败',
			"3": "报名审核中", //'报名审核中',
			"4": "报名审核不通过", //'报名审核不通过',
			"5": "结果审核中" //'结果审核中'
		},
		actsocialStatus: {
			noReply: "noReply",
			replied: "replied"
		},
		defattingListState: {
			all: "all",
			resignation: "resignation",
			inService: "in Service"
		},
		confirm: {
			title: "prompt",
			content: "Are you sure of approval?",
			ok: "confirm",
			cancel: "cancel",
			auditSuccess: "Success",
			auditError: "fail",
			okShutDown: "To shut down??"
		},
		prompt: {
			title: "operation",
			content: "Please enter the reasons for not passing the audit",
			ok: "confirm",
			cancel: "cancel",
			inputErrorMessage: "input Error",
			optionSuccess: "Success",
			optionError: "Fail",
			/*actresult*/
			actresultTitle: "info",
			actresultContent: "Are you sure to perform this operation?",
			auditThrough: "Are you sure to perform this operation?",
			auditNoThrough: "Are you sure to perform this operation?"
		},
		message: {
			submitSuccess: "Submitted successfully",
			submitError: "submit Error",
			messageSuccess: "Success",
			imagePrompt: "Upload images should not be larger than 2MB!",
			modifySuccess: "Modify success",
			addToSuccess: "Add success"
		},
		type: {
			siginUpImage: "sigin Up Image",
			firstWeek: "first Week",
			secondWeek: "second Week",
			thirdWeek: "third Week",
			fourthWeek: "fourth Week"
		}
	},
	livecourses: {
		fatBurning: "Fat burning",
		lung: "Cardiopulmonary",
		endurance: "Endurance",
		power: "Power",
		first: "the",
		paragraph: "paragraph",
		courseEffects: [{
				name: "Fat burning",
				value: "",
				remark: ""
			},
			{
				name: "Cardiopulmonary",
				value: "",
				remark: ""
			},
			{
				name: "Endurance",
				value: "",
				remark: ""
			},
			{
				name: "Power",
				value: "",
				remark: ""
			}
		]
	},
	bannermanagement: {
		tagName: "tag Name:",
		homepagebanner: {
			recommendedSize: "Suggest size:690*320",
			recommendedSize2: "Suggest size:690*140",
			nameAlreadyExists: "The name already exists"
		},
		importanceOptions: {
			all: "all",
			shelf0: "Hidden",
			shelf1: "shelf status",
			shelf2: "Hidden",
			shelf3: "Hidden"
		},
		message: {
			imagePrompt: "Upload images should not be larger than 2MB!"
			//this.$t('bannermanagement.message.imagePrompt')
		}
	},
	/*baseConfig*/
	baseConfig: {
		sectionName: "section",
		targetName: "target",
		actionName: "action",
		categoryName: "class type",
		bigClassName: "fitness type",
		creatUser: "creator",
		creatTime: "creat date",
		status: "status",
		configurationItems: "configuration",
		category: "class type",
		lessonPlanAct: "workout notes ",
		serialNumber: "SN",
		inputCategoryName: "input the class type",
		videoIntroduction: "Description",
		VideoCover: "Video cover",
		defaultValue: "defaultValue",
		tag: "TAG",
		scoreOfTheSet: "Score of the set",
		addDefaultValue: "add Default Value",
		courseIntroduction: " introduction",
		inputCourseIntroduction: "input introduction",
		suitablePeople: "Suggestion",
		inputSuitablePeople: "input the Suggestion",
		courseEffect: "Effects",
		Interval: "Interval",
		pleaseSelect: "choose",
		browserCompatible: "Your browser does not support the HTML5 video tag.",
		inputUserName: "input the user name",
		editSection: "edit Section",
		creatSection: "Add Section",
		editAction: "edit Action",
		creatAction: "add action",
		creatAims: "add target",
		editAims: "edit target",
		target: "target",
		targetRequire: "input the target",
		createCategory: "add classtype",
		btn: {
			ok: "confirm",
			Cancel: "cancel"
		},
		effectRanges: [{
				name: "Fat burning",
				remark: "The higher the index, the longer the stay in the fat burning zone",
				value: "1"
			},
			{
				name: "Cardiopulmonary",
				remark: "The higher the index, the higher the intensity of the course",
				value: "1"
			},
			{
				name: "Endurance",
				remark: "The higher the index, the higher the intensity of the course",
				value: "1"
			},
			{
				name: "Power",
				remark: "The higher the index, the more obvious the intensity changes in the course",
				value: "1"
			}
		],
		scoreSet: {
			mode: "mode",
			option: "option",
			extendedValueInterval: "扩展值区间(前端有效得分范围)",
			preExpansionValue: "前置拓展值",
			postExpansionValue: "后置拓展值",
			scoreRangeSet: "set score Range",
			maxGeZero: "The input value must be greater than or equal to 0",
			maxLe100: "The value entered must be less than or equal to 100",
			mustNumber: "The input value must be a number",
			mustInput: "The input value cannot be null"
		},
		/*detailType*/
		searchData: [{
				label: "target",
				prop: "name"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "enable",
						value: 1
					},
					{
						label: "disable",
						value: 0
					}
				]
			}
		],
		searchDataClass: [{
				label: "class type",
				prop: "name"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "enable",
						value: 1
					},
					{
						label: "disable",
						value: 0
					}
				]
			}
		],
		//动作名称
		actionSearchData: [{
				label: "action",
				prop: "word"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "enable",
						value: 1
					},
					{
						label: "disable",
						value: 0
					}
				]
			}
		],
		//小节名称
		sectionSearchData: [{
				label: "section",
				prop: "word"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "enable",
						value: 1
					},
					{
						label: "disable",
						value: 0
					}
				]
			}
		],
		tableData2: [{
				targetName: "Exercise",
				status: false,
				value: ["名称1", "名称2"]
			},
			{
				targetName: "Yoga",
				status: false,
				value: ["名称1", "名称3"]
			},
			{
				targetName: "cycling",
				status: false,
				value: ["名称1", "名称4"]
			},
			{
				targetName: "running",
				status: false,
				value: ["名称1", "名称5"]
			}
		],
		tableColumn2: [{
				label: "分类名称",
				prop: "name",
				type: "normal"
			},
			{
				label: "default value",
				prop: "defaultNameList",
				type: "tag"
			},
			{
				label: "creator",
				prop: "creator",
				type: "normal"
			},
			{
				label: "create time",
				prop: "createTime",
				type: "normal"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "switch"
			},
			{
				label: "operation",
				prop: "action",
				type: "button",
				button: [{
					label: "edit",
					type: "primary"
				}],
				method: ["_edit"]
			}
		],
		statusOptions: [{
				value: 1,
				label: "enable"
			},
			{
				value: 0,
				label: "disable"
			}
		],
		/*editDetailType*/
		/*eduAction*/
		baseData: {
			label: "section",
			prop: "word",
			placeholder: "input section name"
		},
		baseData_2: {
			label: "action",
			prop: "word",
			placeholder: "input action name"
		},
		searchData2: [{
				label: "分类名称",
				prop: "word"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "enable",
						value: 1
					},
					{
						label: "disable",
						value: 0
					}
				]
			}
		],
		tableData3: [{
				targetName: "Exercise",
				status: false
			},
			{
				targetName: "Yoga",
				status: false
			},
			{
				targetName: "cycling",
				status: false
			},
			{
				targetName: "running",
				status: false
			}
		],
		tableColumn3: [{
				label: "section name",
				prop: "word",
				type: "normal"
			},
			{
				label: "creator",
				prop: "creator",
				type: "normal"
			},
			{
				label: "create Time",
				prop: "createTime",
				type: "normal"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "switch"
			},
			{
				label: "operation",
				prop: "action",
				type: "button",
				button: [{
					label: "edit",
					type: "primary"
				}],
				method: ["_editor"]
			}
		],

		/*target*/
		searchData_1: [{
				label: "target",
				prop: "name"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "enable",
						value: 1
					},
					{
						label: "disable",
						value: 0
					}
				]
			}
		],
		tableColumn_4: [{
				label: "target",
				prop: "name",
				type: "normal"
			},
			{
				label: "creator",
				prop: "creator",
				type: "normal"
			},
			{
				label: "createTime",
				prop: "createTime",
				type: "normal"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "switch"
			},
			{
				label: "operation",
				prop: "action",
				type: "button",
				button: [{
					label: "edit",
					type: "primary"
				}],
				method: ["_editor"]
			}
		],
		baseData_1: {
			label: "target",
			prop: "name",
			placeholder: "input the target name"
		},
		message: {
			keepDefaultValue: "You must keep a default value",
			cancelDelete: "Cancel the delete operation",
			videoSizePrompt: "Upload video file size cannot exceed 500MB!",
			/*this.$t('baseConfig.message.videoSizePrompt')*/
			uploadError: "upload error"
		},
		confirm: {
			title: "info",
			content: "Are you sure to delete the Default Value?"
		}
	},
	permission: {
		addRole: "add Role",
		editRole: "edit Role",
		addAccount: "add Account:",
		addMenu: "add Menu:",
		putPassword: "change the password"
	},
	tablePage: {
		disabled: "disable",
		useful: "enable"
	},
	navbar: {
		user: "user",
		dashboard: "homepage",
		logOut: "sign out",
		putPassword: "change the password"
	},
	login: {
		title: "yesoul admin",
		logIn: "Login",
		username: "account",
		password: "password",
		apihosten: "apihosten",
		nameMsg: "account can not be null",
		noPassword: "password can not be null",
		loginSuccess: "login Success",
		loginErr: "login failed"
	},
	dashboard: {
		dashboard: "Home page"
	},
	manager: {
		editAccount: "edit Account",
		addAccount: "add Account",
		editResources: "edit Resources",
		addResources: "add Resources",
		minute: "min",
		tableColumn: [{
				label: "name",
				prop: "userName",
				type: "normal"
			},
			{
				label: "remarks",
				prop: "position",
				type: "normal"
			},
			{
				label: "Status",
				prop: "status",
				type: "switch"
			},
			{
				label: "super administrator",
				prop: "isSuper",
				type: "normal"
			},
			{
				label: "phone Number",
				prop: "mobile",
				type: "normal"
			},
			{
				label: "operation",
				prop: "action",
				width: "450px",
				type: "button",
				button: [{
						label: "edit"
					},
					{
						label: "associated role",
						type: "primary"
					},
					{
						label: "reset password"
					},
					{
						label: "delete",
						type: "primary"
					}
					/*  { label: "associated resources",type: "primary",disabled:true} */
				],
				method: ["_edit", "_roleManager", "_reset", "_delete" /* '_addMenu' */ ]
			}
		],
		tableColumn_2: [{
				label: "resources name",
				prop: "name",
				type: "normal"
			},
			{
				label: "resources remarks",
				prop: "remark",
				type: "normal"
			},
			{
				label: "URI",
				prop: "uri",
				type: "normal"
			},
			{
				label: "operation",
				prop: "action",
				type: "button",
				button: [{
						label: "edit",
						type: "primary",
						disabled: true
					},
					{
						label: "delete",
						disabled: true
					}
				],
				method: ["_edit", "_delete"]
			}
		],
		tableColumn_3: [{
				label: "role",
				prop: "name",
				type: "normal"
			},
			{
				label: "remarks",
				prop: "remark",
				type: "normal"
			},
			{
				label: "operation",
				prop: "action",
				type: "button",
				button: [{
						label: "edit",
						type: "primary"
					},
					{
						label: "delete"
					},
					{
						label: "associated resources",
						type: "primary"
					}
				],
				method: ["_edit", "_delete", "_menuAction"]
			}
		],
		confirm: {
			title: "info",
			deleteCurrentAccount: "Are you sure to delete the current account?",
			isResetPassword: "Are you sure to reset the password?",
			isDeleteCurrentRole: "Are you sure to delete the current role?"
		},
		message: {
			deleteCancel: "Cancel Delete",
			pwdResetPassword: "Password reset successfully!",
			setUpUserResources: "Complete user resource Settings",
			pwdResetComplete: "Complete password change",
			setUpRoleResources: "Complete the character resource Settings"
		}
	},
	oldsystemmodule: {
		inputTagName: "input tag",
		courseEffects: [{
				name: "Fat burning",
				value: ""
			},
			{
				name: "Cardiopulmonary",
				value: ""
			},
			{
				name: "Endurance",
				value: ""
			},
			{
				name: "Power",
				value: ""
			}
		]
	},
	pushmanager: {
		selectDate: "select date",
		nameAlreadyExists: "The name already exists",
		inputTagName: "input tag",
		userTagOptions: [{
				value: "1",
				label: "tag 1"
			},
			{
				value: "2",
				label: "tag 2"
			}
		],
		items: [{
				type: "info",
				num: 0,
				label: "language",
				value: "1",
				children: [{
						title: "汉语",
						active: false
					},
					{
						title: "English",
						active: false
					},
					{
						title: "法语",
						active: false
					},
					{
						title: "俄语",
						active: false
					},
					{
						title: "日语",
						active: false
					}
				]
			},
			{
				type: "info",
				num: 0,
				label: "sex",
				value: "1",
				children: [{
						title: "male",
						active: false
					},
					{
						title: "female",
						active: false
					}
				]
			},
			{
				type: "info",
				num: 0,
				label: "Level of consumption",
				value: "1",
				children: [{
						title: "high",
						active: false
					},
					{
						title: "medium",
						active: false
					},
					{
						title: "normal",
						active: false
					}
				]
			},
			{
				type: "info",
				num: 0,
				label: "Interest",
				value: "1",
				children: [{
						title: "彩票",
						active: false
					},
					{
						title: "骑车",
						active: false
					},
					{
						title: "汽车",
						active: false
					},
					{
						title: "棋牌",
						active: false
					},
					{
						title: "美食",
						active: false
					}
				]
			}
		],

		message: {
			inputTagName: "input tag",
			optionSuccess: "operation Success"
		}
	},
	putPassword: {
		oldPwd: "old password",
		inputOldPwd: "input old password",
		newPwd: "new password",
		inputNewPwd: "input new password",
		okNewPwd: "Confirm the new password",
		inputPhoneNumber: "input phone Number",
		reset: "reset",
		message: {
			pwdError: "input password",
			inputPwdAgainError: "input password again",
			pwdInconsistentError: "The two passwords do not match!",
			inputOldPwd: "input old password",
			inputNewPwd: "input new password",
			okNewPwd: "Confirm the new password",
			editPwdSuccess: "Change password completed"
		}
	},
	record: {
		tag: "tag",
		addTag: "add tag",
		pleaseSelect: "select",
		inputUserName: "input user name",
		creatCourse: "Creat Course",
		shelf1: "Show up",
		shelf2: "Hidden",
		noStatus: "no Status",
		tableData: [{
				className: "Exercise"
			},
			{
				className: "Yoga"
			},
			{
				className: "cycling"
			},
			{
				className: "running"
			}
		],
		tableColumn: [{
				label: "id",
				prop: "id",
				type: "normal"
			},
			{
				label: "fitness type",
				prop: "categoryName",
				type: "normal",
				width: 80
			},
			{
				label: "course Name",
				prop: "courseName",
				type: "normal"
			},
			{
				label: "Level",
				prop: "courseLevel",
				type: "normal",
				width: 80
			},
			{
				label: "Duration",
				prop: "courseDuration",
				type: "normal"
			},
			{
				label: "Created Time",
				prop: "createTime",
				type: "normal"
			},
			{
				label: "Coach",
				prop: "coachName",
				type: "normal"
			},
			{
				label: "be distributed zone",
				prop: "distribName",
				type: "normal",
				width: 120
			},
			{
				label: "Status",
				prop: "shelf",
				type: "normal",
				width: 80
			},
			{
				label: "Languages",
				prop: "langCode",
				type: "normal",
				width: 80
			},
			{
				label: "operation",
				prop: "action",
				type: "button",
				width: "360px",
				button: [{
						label: "edit",
						type: "primary"
					},
					{
						label: "check"
					},
					{
						label: "add tag",
						type: "primary"
					},
					{
						label: "delete"
					}
				],
				method: ["_edit", "_check", "_addTag", "_delete"]
			}
		],
		searchData: [{
				label: "fitness type",
				prop: "class type_id",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "primary",
						value: 1
					},
					{
						label: "intermediate",
						value: 2
					},
					{
						label: "senior",
						value: 3
					}
				]
			},
			{
				label: "Level",
				prop: "level_id",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "Hidden",
						value: 2
					},
					{
						label: "Show up",
						value: 1
					},
					{
						label: "Hidden",
						value: 0
					}
				]
			},
			{
				label: "duration",
				prop: "duration",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "15min",
						value: 15 * 60
					},
					{
						label: "20min",
						value: 20 * 60
					},
					{
						label: "30min",
						value: 30 * 60
					},
					{
						label: "45min",
						value: 45 * 60
					},
					{
						label: "60min",
						value: 60 * 60
					}
				]
			},
			{
				label: "distributed",
				prop: "distrib_id",
				type: "select",
				option: [{
					label: "all",
					value: ""
				}, {
					label: "free",
					value: 1
				}]
			},
			{
				label: "workout",
				prop: "course_name"
			},
			{
				label: "id",
				prop: "show_id"
			},
			{
				label: "coach",
				prop: "coach_name"
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "Hidden",
						value: "0"
					},
					{
						label: "Show up",
						value: "1"
					}
				]
			},
			{
				label: "language",
				prop: "langCode",
				type: "select",
				option: [
					// { label: "all", value: "" },
				]
			}
			// { label: "start time", prop: "time_range",type: "dateRangePicker" }
		],
		distributeSearchData: [{
				label: "id",
				prop: "show_id"
			},
			{
				label: "workout",
				prop: "course_name"
			},
			{
				label: "coach",
				prop: "coach_name"
			},
			{
				label: "fitness type",
				prop: "class type_id",
				type: "select",
				option: []
			},
			{
				label: "duration",
				prop: "duration",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "15min",
						value: 15 * 60
					},
					{
						label: "20min",
						value: 20 * 60
					},
					{
						label: "30min",
						value: 30 * 60
					},
					{
						label: "45min",
						value: 45 * 60
					},
					{
						label: "60min",
						value: 60 * 60
					}
				]
			},
			{
				label: "Level",
				prop: "level_id",
				type: "select",
				option: []
			}
			// {
			//   label: "Status",
			//   prop: "shelf",
			//   type: "select",
			//   option: [
			//     { label: "all", value: "" },
			//     { label: "Hidden", value: "0" },
			//     { label: "Show up", value: "1" },
			//     { label: "Hidden", value: "2" }
			//   ]
			// }
		],
		distributeTableColumn: [{
				label: "id",
				prop: "id",
				type: "normal"
			},
			{
				label: "workout",
				prop: "courseName",
				type: "normal"
			},
			{
				label: "level",
				prop: "courseLevel",
				type: "normal",
				width: 80
			},
			{
				label: "Duration",
				prop: "courseDuration",
				type: "normal"
			},
			{
				label: "coach",
				prop: "coachName",
				type: "normal"
			},
			{
				label: "finishNum",
				prop: "finishNum",
				type: "normal",
				sortable: true
			},
			{
				label: "participation",
				prop: "joinNum",
				type: "normal",
				sortable: true
			},
			{
				label: "begin Time",
				prop: "beginTime",
				type: "normal"
			}
		]
	},
	reviewedcourses: {
		needInstrument: "equipment",
		courseEffects: [{
				name: "Fat burning",
				value: "",
				remark: ""
			},
			{
				name: "Cardiopulmonary",
				value: "",
				remark: ""
			},
			{
				name: "Endurance",
				value: "",
				remark: ""
			},
			{
				name: "Power",
				value: "",
				remark: ""
			}
		],
		message: {
			JPGformat: "Uploaded images should only be in JPG!"
		}
	},
	tagmanager: {
		open: "unfold",
		close: "fold",
		courseName: "workout",
		inputCourseName: "input workout",
		option: "operation",
		search: "search"
	},
	langOptions: [{
			label: "简体中文（zh-CN）",
			value: "zh"
		},
		{
			label: "英语（en）",
			value: "en"
		},
		{
			label: "德语（de）",
			value: "de"
		},
		{
			label: "西班牙语（es）",
			value: "es"
		}
	], //语言切换配置
	promptObj: {
		selectPlaceholder: "select",
		err400msg: "There are fields that have not been filled in",
		sizeTips: "Please upload the size:",
		loadingTips: "Loading...",
		batchSuccess: "success",
		batchError: "failure",
		modifyTheSuccess: "complete the change",
		modifyTheFailure: "failure the change",
		haveBeenAdded: "have Been Added",
		addSuccess: "Added successfully",
		addError: "failure the add",
		delectSuccess: "Complete the delete operation",
		deleted: "Has been deleted",
		cancel: "Has been canceled",
		hasBeenOn: "Has been showed up",
		newErrMsg: "Service error",
		hasBeenOffTheShelves: "Has been Hiddened",
		copySuccess: "copy Success！",
		copyError: "Please click generate exchange code！",
		checkRelatedOptions: "Please check the relevant content first",
		successfullyListed: "Complete the show up operation",
		successfullyRemoved: "Removed successfully",
		sortSuccess: "sort Success",
		deleteCurrentRow: "Are you sure to delete the selected content？",
		prompt: "info",
		sortEmpty: "The current sorting list is empty. Please upload the relevant contents first",
		creatSuccess: "Complete creat",
		modifiedStatusSuccess: "Complete the Status change",
		offSelfAfterDelete: "There is content on the shelf, please remove it first and then delete it",
		uploadImageSizePrompt: "Upload images should not be larger than 200MB!",
		imageCannotBeEmpty: "image Can not Be null",
		linkAddressCannotBeEmpty: "link Address Can not Be null",
		uploadImageFailed: "The picture upload address failed to get, please upload again",
		noMoreThenTwenty: "input name，No more than 20 character"
	},
	actionbuttons: {
		allLocations: "All locations",
		allSelect: "all",
		exportLessonPlan:"Export lesson plan",//2.2.4新增 导出教案按钮
		importLessonPlans:"Import lesson plans",//2.2.4新增 导入教案
		lessonPlansTips:"If you have added a lesson plan, you need to clear all the added lesson plans before you can import a new lesson plan",//2.2.4新增 导入教案tips
		lessonErrTips:"Attachment format error, please delete and upload again!",//2.2.4新增 导入教案出错提示语
		lessonTips:"Please upload the attachment!",//2.2.4新增 请上传附件！
		search: "search",
		addaction: "add",
		createaction: "create",
		editaction: "edit",
		deleteaction: "delete",
		saveaction: "save",
		detail: "detail",
		shelves: "Show up",
		soldout: "Hidden",
		export: "export data",
		toview: "check",
		viewcourse: "check the course",
		forBack: "back",
		confirmAction: "confirm",
		cancelAction: "cancel",
		closeAction: "close",
		moveUp: "up",
		moveDown: "down",
		copyAction: "copy",
		recoveryCourse: "recovery",
		hot: "hot",
		unbind: "unbind",
		resetPsw: "rest password",
		coachSupervisor: "coach Supervisor",
		cancelSupervisor: "remove Supervisor",
		sortButton: "sort",
		options: "operation",
		replay: "reply",
		addToRecycle: "Add to recycle bin",
		reEditBtn: "Re-edit",
		recycleBinBtn: "Recycle bin",
		yesBtn: "yes",
		noBtn: "no"
	},
	registrationSourceOptions: [{
			value: "1",
			text: "App"
		},
		{
			value: "2",
			text: "屏幕端"
		}
	],
	sexOptions: [{
			value: "",
			text: "all"
		},
		{
			value: 0,
			text: "male"
		},
		{
			value: 1,
			text: "female"
		}
	],
	sexMap: {
		1: "female",
		0: "male",
		2: "Other"
	},
	attributeGender: {
		0: "male",
		1: "female",
		2: "all"
	},
	deliveredUserType: {
		0: "all user",
		1: "tag-user"
	},
	workingState: [{
			value: "",
			text: "all"
		},
		{
			value: 0,
			text: "resignation"
		},
		{
			value: 1,
			text: "in Service"
		}
	],
	workingObj: {
		0: "resignation",
		1: "in Service"
	},
	importanceOptions: [{
			status: "",
			text: "all"
		},
		{
			status: 2,
			text: "Not listed"
		},
		{
			status: 1,
			text: "Shelf status"
		},
		{
			status: 0,
			text: "Off-shelf status"
		}
	],

	importanceStatusMap: {
		2: "Not listed",
		0: "Off-shelf status",
		1: "Shelf status"
	},

	// 兑换码添加验证
	exchange_codes_add: {
		serialNumber: [{
			required: true,
			message: "Please click generate exchange code",
			trigger: "blur"
		}],
		expireTime: [{
			required: true,
			message: "Please select valid time",
			trigger: "blur"
		}],
		bindPeopleNum: [{
			required: true,
			message: "Please fill in the number of times used",
			trigger: "blur"
		}]
		// vipDays: [
		//   {
		//     required: true,
		//     message: 'input the Length of Gift',
		//     trigger: 'blur'
		//   }
		// ]
	},
	courseEffectInterval: [{
			label: 1,
			value: 1
		},
		{
			label: 2,
			value: 2
		},
		{
			label: 3,
			value: 3
		},
		{
			label: 4,
			value: 4
		},
		{
			label: 5,
			value: 5
		},
		{
			label: 6,
			value: 6
		},
		{
			label: 7,
			value: 7
		},
		{
			label: 8,
			value: 8
		},
		{
			label: 9,
			value: 9
		},
		{
			label: 10,
			value: 10
		}
	],
	notifyMsg: {
		updatesuccessfully: "update is successful",
		updatefailed: "Update failed"
	},
	// 兑换码国际化
	exchangecodemaneger: {
		conversioncode: "Conversion code",
		conversionplaceholder: "input the Conversion code",
		givingduration: "giving duration",
		givingplaceholder: "input the giving duration",
		status: "Status",
		statusplaceholder: "select the status",
		headerfields: {
			conversioncode: "Conversion code",
			givingduration: "giving duration",
			totaltimes: "total times",
			day: "day",
			status: "Status",
			periodvalidity: "period of validity",
			changeTime: "exchange date",
			used: "used",
			free: "left",
			effectivedate: "The effective date",
			destruction: "destruction",
			operation: "operation"
		},
		destruction: {
			title: "info",
			unExchangeMsg: "Confirm to destroy the exchange code？",
			destoryMsg: "The exchange code has been used. The destruction will invalidate the user's rights. Do you want to continue？",
			success: "Complete",
			cancel: "cancel"
		},
		importanceOptions: [{
				status: 0,
				text: "all"
			},
			{
				status: 1,
				text: "Show up"
			},
			{
				status: 2,
				text: "Hidden"
			},
			{
				status: 3,
				text: "Hidden"
			}
		],

		statusMap: {
			0: "Not in use",
			1: "used"
		},
		effectiveDateTypeMap: {
			0: "unlimited",
			1: "Expiration time"
		},
		statuslist: [{
				value: "",
				label: "all"
			},
			{
				value: 1,
				label: "used"
			},
			{
				value: 0,
				label: "Not in use"
			}
		],
		dialogfields: {
			title: "Create an exchange code",
			codeDetails: "Code Details",
			timeLimit: "time Limit",
			unlimited: "unlimited",
			expireDate: "Expiration time",
			editTitle: "Edit",
			givingduration: "duration (day)",
			givingplaceholder: "input the duration",
			usedtimes: "count of use",
			usedtimesplaceholder: "input the number of use",
			effectivedate: "the Effective date",
			effectivedatePlaceholder: "Please select the date",
			startplaceholder: "Effective date",
			endplaceholder: "expiry date",
			to: "to",
			getcode: "Generate exchange code",
			editcode: "exchange code",
			getcodeplaceholder: "Click to generate the exchange code",
			copy: "copy",
			confirmbtn: "confirm",
			cancelbtn: "cancel"
		},
		dialogexchangeinfo: {
			title: "info",
			exchangecode: "exchange code",
			duration: "duration",
			effectivedate: "expiry date",
			totaltimes: "the number of use",
			used: "used",
			free: "left",
			forback: "back",
			tablefields: {
				nickname: "user name",
				phone: "photo number",
				exchangetime: "exchange date"
			}
		}
	},
	// exchangecodeentry兑换码录入
	exchangecodeentry: {
		vipfordays: "会员天数",
		vipfordaysPlaceholder: "input 会员天数",
		exchangecodes: "兑换码",
		exchangecodePlaceholder: "最多仅支持500个兑换码操作，每个之间用英文逗号分隔",
		periodofvalidity: "有效期",
		unlimited: "不限时",
		expirationdate: "时间",
		startTime: "开始时间",
		endTime: "结束时间",
		expirationdatePlaceholder: "请选择有效日期",
		numOfExchangeCodeGeneration: "兑换码生成数量",
		getcodenumplaceholder: "input 兑换码生成数量",

		memberNumberGroup: [{
				label: "请选择",
				value: 0
			},
			{
				label: "7天",
				value: 7
			},
			{
				label: "30天",
				value: 30
			},
			{
				label: "60天",
				value: 60
			},
			{
				label: "365天",
				value: 365
			}
		]
	},
	// SN码国际化
	sncodemaneger: {
		instrument: "器械",
		instrumentAll: "all",
		snCode: "SN码",
		snCodeplaceholder: "input SN码",
		state: "Status",
		stateplaceholder: "input SN请选择状态",
		membernumber: "会员天数",
		memberplaceholder: "input 会员天数",
		stateGroup: [{
				label: "all",
				value: ""
			},
			{
				label: "已绑定",
				value: "isnotnull isnotnull"
			},
			{
				label: "未绑定",
				value: "isnull isnull"
			}
		],
		statusMap: {
			0: "未绑定",
			1: "已绑定"
		},
		bindingInfo: {
			infoTitle: "binding info",
			snCode: "SN Code",
			state: "Status",
			totalNumberBindings: "可绑定人数",
			numberOfBound: "已绑定人数",
			memberNumber: "会员天数",
			headerfields: {
				name: "用户名",
				phone: "phone Number",
				email: "email",
				bindTime: "绑定时间"
			}
		},
		headerfields: {
			snCode: "SN",
			categoryName: "器械",
			numberofbindings: "可绑定人数",
			numberofbound: "已绑定人数",
			membernumber: "会员天数",
			state: "Status",
			operation: "operation"
		},
		unBindSn: {
			title: "解绑",
			message: "解绑操作会导致关联该SN码的所有用户权益失效，是否继续？",
			success: "解绑成功",
			cancel: "cancel"
		},
		editSnCode: {
			title: "编辑SN码",
			memberNumber: "会员天数",
			memberPlaceholder: "input 会员天数",
			custom: "自定义",
			customPlaceholder: "input 兑换天数",
			numberOfBinding: "绑定人数",
			numberOfBindingMsg: "input 绑定人数",
			numberOfBindingPlaceholder: "默认支持5人",
			snCode: "SN码",
			snCodePlaceholder: "最多仅支持500个SN操作,每个之间用英文逗号分隔",
			repeatSn: "SN码重复,请确认后提交",
			maxBindFives: "最多绑定5人"
		},
		memberNumberGroup: [{
				label: "请选择",
				value: 0
			},
			{
				label: "7天",
				value: 7
			},
			{
				label: "30天",
				value: 30
			},
			{
				label: "60天",
				value: 60
			},
			{
				label: "365天",
				value: 365
			}
		],
		editFormatMsg: {
			memberNumber: [{
				required: true,
				message: "请选择会员天数",
				trigger: "blur"
			}],
			custom: [{
				required: true,
				message: "input 兑换天数",
				trigger: "blur"
			}],
			numberOfBinding: [{
				required: true,
				message: "input 绑定人数",
				trigger: "blur"
			}]
		}
	},
	sncodeentry: {
		memberNumber: "会员天数",
		memberPlaceholder: "input 会员天数",
		custom: "自定义",
		customPlaceholder: "input 兑换天数",
		numberOfBinding: "绑定人数",
		numberOfBindingPlaceholder: "默认支持5人",
		numberOfBindingMsg: "input 绑定人数",
		snCode: "SN码",
		instrument: "器械",
		sncodeentryplaceholder: "请选择器械",
		instrumentGroup: [{
				value: "",
				label: "choose"
			},
			{
				value: 1,
				label: "Bicycle"
			},
			{
				value: 2,
				label: "Treadmill"
			},
			{
				value: 3,
				label: "Rowing machine"
			},
			{
				value: 4,
				label: "健腹轮"
			}
		],
		snCodePlaceholder: "最多仅支持500个SN操作,每个之间用英文逗号分隔",
		formSearch: {
			memberNumber: [{
				required: true,
				message: "请选择会员天数",
				trigger: "blur"
			}],
			// custom: [
			//   {
			//     required: true,
			//     message: 'input 兑换天数',
			//     trigger: 'blur'
			//   }, { validator: /^[+]{0,1}(\d+)$/, message: '兑换天数为正整数', trigger: 'blur' }
			// ],
			numberOfBinding: [{
				required: true,
				message: "input 绑定人数",
				trigger: "blur"
			}],
			snCode: [{
				required: true,
				message: "input SN码",
				trigger: "blur"
			}]
		}
	},
	userBodyFile: {
		weight: "weight",
		BMI: "BMI",
		KG: "KG",
		HEIGHT: "height",
		powerThreshold: "Power threshold",
		bodyFatRate: "Body fat rate",
		maxHeartRate: "Maximum heart rate",
		headerfields: {
			updateTime: "Update time",
			updateTheWay: "way of update",
			updateValues: "new values"
		},
		tableNoDateMsg: {
			weightMsg: "no update",
			BMIMsg: "Intelligent matching",
			powerThresholdMsg: "No update",
			bodyFatRateMsg: "upo date",
			maxHeartRateMsg: "No update"
		}
	},
	// 推送分组管理
	grouplist: {
		groupName: "group Name",
		groupNamePlaceholder: "input the group Name",
		creatGroup: "add group",
		headerfields: {
			groupName: "group",
			userAttributes: "User attributes",
			deliveredUser: "Delivered to the user",
			creatTime: "creat Time",
			operation: "operation"
		},
		delectInfo: {
			title: "info",
			content: "Do you want to delete the group?",
			deleteGroupSuccess: "complete remove the group",
			cancel: "cancel"
		}
	},
	creatGroup: {
		title: "新建用户分组",
		groupName: "用户分组名称",
		namePlaceholder: "input 用户分组名称",
		describe: "用户分组描述",
		describePlaceholder: "input 用户分组描述",
		setType: "分类设置",
		userAttribute: "用户属性",
		pleaseSelect: "请选择",
		deliveredUser: "送达用户",
		continueSelect: "继续选择",
		firstLabel: "all",
		userAttributeSelected: "已选用户属性",
		allusersLabel: "全部用户",
		tagsUserLabel: "标签用户",
		userTagName: "用户标签",
		userTagNamePlaceholder: "请选择用户标签",
		confirm: {
			title: "提示",
			addSuccess: "添加成功",
			editSuccess: "修改成功",
			cancelMsg: "已取消"
		},
		requireUserTag: "请选择用户标签",
		creatGroupRule: {
			name: [{
				required: true,
				message: "input 标签名称",
				trigger: "blur"
			}],
			confirmAttributeTags: [{
				required: true,
				message: "请选择用户属性",
				trigger: "blur"
			}],
			type: [{
				required: true,
				message: "请选择送达用户",
				trigger: "blur"
			}]
		}
	},
	pushlist: {
		groupName: "分组名称",
		groupNamePlaceholder: "input 分组名称",
		noticeTheTitle: "通知标题",
		noticeTheTitlePlaceholder: "input 通知标题",
		pushTheTime: "推送时间",
		startplaceholder: "开始日期",
		endplaceholder: "结束日期",
		creatPush: "创建推送",
		to: "至",
		IOS: "IOS",
		andorid: "andorid ",
		allPlatform: "all",
		headerfields: {
			noticeTitleLabel: "通知标题",
			groupName: "推送分组",
			deliveredUser: "目标平台",
			pushTheTime: "推送时间",
			operation: "operation"
		},
		prompt: {
			title: "提示",
			content: "是否删除该推送"
		}
	},
	pushcreated: {
		selectGroupLabel: "推送分组",
		selectGroupPlaceholder: "请选择分组",
		targetPlatFormLabel: "目标平台",
		noticeTitleLabel: "通知标题",
		titlePlaceholder: "input 通知标题",
		noticeContentLabel: "通知内容",
		contentPlaceholder: "input 通知内容",
		pushPictureLabel: "推送图片",
		upImg: "上传图片",
		pushUrl: "url",
		urlPlaceholder: "Please enter the link address",
		followUpActionLabel: "后续动作",
		startApplication: "启动应用",
		openLinks: "打开链接",
		pushMethodLabel: "推送方式",
		timing: "定时",
		instant: "即时",
		android: "安卓",
		IOS: "IOS",
		allPlatform: "all",
		selectDate: "时间选择",
		selectDatePlaceholder: "请选择日期",
		selectTimePlaceholder: "请选择时间",
		pushValidateCode: "短信验证码",
		sendSms: "发送短信",
		formSearch: {
			selectGroup: [{
				required: true,
				message: "请选择分组",
				trigger: "blur"
			}],
			targetPlatForm: [{
				required: true,
				message: "请选择目标平台",
				trigger: "blur"
			}],
			noticeTitle: [{
				required: true,
				message: "input 通知标题",
				trigger: "blur"
			}],
			noticeContent: [{
				required: true,
				message: "input 通知内容",
				trigger: "blur"
			}],
			pushUrl: [{
				required: true,
				message: "input 链接地址",
				trigger: "blur"
			}],
			followUpAction: [{
				required: true,
				message: "请选择后续动作",
				trigger: "blur"
			}],
			pushMethod: [{
				required: true,
				message: "请选择推送方式",
				trigger: "blur"
			}],
			pushValidateCode: [{
				required: true,
				message: "input 短信验证码",
				trigger: "blur"
			}]
		}
	},
	seeThePush: {
		titleOne: "用户信息",
		titleTwo: "推送信息"
	},
	pushdatalist: {
		groupName: "group Name",
		groupNamePlaceholder: "input group Name",
		noticeTheTitle: "notice",
		noticeTheTitlePlaceholder: "input notice Title",
		pushTheTime: "push date",
		startplaceholder: "start date",
		endplaceholder: "end date",
		creatPush: "add push",
		to: "to",
		headerfields: {
			noticeTheTitle: "push title",
			groupName: "to group",
			pushTheTime: "push date",
			targetPlatForm: "目标平台",
			touchFor: "触达数",
			issuedByTheNumber: "下发成功数",
			clicks: "点击数"
		},
		targetPlatForm: "目标平台",
		targetPlatFormPlaceholder: "请选择目标平台",
		targetPlatFormOption: [{
				label: "all",
				value: ""
			},
			{
				label: "安卓",
				value: 0
			},
			{
				label: "IOS",
				value: 1
			}
		]
	},
	// 0 录播 1 直播 2 往期
	is_live_map: {
		0: "Recorded workouts",
		1: "Live workouts",
		2: "past workouts"
	},

	coachmanager: {
		ID: "ID",
		idPlaceholder: "input ID",
		coachName: "coach Name",
		coachNamePlaceholder: "input the coach Name",
		phone: "phone Number",
		phonePlaceholder: "input the phone Number",
		email: "Email address",
		emailPlaceholder: "input the Email address",
		sex: "sex",
		sexPlaceholder: "select gender",
		state: "Status",
		statePlaceholder: "select state",
		number: "Num",
		nameAlreadyExists: "The name already exists",
		create: "add coach",
		baseMassage: {
			coach: "coach",
			homeCover: "home Cover",
			coachImage: "couach page head image",
			recommendedSize: "Suggest size：750*440",
			recommendedSize1: "Coaches Home Page Cover Suggest size：750*440",
			liveCourseCover0: "image of Live workouts List",
			liveCourseCover: "image of live workouts detail's head",
			liveCourseCover1: "image of live workouts detail's head",
			recommendedSize2: "Suggest size：750*524",
			recommendedSize_2: "Suggest size：1029*579",
			recommendedSize_3: "Suggest size：1125*786",
			recommendedSize3: "Suggest size：1125*786",
			recommendedSize4: "Suggest size：1029*579",
			pastCourseCover: "image of past workouts List",
			recordCourseCover: "image of Recorded workout's List",
			email: "Email address",
			inputEmail: "input the Email address",
			jobTitle: "job Title",
			inputJobTitle: "input the job Title",
			achievement: "achievement",
			separate: "Multiple achievements are separated by commas",
			areasExpertise: "areas Expertise",
			enterKey: "Click Enter to confirm",
			signature: "motto",
			inputSignature: "input the motto",
			introduction: "Introduction",
			inputiIntroduction: "input the introduction",
			photoAlbum: "photo Album",
			uploadPhotosOrVideo: "upload Photos Suggest size：200*246"
		},
		creat: "add",
		coachingstafflist: {
			coachTeamName: "coach Team name",
			pleaseInputName: "input the name",
			coachTeamAvatar: "coach Team Avatar",
			recommendedSize: "Suggest size：120*120",
			coach: "coach",
			pleaseSelect: "select",
			dynamicTags: [{
					name: "tag1"
				},
				{
					name: "tag2"
				},
				{
					name: "tag3"
				},
				{
					name: "tag4"
				},
				{
					name: "tag5"
				}
			],
			searchData: [{
				label: "coach Team Name",
				prop: "groupName"
			}],
			tableColumn: [{
					label: "coach Team Name",
					prop: "groupName",
					type: "normal"
				},
				{
					label: "team Members",
					prop: "coachList",
					type: "tag"
				},
				{
					label: "create date",
					prop: "createTime",
					type: "normal"
				},
				{
					label: "Status",
					prop: "status",
					type: "switch"
				},
				{
					label: "operation",
					prop: "action",
					type: "button",
					width: "360px",
					button: [{
							label: "edit",
							limit: "shelf",
							limitValue: "Show up"
						},
						{
							label: "check",
							type: "primary"
						}
					],
					method: ["_edit", "_check"]
				}
			],
			tableData: [{
				name: "test"
			}]
		},
		btn: {
			return: "back"
		},
		message: {
			renewLogin: "Please log in again",
			repeatError: "The content is repeated, please confirm and input",
			formatError: "Please upload the correct file format",
			videoSizeError: "Upload video size cannot exceed 500MB!",
			formatTypeError: "The image format is incorrect. Only JPEG, JPG and PNG images are supported",
			imageUpdate: "Picture uploading, please hold on",
			loginError: "Please log in again",
			uploadFailedError: "Upload failed, please re-upload",
			/*coachingstafflist*/
			ageError: "Age cannot be null",
			numberError: "input the age",
			ageLeastError: "Must be at least 18 years of age",
			pwdError: "input the password",
			pwdAgainError: "input the password again",
			pwdInconsistentError: "The two passwords do not match!",
			addSuccess: "Add operation completed",
			addError: "Add operation failed",
			editSuccess: "Modification operation completed",
			resetPswText: 'The reset password is "123456". Are you sure about the reset password？',
			cancelText: "Please remove the coach from his supervisory position first",
			setSuccess: "Setup supervisor successful",
			cancelSuccess: "Cancel supervisor successfully",
			resetPswSuccess: "Password reset successfully",
			resetPswFail: "Password reset failed",
			sureSetCoach: "Are you sure to set this coach as a supervisor?",
			sureCancelSetCoach: "Are you sure to cancel the coach's supervisor status?",
			prompt: "info",
			cancelOption: "Operation cancelled",
			inputEmail: "email address cannot be null",
			inputIntroduction: "introduction cannot be null",
			inputTitle: "job title cannot be null",
			exceed: "No more than 3 areas Expertise "
		},
		headerfields: {
			id: "ID",
			headerImg: "image",
			name: "name",
			sex: "sex",
			phone: "phone Number",
			email: "Email address",
			inTheTime: "joined date",
			numberOfCourse: "total workouts",
			departure: "resignation",
			onTheJob: "in Service",
			state: "Status",
			operation: "operation",
			coverPic: "Default image of workouts list",
			infoPic: "Default image of workouts details"
		},
		confirm: {
			title: "info",
			submitThisCoach: "Are you sure about add the new coach?",
			editThisCoach: "Are you sure about the edit operation?"
		},
		coachlistEditStatus: {
			title: "info",
			content: "Confirm to modify the status of the coach？",
			comfirmOkMsg: "Modify success"
		},
		creatAndEdit: {
			creatTitle: "add coach",
			editTitle: "edit coach",
			coachName: "coach name",
			coachNamePlaceholder: "input the coach name",
			sexLabel: "sex",
			male: "male",
			female: "female",
			age: "age",
			agePlaceholder: "input the age",
			header: "image",
			headerCoursePic: "couach page head image",
			coursePic: "image of Live workouts",
			videoCourseCover: "image of past workouts",
			coverPlaceholder: "please upload the image of past workouts",
			clickUpload: "upload",
			phone: "phone Number",
			phonePlaceholder: "input phone Number",
			introduction: "introduction",
			introPlaceholder: "input introduction",
			address: "address",
			addressPlaceholder: "select",
			addressDetailPlaceholder: "please input the detailed address",
			addressDetail: "detailed address",
			whetherInOffice: "in Service?",
			onTheJob: "yes",
			departure: "no",
			labelRule: {
				nickname: [{
					required: true,
					message: "input coach name",
					trigger: "blur"
				}],
				sex: [{
					required: true,
					message: "Please select gender",
					trigger: "blur"
				}],
				age: [{
					required: true,
					message: "input age",
					trigger: "blur"
				}],
				photo: [{
					required: true,
					message: "Please select your head image",
					trigger: "blur"
				}],
				coursePic: [{
					required: true,
					message: "Please upload the workouts cover",
					trigger: "blur"
				}],
				mobile: [{
						required: true,
						message: "input phoneNumber",
						trigger: "blur"
					},
					{
						pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
						message: "input the  correct phone Number",
						trigger: "blur"
					}
				],
				introduction: [{
					required: true,
					message: "input Introduction",
					trigger: "blur"
				}]
			}
		}
	},
	coachdetails: {
		baseTap: "basic information",
		coachTap: "workouts info",
		baseTapObj: {
			state: "Status",
			phone: "phoneNumber",
			sex: "sex",
			address: "address",
			introduction: "introduction"
		},
		coachTapObj: {
			theTotalCourse: "Total workouts",
			participation: "joined ",
			manTime: "participation"
		},
		headerfields: {
			coachId: "workout ID",
			coachName: "workout",
			coachType: "fitness type",
			classTime: "start time",
			manTime: "participation",
			participation: "joined"
		}
	},
	usertag: {
		name: "name",
		status: "Status",
		enterholder: "input name",
		search: "search",
		inputTagName: "input the tag",
		statePlaceholder: "select state",
		shelves: "Show up",
		soldOut: "Hidden",
		selctAddData: "Select the data you want to add first",
		headerfields: {
			name: "name",
			state: "Status",
			creator: "creator",
			creationtime: "creator date",
			operation: "operation",
			deleteTagMsg: "Do you want to delete the tag?"
		},
		creattagsdialog: {
			title: "add new tag",
			confirmbtn: "confirm",
			cancelbtn: "cancel",
			labelName: "tag ",
			labelNameHolder: "input the tag",
			enterholder: "input the new tag",
			tagType: "tag type",
			tagTypeHolder: "input the tag type",
			creatTitle: "add new tag",
			editTitle: "edit tag"
		},
		deleteTagMsg: "Do you want to delete the tag？",
		deleteTagSuccess: "The tag was removed successfully",
		cancelMsg: "canceled",
		prompt: "info",
		labelRule: {
			name: [{
				required: true,
				message: "input the tag",
				trigger: "blur"
			}]
		}
	},
	classtag: {
		tagOne: "type",
		tagTwo: "tag",
		tagName: "tag name",
		tagNamePlaceholder: "input tag name",
		state: "Status",
		statePlaceholder: "select state",
		creatTime: "creat date",
		startTime: "start date",
		endTime: "end date",
		hotTag: "Hot",
		shelves: "Show up",
		soldOut: "Hidden",
		deleteTagMsg: "Do you want to delete the tag？",
		deleteTagSuccess: "The tag was removed successfully",
		cancelMsg: "canceled",
		prompt: "info",
		headerfields: {
			levelOneLabel: "name",
			levelTwoLabel: "attribute",
			state: "Status",
			creator: "creator",
			creatTime: "creat date",
			operation: "operation"
		},
		levelOneDetail: {
			title: "chack the type",
			tagName: "type",
			levelTwoName: "secondary tag",
			tagDetail: "tag details",
			clicks: " click count"
		},
		setHotTags: {
			title: "设置热门标签",
			tagName: "标签名称",
			hot: "设置热门",
			cancelHot: "取消热门",
			hotSort: "热门排序",
			operation: "operation",
			serialNumber: "序号"
		},
		addOrEdit: {
			addTitle: "添加一级标签",
			editTitle: "编辑一级标签",
			tagName: "标签名称",
			tagPlaceholder: "请填写标签名称",
			levelTags: "二级标签"
		},
		labelRule: {
			name: [{
				required: true,
				message: "input 标签名称",
				trigger: "blur"
			}]
		},
		shelvesOperation: {
			msg: "确定Show up所选标签吗？",
			success: "showed up",
			cancelMsg: "canceled",
			prompt: "show up info"
		},
		soldOutOperation: {
			msg: "确定下架所选标签吗？",
			success: "Hidden",
			cancelMsg: "canceled",
			prompt: "hidden info"
		},
		courseTable: {
			id: "ID",
			categories: "fitness type",
			title: "workout",
			difficulty: "level",
			duration: "duration",
			source: "from",
			operating: "operation"
		},
		options: {
			viewCourse: "check",
			deletCourse: "remove"
		},
		deleteCourse: {
			title: "info",
			content: "Are you sure to delete this workout?",
			ok: "confirm",
			no: "cancel",
			okDelete: "The workout was successfully deleted",
			noDelect: "canceled"
		}
	},
	classtagTwo: {
		tagName: "tag Name",
		tagNamePlaceholder: "input tag name",
		state: "Status",
		statePlaceholder: "select state",
		creatTime: "creatTime",
		startTime: "start date",
		endTime: "end date",
		hotTag: "hot",
		shelves: "Show up",
		soldOut: "Hidden",
		deleteTagMsg: "Do you want to delete the tag？",
		deleteTagSuccess: "The tag was removed successfully",
		cancelMsg: "canceled",
		prompt: "info",
		addTagRule: {
			name: [{
				required: true,
				message: "input tag Name",
				trigger: "blur"
			}],
			suitsCrowd: [{
				required: true,
				message: "input Suggestion",
				trigger: "change"
			}],
			courseIntroduction: [{
				required: true,
				message: "input Description",
				trigger: "change"
			}],
			effect: {
				value: [{
					required: true,
					message: "please select the fat burning interval.",
					trigger: "change"
				}],
				fatBurn: [{
					required: true,
					message: "please select the fat burning interval.",
					trigger: "change"
				}],
				cardiopulmonary: [{
					required: true,
					message: "please select the cardiopulmonary interval ",
					trigger: "change"
				}],
				endurance: [{
					required: true,
					message: "please select the Endurance Exercise interval",
					trigger: "change"
				}],
				power: [{
					required: true,
					message: "please select the Strength Exercise interval",
					trigger: "change"
				}]
			}
		},
		headerfields: {
			attributionTag: "Attribution tags",
			levelTwoLabel: "tag",
			state: "Status",
			creator: "creator",
			creatTime: "creatTime",
			operation: "operation"
		},
		levelTwoDetail: {
			title: "check tag",
			addTitle: "add tag",
			editTitle: "edit tag",
			tagName: "tag",
			tagNamePlaceholder: "input tag name",
			introduce: "Description",
			suitsCrowd: "Suggestion",
			effectCourse: {
				title: "Effects",
				fatBurn: "Fat burning",
				cardiopulmonary: "Cardiopulmonary",
				endurance: "Endurance",
				power: "Power",
				interval: "Interval",
				fatBurnPlaceholder: "please select the fat burning interval.",
				cardiopulmonaryPlaceholder: "please select the cardiopulmonary interval",
				endurancePlaceholder: "please select the Endurance Exercise interval",
				powerPlaceholder: "please select the Strength Exercise interval"
			},
			attributionTag: "Attribution tags",
			attributionTagPlaceholder: "please select Attribution tags",
			levelTwoName: "secondary tag",
			tagDetail: "tag Detail",
			clicks: "click count"
		},
		setHotTags: {
			title: "Set the Hot Tabs",
			tagName: "tag Name",
			operation: "operation"
		},
		addOrEdit: {
			addTitle: "add the first level tag",
			editTitle: "edit the first level tag",
			tagName: "tag Name",
			tagPlaceholder: "input tag Name",
			levelTags: "secondary tag"
		},
		labelRule: {
			name: [{
				required: true,
				message: "input tag Name",
				trigger: "blur"
			}]
		},
		shelvesOperation: {
			msg: "Are you sure whow up all the selected tag ？",
			success: "showed up",
			cancelMsg: "canceled",
			prompt: "show up info"
		},
		soldOutOperation: {
			msg: "Are you sure to hide all the selected tag？",
			success: "Hidden",
			cancelMsg: "canceled",
			prompt: "hidden info"
		}
	},
	userlist: {
		id: "ID",
		idPlaceholder: "input ID",
		nickName: "name",
		nickNamePlaceholder: "input name",
		phone: "phoneNumber",
		phonePlaceholder: "input phoneNumber",
		email: "Email address",
		emailPlaceholder: "input Email address",
		userTag: "User tag",
		userTagNamePlaceholder: "input user tag",
		sex: "sex",
		sexPlaceholder: "Please select gender",
		userGroup: "user group",
		userGroupPlaceholder: "please user group",
		addTag: "add tag",
		addTagsPrompt: {
			title: "info",
			content: "Confirm to add the label for the selected user？",
			noUser: "Please select the user first"
		},
		vipTypeMap: {
			4: "average user",
			8: "members"
		},
		noVip: "non-members",
		headerfields: {
			id: "ID",
			header: "image",
			nickName: "name",
			phone: "phone Number",
			email: "Email address",
			sex: "sex",
			age: "age",
			vipType: "user type",
			vipDeadline: "Member length",
			registrationTime: "Registration date",
			groundnuts: "Token money",
			registrationSource: "way of Registration",
			operation: "operation",
			userDetails: "user Details"
		},
		addTagDiaDlog: {
			title: "add tag",
			addtagName: "add tag",
			addtagPlaceholder: "select tag",
			tagsRule: {
				selectedTag: [{
					required: true,
					message: "input tag",
					trigger: "blur"
				}]
			}
		}
	},
	userGroup: [{
			value: "",
			text: "all"
		},
		{
			value: 8,
			text: "members"
		},
		{
			value: 4,
			text: "average user"
		}
	],
	userdetail: {
		baseObj: {
			title: "info",
			userTag: "user tag",
			addtagName: "add tag",
			tagName: "user tag",
			userId: "user ID",
			userType: "user type",
			setToCoach: "Set to coach",
			vip: "members",
			phone: "phone Number",
			email: "Email address",
			sex: "sex",
			addtagPlaceholder: "please select tag",
			tagsRule: {
				selectedTag: [{
					required: true,
					message: "input tag",
					trigger: "blur"
				}]
			}
		},
		userAssetsObj: {
			title: "User assets",
			groundnuts: "token money",
			groundnutsUnit: "count",
			detail: "detail",
			hardwareDevices: "equipment",
			isBinding: "Binding",
			unbounded: "unbounded",
			heartRateBelt: "heart rate recognized",
			coach: "token money history",
			exchange: "变动",
			bicycle: "bicycle",
			treadmill: "treadmill",
			rowingMachine: "Rowing machine",
			watch_record_map: {
				1: "系统奖励",
				2: "打赏教练",
				3: "盲盒抽奖",
				4: "盲盒抽奖归还",
				5: "活动报名",
				6: "活动报名退还",
				7: "活动奖励",
				8: "任务奖励",
				9: "训练奖励"
			},
			watch_record_map_one: "System Rewards",
			watch_record_map_two: "System Rewards",
			watch_record_map_zero: "System Rewards"
		},
		movementDataObj: {
			title: "Exercise data",
			times: "times",
			duration: "duration",
			totalConsumption: "total consumption",
			totalDistance: "total Distance",
			id: "workout id",
			name: "workout",
			movementTime: "length",
			burnCalories: "Burn calories",
			movementDistance: "Distance",
			moveStart: "date",
			calories: "calories",
			timts: "times",
			km: "km"
		},
		bodyFlie: {
			title: "bodyFlie"
		}
	},
	addcoursecategory: {
		classifyName: "type",
		classifyNamePlaceholder: "input type name",
		defaultValue: "default Value",
		classifyNameerr: "type already exists",
		attrName: "attribute",
		attrNamePlaceholder: "input attribute name",
		introduce: "Description",
		introducePlaceholder: "input Description",
		suitsCrowd: "Suggestion",
		suitsCrowdPlaceholder: "input Suggestion",
		effectOfCourse: "Effects",
		effectOfCoursePlaceholder: "select Effects",
		fatBurn: "Fat burning",
		cardiopulmonary: "Cardiopulmonary",
		endurance: "Endurance",
		power: "Power",
		interval: "Interval",
		fatBurnPlaceholder: "please select the fat burning interval.",
		cardiopulmonaryPlaceholder: "please select the cardiopulmonary interval",
		endurancePlaceholder: "please select the Endurance Exercise interval",
		powerPlaceholder: "please select the Strength Exercise interval",
		addTheDefaultBtn: "Add default Value"
	},
	theme: {
		videoSizePrompt: "Upload video file size cannot exceed 500MB!",
		themeSearch: [{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "shelf status",
						value: 1
					},
					{
						label: "Off-shelf status",
						value: 0
					},
					{
						label: "Not listed",
						value: 2
					}
				]
			},
			{
				label: "id",
				prop: "id"
			},
			{
				label: "Name of the series",
				prop: "group_name"
			},
			{
				label: "Distributed language area",
				prop: "language_area",
				type: "select",
				option: [] //从服务端获取
			},
			// { //2.2.6新增
			//   label: "Level",
			//   prop: "fitness_level",
			//   type: "select",
			//   option: [
			// 	{ label: "all", value: "" },
			// 	{ label: "Beginners", value: 1 },
			// 	{ label: "Intermediate", value: 2 },
			// 	{ label: "Advanced", value: 3 }
			//   ]
			// },
      { //难度
        label: "Level",
        prop: "level",
        type: "select",
        option: [
					{ label: "全部", value: "" },
					{ label: "Y1", value: 1 },
					{ label: "Y2", value: 2 },
					{ label: "Y3", value: 3 },
					{ label: "Y4", value: 4 },
        ]
      },
			{ //2.2.6新增
			  label: "Goals",
			  prop: "sports_purpose",
			  type: "select",
			  option: [
				{ label: "all", value: "" },
				{ label: "Burning Fat", value: 1 },
				{ label: "Reduce Stress", value: 2 },
				{ label: "Athletic Ability", value: 3 }
			  ]
			},
			{ //2.2.7新增
			  label: "收费方式",
			  prop: "group_type",
			  type: "select",
			  option: [
				{ label: "全部", value: "" },
				{ label: "VIP", value: 1 },
				{ label: "免费", value: 2 },
			  ]
			}
		],
		themeList: [{
				label: "Id",
				prop: "id",
				type: "normal",
				width: "80px"
			},
			{
				label: "Name of the series",
				prop: "groupName",
				type: "normal"
			},
			{ label: "Level", prop: "level", type: "normal" },//2.2.6新增
			{
				label: "Status",
				prop: "shelf",
				type: "normal"
			},
			{ label: "Training weeks", prop: "recommendedTrainingWeeks", type: "normal",width: "80px" },//2.2.6新增
			{
				label: "Workouts Count",
				prop: "courseNum",
				type: "normal",
				width: "90px" 
			},
			{ label: "Goals", prop: "courseGroupSportsPurposeList", type: "normal" ,width: "156px",align:"center"},//2.2.6新增
			{
				label: "Distributed language area",
				prop: "languageAreas",
				type: "tag"
			},
			{ label: "收费方式", prop: "groupType", type: "normal" },//2.2.7新增
			{
				label: "Created time",
				prop: "createTime",
				type: "normal",
				width: "180px"
			},
			{
				label: "Shelf time",
				prop: "shelfTime",
				type: "normal",
				width: "180px"
			},

			{
				label: "operation",
				prop: "action",
				type: "button",
				width: "360px",
				button: [
					// { label: "Configuration workout", type: "primary" }, ==>2.2.2 版本config 按钮文字表述变更，2.2.2版本发布后，可删除
					{
						label: "Configure",
						type: "primary"
					},
					{
						label: "Edit",
						type: "primary",
						limit: "shelf",
						limitValue: "Shelf status"
					},
					{
						label: "Check",
						type: "primary"
					},
					{
						label: "Delete",
						limit: "shelf",
						limitValue: "Shelf status"
					}
				],
				method: ["_config", "_edit", "_check", "_delete"]
			}
		],
		searchData: [{
				label: "ID",
				prop: "show_id"
			},
			{
				label: "Name",
				prop: "course_name"
			},
			{
				label: "Duration",
				prop: "duration",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "15min",
						value: 15 * 60
					},
					{
						label: "20min",
						value: 20 * 60
					},
					{
						label: "30min",
						value: 30 * 60
					},
					{
						label: "45min",
						value: 45 * 60
					},
					{
						label: "60min",
						value: 60 * 60
					}
				]
			},
			{
				label: "Difficulty",
				prop: "level_id",
				type: "select",
				option: []
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "Not listed",
						value: "0"
					},
					{
						label: "shelf status",
						value: "1"
					},
					{
						label: "off-shelf status",
						value: "2"
					}
				]
			}
		],
		searchData_2: [{
				label: "workout",
				prop: "course_name"
			},
			{
				label: "fitness type",
				prop: "class type_id",
				type: "select",
				option: []
			},
			{
				label: "Status",
				prop: "shelf",
				type: "select",
				option: [{
						label: "all",
						value: ""
					},
					{
						label: "Hidden",
						value: 0
					},
					{
						label: "Show up",
						value: 1
					},
					{
						label: "Hidden",
						value: 2
					}
				]
			}
		],
		levelOtion: [{
				label: "primary",
				value: 1
			},
			{
				label: "intermediate",
				value: 2
			},
			{
				label: "senior",
				value: 3
			}
		],
		tableColumn: [{
				label: "",
				prop: "courseSeq",
				type: "normal"
			},
			{
				label: "workout",
				prop: "courseName",
				type: "normal"
			},
			{
				label: "level",
				prop: "levelName",
				type: "normal"
			},
			{
				label: "duration",
				prop: "duration",
				type: "normal"
			}
		],
		courseUsedTableColumn: [{
				label: "id",
				prop: "id",
				type: "normal"
			},
			{
				label: "Name",
				prop: "courseName",
				type: "normal"
			},
			{
				label: "Difficulty",
				prop: "courseLevel",
				type: "normal"
			},
			{
				label: "Duration",
				prop: "courseDuration",
				type: "normal"
			},
			{
				label: "Start time",
				prop: "beginTime",
				type: "normal"
			}
		],
		tableColumn_2: [{
				label: "Coures ID",
				prop: "courseId",
				type: "normal"
			},
			{
				label: "Name",
				prop: "courseName",
				type: "normal"
			},
			{
				label: "Difficulty",
				prop: "levelName",
				type: "normal"
			},
			{
				label: "Duration",
				prop: "duration",
				type: "normal"
			},
			{
				label: "位置",
				prop: "positionText",
				type: "normal"
			},
			{
				label: "Operation",
				prop: "action",
				type: "button",
				button: [{
						label: "位置设置",
						type: "primary"
					},{
						label: "Check",
						type: "primary"
					},
					{
						label: "Delete",
						limit: "shelf",
						limitValue: "Show up"
					}
				],
				method: ["_locationView","_check", "_delete"]
			}
		],
		first: "NO.",
		festival: "section",
		difficulty: "level",
		minute: "min",
		courseSort: "Sort",
		editTopics: "Edit Series",
		addTopics: "Add a program",
		creat: "Add a program",
		addCourseListUsed: {
			courseCategories: "fitness type",
			enterCategoryName: "input type name",
			selectStatus: "select state",
			chargeMethod: "Payment method",
			free: "free",
			vip: "members",
			distributionArea: "distributed",
			grade: "level",
			projectType: "special workouts type",
			zeroFoundationProject: "零基础专题课",
			topicCover: "image",
			topicCoverError: "Please choose a image",
			recommendedTrainingWeeks:"Training weeks", //2.2.3新增
			recommendedTrainingWeeksError:"Please enter training weeks of the series", //2.2.3新增
			decimalProhibited:"Decimal and negative numbers are not allowed",//2.2.3新增
			integerGreater:"Digital range: 1-4",//2.2.3新增
			topicCourseName: "Name",
			inputTopicCourse: "Please enter name of the series",
			topicIntroduction: "Distributed language area",
			inputContent: "",
			suitablePeople: "Suggestion",
			precautions: "Introduction",
			precautionsPlaceholder: "Please enter introduction",
			videoIntroduction: "Description",
			videoContent: "Description",
			videoCover: "videoCover",
			participatePeople: "Fake number of people",
			participatePeoplePlaceholder: "Place enter fake number of people",
			browserCompatible: "Your browser does not support the HTML5 video tag。",
			needInstrument: "equipment",
			coach: "coach",
			courseDetails: "Description"
		},
		courseConfig: {
			batchDelete: "Batch deletion",
			creatCourse: "creat workout",
			addCourse: "Add a course",
			sort: "Sort",
			formRecordChoose: "select from ",
			formPreviousChoose: "select from past workouts",
      formRealChoose: "从实景骑行选择",
			shelf2: "Hidden",
			shelf1: "Show up",
			shelf0: "Hidden",
			shelf: "no-state",
			shelf2New: "Not listed",
			shelf1New: "Shelf status",
			shelf0New: "Off-shelf status",
			shelfNew: "Not listed",
			freeTrial: "free",
			specialCourses: "special course",
			abdominalWheel: "健腹轮",
			noCategoryFound: "未找到分类",
			allLang: "all"
		},
		confirm: {
			title: "info",
			isDelete: "是否删除该课程?",
			isDeleteTopics: "Whether to delete the series?",
			isDeleteSelect: "Whether to delete the batch?"
		},
		message: {
			cancelDelete: "cancel Delete",
			inputUserName: "input user name"
		}
	},
	usermanager: {
		inputSelectTag: "input tag",
		inputTagName: "input tag Name"
	},
	livecourselist: {
		pushTitle: "Push stream address",
		languagePlaceholder: "select language",
		id: "ID",
		idPlaceholder: "input ID",
		tagName: "tag",
		courseStartTime: "start time",
		tagNamePlaceholder: "input tag Name",
		courseName: "workout",
		coursePlaceholder: "input workout",
		coachaName: "coach",
		coachNamePlaceholder: "input coach name",
		categoryName: "fitness type",
		categoryNamePlacholder: "select fitness type",
		coachDifficulty: "level",
		difficultyPlacholder: "select level",
		timing: "Duration",
		timingPlacholder: "select Duration",
		status: "Status",
		statusPlacholder: "select state",
		addTag: "add tag",
		delectedSection: "Deleted workouts",
		distributionLanguages: "distribution Languages",
		fileName: "workouts data file.xls",
		addCourseBtn: "add",
		charge: "Payment method",
		chargePlacholder: "select Payment method",
		lang: "Distribution languages",
		langPlacholder: "select Distribution of languages",
		headerfields: {
			id: "ID",
			courseName: "workout",
			categoryName: "class type",
			distributionLanguages: "Distribution languages",
			language: "language",
			copyCourse: "copy",
			coachDifficulty: "level",
			timing: "Duration",
			startTime: "start time",
			status: "Status",
			coachName: "coach",
			operation: "operation",
			addTag: "add tag",
			langArea: "Distribution languages",
			lang: "language",
			showTag: "tag",
			sourceName: "source" // 2.2.3 新增 source字段
		},
		pusbFields: {
			ausAddress: "Australia - Push Stream address",
			serbiaAddress: "Serbia - Push stream address"
		},
		shelvesOperation: {
			msg: "Are you sure to show up the selected live workouts？",
			success: "showed up",
			cancelMsg: "canceled",
			prompt: "operation info"
		},
		checkTheCourse: "Please check the workout first",
		soldOutOperation: {
			msg: "Are you sure to hide the selected live workouts？",
			success: "Hidden",
			cancelMsg: "canceled",
			prompt: "operation info"
		},
		previousCourseShelvesOperation: {
			msg: "Are you sure to show up the selected past workouts？",
			success: "showed up",
			cancelMsg: "canceled",
			prompt: "operation info"
		},
		previousCourseSoldOutOperation: {
			msg: "Are you sure to hide the selected past workouts？",
			success: "Hidden",
			cancelMsg: "canceled",
			prompt: "operation info"
		},
		restoreOperation: {
			msg: "Are you sure to resume your selected live workouts？",
			success: "restored",
			cancelMsg: "canceled",
			prompt: "operation info"
		},
		addCourse: {
			title: "add tag",
			placeholder: "select tag"
		},
		prompt: {
			title: "info",
			content: "Are you sure to delete the selected workouts?",
			success: "deletion successfully",
			error: "deletion failed"
		},
		showTagObj: {
			title: "tag",
			coursrName: "workout",
			classifyName: "type",
			tagName: "tag Name",
			operation: "operation",
			comfirm: "comfirm",
			cancel: "cancel"
		}
	},
	coachDifficulty: [{
			value: "",
			text: "all"
		},
		{
			value: "Y1",
			text: "Y1"
		},
		{
			value: "Y2",
			text: "Y2"
		},
		{
			value: "Y3",
			text: "Y3"
		},
		{
			value: "Y4",
			text: "Y4"
		},
		{
			value: "Y5",
			text: "Y5"
		},
		{
			value: "Y6",
			text: "Y6"
		}
	],
	creatcoachDifficulty: [{
			value: "Y1",
			text: "Y1"
		},
		{
			value: "Y2",
			text: "Y2"
		},
		{
			value: "Y3",
			text: "Y3"
		},
		{
			value: "Y4",
			text: "Y4"
		},
		{
			value: "Y5",
			text: "Y5"
		},
		{
			value: "Y6",
			text: "Y6"
		}
	],
	coachInterval: [{
			value: "Y1.1",
			text: "Y1.1"
		},
		{
			value: "Y1.2",
			text: "Y1.2"
		},
		{
			value: "Y1.3",
			text: "Y1.3"
		},
		{
			value: "Y1.4",
			text: "Y1.4"
		},
		{
			value: "Y1.5",
			text: "Y1.5"
		},
		{
			value: "Y1.6",
			text: "Y1.6"
		}
	],
	coachDuration: [{
			value: "",
			text: "all"
		},
		{
			value: "15",
			text: "15min"
		},
		{
			value: "20",
			text: "20min"
		},
		{
			value: "30",
			text: "30min"
		},
		{
			value: "45",
			text: "45min"
		},
		{
			value: "60",
			text: "60min"
		}
	],
	creatCoachDuration: [{
			value: "15",
			text: "15min"
		},
		{
			value: "20",
			text: "20min"
		},
		{
			value: "30",
			text: "30min"
		},
		{
			value: "45",
			text: "45min"
		},
		{
			value: "60",
			text: "60min"
		}
	],
	createcourse: {
		btnAdd: "add",
		hint: "TIPS:After the live workout’s course is over, paste live workout address of the video file here and save it. The system will automatically convert the live workout’s course to the past workout’s course.",
		sizeHint: "TIPS:1. After filling in the data, the number of reservations for the front-end course = the number of real reservations + the number of robot reservations. 2. For past workout’s courses completed by trans-coding, the number of participants displayed on the front end = the number of real participants + the number of robot participants .",
		transVideoUrl: "the live workout's Video file address",
		transVideoUrlPlaceHolder: "input the live workout's Video file address",
		endTimeErr: "The end of the past workouts should not be later than today",
		endTimeString: "end time",
		language: "language",
		languagePlaceholder: "Please select language",
		pastCover: "image of past workouts cover",
		pastCoverSize: "Suggest size：1029*579",
		pastCoverRequire: "select image for past workouts cover",
		pastDetailCover: "image of past workouts Detail",
		pastDetailCoverRequire: "select image for past workouts Detail",
		pastDetailCoverSize: " Suggest size：1125*786",
		courseName: "workout",
		courseLessonPlans: "workout's notes",
		coursePlaceholder: "input workout",
		videoUrlPlaceholder: "input the live workout's Video file address",
		distributionLanguages: "Distribution languages",
		distributionLanguagesRequire: "please check Distribution of languages",
		type: "fitness type",
		typeRequire: "please select fitness type",
		courseTarget: "workout target",
		liveRoom: "Live studio location",
		distributeLang: "Distribution languages",
		courseTargetRequire: "please select workout target",
		liveRoomRequire: "Please select a studio",
		distributeLangRequire: "please check Distribution of languages",
		courseClass: "class type",
		courseClassRequire: "select class type",
		sourceName:"source", //2.2.3新增 source字段
		sourceNameRequire:"Please select source",//2.2.3新增 source字段
		suitableLevel1:"Level",
		suitableLevel:"Level",//2.2.6新增适合水平
		suitableLevelRequire:"Please select the level",//2.2.6新增
		sportsPurpose:"Goals",//2.2.6新增运动目的
		sportsPurposeRequire:"Please select the purpose of it",//2.2.6新增
		weeklyCourse:"Number of classes per week",//2.2.6新增
		weeklyCourseRequire:"Please enter the number of classes per week",//2.2.6新增
		weeklyLimit:"Digital range: 2-7",//2.2.6新增
		suitableLevelArr1:{
			1: "Y1",
			2: "Y2",
			3: "Y3" ,
			4: "Y4" ,
		},
		suitableLevelArr:{
			1: "Beginners",
			2: "Intermediate",
			3: "Advanced" 
		},//2.2.6新增适合水平选择数组
		suitableLevelArrSearch:[
			{value:'',text:'all'},
			{value:1,text:'Beginners'},
			{value:2,text:'Intermediate'},
			{value:3,text:'Advanced'},
		],//2.2.6新增适合水平筛选数组
		sportsPurposeArr:{
			1: "Burning Fat",
			2: "Reduce Stress",
			3: "Athletic Ability"
		},//2.2.6新增运动目的选择数组
		sportsPurposeArrSearch:[
			{value:'',text:'all'},
			{value:1,text:'Burning Fat'},
			{value:2,text:'Reduce Stress'},
			{value:3,text:'Athletic Ability'},
		],//2.2.6新增运动目的筛选数组
		startTypeAttr: "load default values",
		typeAttr: "TAG",
		attrTrue: "yes",
		attrFalse: "no",
		courseIntroduce: "Description",
		courseIntroducePlaceholder: "input workout Description",
		suitsCrowd: "Suggestion",
		suitsCrowdPlaceholder: "input workout Suggestion",
		smartInstrument: "Exercise equipment",
		smartInstrumentRequire: "select Exercise equipment",
		nonIntelligentInstrument: "Auxiliary equipment",
		nonIntelligentInstrumentRequire: "select Auxiliary equipment",
		noequipment: "without equipmen",
		delectTitle: "info",
		delectsubSection: "Delete section, do you want to continue?",
		nodelect: "This data is not allowed to be deleted",
		delectSection: "Delete action group, do you want to continue？",
		delectTrain: "Delete action, do you want to continue?",
		scoreSelect: [{
			value: 1,
			label: "yes"
		}, {
			value: 0,
			label: "no"
		}],
		cycleIndexName: {
			2: "Pedal frequency(target)",
			3: "Rowing frequency(target)",
			4: "speed(target)" // 1: 'Heart rate(target)'
		},
		rangeValueHeads: {
			"0": {
				maxTargetHeartRate: "Maximum power %（target）",
				minTargetHeartRate: "Minimum power %（target）"
			},
			"1": {
				maxTargetHeartRate: "Maximum heart rate %（target）",
				minTargetHeartRate: "Minimum heart rate %（target）"
			},
			"5": {
				maxTargetHeartRate: "Maximum heart rate %（target）",
				minTargetHeartRate: "Minimum heart rate %（target）"
			},
			"6": {
				maxTargetHeartRate: "Maximum heart rate %（target）",
				minTargetHeartRate: "Minimum heart rate %（target）"
			}
		},
		fatremark: "The higher the index, the longer the stay in the fat burning zone",
		cardiopulmonaryremark: "The higher the index, the more obvious the intensity changes in the course",
		effectCourse: {
			heartMinMsg: "The minimum heart rate data should be greater than",
			powerMinMsg: "The minimum power data should be greater than",
			heartHightAndLow: "The minimum heart rate should not be higher than the maximum",
			powerHightAndLow: "The minimum power shall not be higher than the maximum",
			title: "Effects",
			fatBurn: "Fat burning",
			cardiopulmonary: "Cardiopulmonary",
			endurance: "Endurance",
			power: "Power",
			// interval: 'Interval',
			requiredEquipment: "Exercise equipment",
			fatBurnPlaceholder: "please select the fat burning interval.",
			fatremarkPlaceholder: "Description",
			cardiopulmonaryPlaceholder: "please select the cardiopulmonary interval",
			endurancePlaceholder: "please select the Endurance Exercise interval",
			powerPlaceholder: "please select the Strength Exercise interval",
			trainingMode: "mode",
			trainingModePlaceholder: "select mode",
			distributionArea: "distributed",
			freeExperience: "free",
			rate: "power",
			heartRate: "Heart rate",
			steppedFrequency: "Pedal frequency",
			speedTndicator: "speed",
			plasmaFrequency: "Rowing frequency",
			expect: "expect",
			calories: "calories",
			expectCalories: "Please input the estimated calorie value",
			intCalories: "Calorie data must be positive integers",
			courseInstructor: "coach",
			courseInstructorPlaceholder: "select coach",
			courseImage: "image of Live workouts",
			courseImage0: "image of Live workouts List",
			coursePicPlaceholder: "please upload image for the past workouts list",
			courseImage1: "image of live workouts detail's head",
			courseImagePlaceholder: "please upload image for the Live workouts",
			courseCoverImagePlaceholder: "please upload image for the Live workouts list",
			courseInfoImagePlaceholder: "please upload image for the  live workouts detail",
			videoCourseImage: "image of past workouts",
			videoCourseImageIntro: "image of past workouts detail",
			recordCourseImage: "录播课图片",
			videoCourseImagePlaceholder: "please upload image for the past workouts list",
			videoCourseImageIntroPlaceholder: "please upload image for the past workouts detail",
			recordCourseImagePlaceholder: "Please upload a picture of the recorded lesson",
			wayToCharge: "Payment method",
			wayToChargePlaceholder: "select Payment method",
			free: "free",
			free2: "free",
			vip: "vip",
			participation: "the number of robot reservations",
			participationPlaceholder: "input the number of robot reservations",
			thumbUpNumber: "点赞人数",
			difficultyOfCourse: "level",
			difficultyRequire: "select level",
			intervalRequire: "Please select the interval",
			interval: "Interval",
			intervalPlaceholder: "Please select the interval",
			startTime: "start date",
			startTimePlaceholder: "set start date",
			courseDuration: "Duration",
			courseDurationPlaceholder: "select Duration",
			courseTarget: "workout target",
			subSection: "section",
			subSectionPlaceholder: "select section",
			section: "action group",
			copySectionTips: "(Copy the current group)",
			train: "action",
			music: "music",
			musicPlaceholder: "select music",
			RPM: "RPM",
			RPMPlaceholder: "set RPM",
			totalTeachingTime: "total Duration",
			totalTime: "section Duration",
			add: "add",
			totalTimePlaceholder: "请填写总时长",
			daration: "Duration(sec)",
			darationPlaceholder: "set the action Duration",
			actionName: "action",
			actionNamePlaceholder: "select action",
			targetPower: "Pedal frequency(target)",
			pace: "步频",
			stepRatePlaceholder: "input 步频",
			isScore: "score?",
			isScoreRequire: "please select",
			isBarrage: "PUSH?",
			teachingLessonPlans: "workout notes",
			allPushTime: "总推次",
			allsectionTime: "推次",
			fPush: "正推",
			lPush: "左推",
			rPush: "右推",
			fPushPlaceholder: "input 正推",
			lPushPlaceholder: "input 左推",
			rPushPlaceholder: "input 右推",
			isBarrageRequire: "PUSH?",
			targetPowerPlaceholder: "set the target",
			targetPowerMaxPlaceholder: "set Maximum power (%)",
			SteppedFrequency: "Pedal frequency",
			SteppedFrequencyPlaceholder: "set the target of Pedal frequency",
			resistance: "resistance",
			resistancePlaceholder: "input the workout's resistance data",
			color: "color",
			colorPlaceholder: "请填写该训练目标颜色",
			videoInfo: "videoInfo",
			transcodingVideoAddress: "live workout's Video file address",
			transcodingVideoPlaceholder: "input the live workout's Video file address",
			orgVideoLink: "录播高清地址",
			orgVideoLinkPlaceholder: "请填写录播高清地址",
			clearLink: "标清链接",
			clearLinkPlaceholder: "请填写标清链接",
			highDefinitionLink: "高清链接",
			highDefinitionLinkPlaceholder: "请填写高清链接",
			liveVideoHigh: "直播高清",
			liveVideoStandard: "直播标清",
			originalVideoHigh: "录播高清",
			originalVideoStandard: "录播标清",
			liveVideoHighPlaceholder: "input 直播高清",
			liveVideoStandardPlaceholder: "input 直播标清",
			originalVideoHighPlaceholder: "input 录播高清",
			originalVideoStandardPlaceholder: "input 录播标清",
			addressSupportChinese: "视频地址不支持中文",
			historyVideoDelayTimeSeconds: "历史视频延时时间秒",
			delatSecondPlacholder: "请填写历史视频延时时间",
			delaySecondIntroduce: "0:正常；<0:直播视频过早录制的秒数,客户端需要跳过前此值;>0：课程过晚录制的时间，客户端的运动数据需要跳过的秒数。",
			liveRankingParticipants: "The number of ranking",
			liveParticipantsPlacholder: "input The number of ranking",
			remark: "workout remarks",
			effectivePowerValue: "input Valid target value"
		}
	},
	viewCourse: {
		courseName: "workout",
		courseDuration: "Duration",
		peoples: "joined",
		reservationNumber: "reservation",
		coachScore: "coach Score",
		theSeedingRate: "completion rate",
		departureRate30: "give up rate（0-30%）",
		departureRate50: "give up rate（30%-50%）",
		departureRate80: "give up rate（50%-80%）",
		averageClassTime: "average Duration",
		courseDetail: "workout Detail",
		courseData: "workout data"
	},
	reviewedlist: {
		setHotCourse: "Set the hot workout",
		courseName: "workout",
		operation: "operation",
		cancelHot: "Remove hot"
	},
	classtaglist: {
		setHotTypeMsg: "Please check the class type first",
		setHotTagMsg: "Please check the tag first",
		shelvesOperation: {
			msg: "确定上架已选分类吗？",
			success: "已上架",
			cancelMsg: "已取消",
			prompt: "上架提示"
		},
		soldOutOperation: {
			msg: "确定下架所选分类吗？",
			success: "Hidden",
			cancelMsg: "已取消",
			prompt: "下架提示"
		},
		deleteTag: {
			prompt: "info",
			msg: "确定删除该标签吗？",
			success: "已删除",
			cancelMsg: "已取消"
		}
	},
	typeofcourse: {
		typeName: "类型名称",
		typeIcon: "类型图标",
		typeIntroduce: "类型介绍",
		suitsTheCrowd: "Suggestion",
		typeNameOpen: "是否启用workout",
		status: "Status",
		isHot: "是否热门",
		operation: "operation",
		edit: "编辑",
		new: "新增",
		reload: "刷新",
		newTypeCourse: "新增课程类型",
		editTypeCourse: "编辑课程类型",
		saveBtn: "保存内容",
		cencel: "cancel",
		addFORM: {
			typeName: "类型名称",
			introduction: "类型介绍",
			for_the_crowd: "Suggestion",
			recommend: "workout启用状态",
			is_hot: "热门",
			icon_url: "图标",
			upImg: "上传图片",
			status: "Status",
			isHot: "是",
			notHot: "否",
			recommendenable: "启用名称",
			recommenddisable: "禁用名称",
			statusenable: "启用状态",
			statusdisable: "禁用状态"
		},
		typeCourseRules: {
			name: [{
				required: true,
				message: "input 类型名称",
				trigger: "blur"
			}],
			introduction: [{
				required: true,
				message: "input Description",
				trigger: "blur"
			}],
			for_the_crowd: [{
				required: true,
				message: "input Suggestion",
				trigger: "blur"
			}],
			recommend: [{
				required: true,
				message: "请选择workout启用状态",
				trigger: "change"
			}],
			is_hot: [{
				required: true,
				message: "请选择是否热门",
				trigger: "change"
			}],
			icon_url: [{
				required: true,
				message: "请上传图标图片",
				trigger: "blur"
			}],
			status: [{
				required: true,
				message: "请启用选择状态",
				trigger: "blur"
			}]
		}
	},
	coursemanagementlist: {
		courseDate: "课程日期",
		courseTag: "课程标签",
		courseTagPlacholder: "请选择课程标签",
		today: "今天",
		model: {
			0: "功率训练",
			1: "Heart rate训练"
		},
		headerfields: {
			courseName: "workout",
			couchName: "教练名称",
			startTime: "课程开始时间",
			endTime: "课程结束时间",
			model: "模式",
			status: "课程状态",
			operation: "operation",
			edit: "编辑",
			phase: "阶段"
		}
	},
	creatOldCourse: {
		targetCalories: "目标卡路里",
		targetCaloriesPlacholder: "请填写目标卡路里",
		intCalories: "input 有效目标卡路里",
		calories: "卡路里",
		isfree: "是否免费",
		startTime: "课程开始时间",
		startTimePlaceholder: "请选择课程开始时间",
		courseType: "课程类型",
		courseTypePlacholder: "请选择课程类型",
		coursePic: "课程封面",
		videoPic: "视频封面",
		uploadPic: "上传图片",
		liveStandardVideo: "直播标清视频地址",
		livePlaceholder: "input 直播标清视频地址",
		liveHdVideoAddress: "直播高清视频地址",
		liveHdPlaceholder: "input 直播高清地址",
		vodMarkedVideoAddress: "點播标清视频地址",
		vodMarkedPlaceholder: "input 點播标清视频地址",
		hdVideoOnDemandAddress: "點播高清视频地址",
		hdVideoOnDemandPlaceholder: "input 點播高清视频地址",
		courseNotes: "课程remarks",
		courseNotesPlacholder: "input 内容",
		coursedifferent: "level",
		coursesCover: "课程封面",
		coursesCoverPlaceholder: "请上传课程封面",
		videoCover: "视频封面",
		videoCoverPlaceholder: "请上传视频封面",
		coursedifferentPlacholder: "请选择level",
		submitImmediately: "立即提交",
		content: "确认提交课程信息是否正确？",
		title: "info"
	},
	coursePhase: {
		content: "确认保存阶段",
		title: "info"
	},
	editclassify: {
		content: "确认移除该预设值",
		title: "info",
		remove: "已移除"
	},
	homepagebanner: {
		recommendationReason:"Reason for recommendation",//2.2.6新增
		reasonPlaceholder:"Please enter a reason for your recommendation",//2.2.6新增
		distributionTheater:"Distribution Regions",//2.2.6新增
		distributionTheaterPl:"Please select at least one distribution area",//2.2.6新增
		toastTips:"The current number of configured courses is not equal to the set total number of courses, the current number of courses = [number of training weeks] * number of courses per week",//2.2.6新增
		open:"开启",//2.2.6新增
		close:"关闭",//2.2.6新增
		name: "Name",
		name2: "昵称",
		namePlaceholder: "Please enter a name",
		doNotExceed: "请不要超过40个字",
		uploadImage: "Please upload pictures",
		homePageTap: "首页tab",
		homeRecommend: "Home-Recommendation",//2.2.6新增
		livePageTap: "直播tab",
		myTab: "我的tab",
		startPage: "启动页",
		loginPopup: "登录弹窗",
		floatButton: "浮动按钮",
		yeDouCenter: "野豆中心",
		exerciseRecord: "运动记录",
		padpopwindow: "Screen version-wake up pop-up",
		courseGuanggao: "课中广告位",
		status: "Status",
		homeLocation: "位置",
		homeLocationPlacholder: "请选择位置",
		type: "banner类型",
		statusPlacholder: "请选择状态",
		homesort: "sort",
		wait: "等",
		tagUsers: "个标签用户",
		visible: "visible",
		invisible: "invisible",
		popUpsNumber: "弹窗次数",
		each: "每次",
		once: "一次",
		all: "all",
		buttonImage: "按钮图片",
		countDown: "倒计时",
		enterCountDown: "input 倒计时",
		mustNumber: "倒计时必须为数字值",
		countDownIsTen: "倒计时最大为10秒",
		countDownIsZero: "倒计时最小为0秒",
		visibleState: "Visible state",
		second: "秒",
		location: "所在位置",
		home: "首页",
		recommendSize: "Recommended picture size",
		recommendAdd: 'and equal scale size',
		headerfields: {
			bannername: "banner名称",
			bannerImg: "Picture",
			type: "banner类型",
			skiplinks: "link",
			status: "Status",
			contentType:"内容类型",
			operation: "operation",
			contentId:"内容ID",
			recommendReason:"Reason for recommendation",
			whereTab: "所在tab",
			liveBroadcast: "直播",
			location: "位置",
			find: "发现",
			mine: "我的"
		},
		banneroperationmsg: "请勾选选项",
		homesorttable: {
			title: "首页banner排序",
			bannername: "banner名称",
			bannerImg: "banner图片",
			operation: "operation",
			removeaction: "移除",
			serialNumber: "Serial number",
			sort: "sort"
		},
		shelvesOperation: {
			msg: "确定上架所选banner吗？",
			success: "已上架",
			cancelMsg: "已取消",
			prompt: "上架提示"
		},
		soldOutOperation: {
			msg: "确定下架所选banner吗？",
			success: "Hidden",
			cancelMsg: "已取消",
			prompt: "下架提示"
		},
		deleteBannerMsg: "确定删除当前banner吗？",
		deleteBannerSuccess: "已成功删除banner",
		deleteBannerErr: "删除失败",
		cancelMsg: "已取消",
		prompt: "info"
	},
	instrumentTutorials: {
		videoLink: "视频链接",
		inputVideoLink: "视频链接不能为空",
		placeholderVideoLink: "input 视频链接",
		placeholderTitle: "input 标题",
		placeholderSubTitle: "input 副标题",
		videoCourseSort: "视频教程排序",
		subTitle: "副标题",
		inputTitle: "标题不能为空",
		inputSubTitle: "副标题不能为空",
		deviceType: "器械类型",
		selectDeviceType: "请选择器械类型"
	},
	creatbanner: {
		name: "名称",
		namePlaceholder: "请填写banner名称",
		bannerImg: "图片",
		bannerimgPlaceholder: "请选择图片",
		type: "banner类型",
		typePlaceholder: "请选择banner类型",
		skiplinks: "跳转链接",
		skipTypeOrgPlaceholder: "请填写原生链接",
		skiplinksPlaceholder: "请填写跳转链接",
		whethertheshelf: "Is it on the shelf",
		homebanner: "首页banner",
		coursebanner: "课程页banner",
		true: "Yes",
		false: "No",
		skipTypeOrg: "Native link",
		skipTypeLink: "Jump link",
		linkType: "Type of link",
		linkAddress: "Linked address",
		formSearch: {
			name: [{
				required: true,
				message: "input banner名称",
				trigger: "blur"
			}],
			bannerImg: [{
				required: true,
				message: "请选择图片",
				trigger: "blur"
			}]
			// type: [
			//   {
			//     required: true,
			//     message: '请选择banner类型',
			//     trigger: 'blur'
			//   }
			// ],
			// skiplinks: [
			//   {
			//     required: true,
			//     message: '请填写跳转链接',
			//     trigger: 'blur'
			//   }
			// ],
			// status: [
			//   {
			//     required: true,
			//     message: '请选择是否上架',
			//     trigger: 'blur'
			//   }
			// ]
		}
	},
	bannerdetail: {
		bannerclicks: "banner点击数据"
	},
	articlelist: {
		title: "标题",
		titlePlaceholder: "input 标题名称",
		status: "是否置顶",
		statusPlacholder: "请选择状态",
		select: "精选",
		shelves: "Show up",
		soldOut: "Hidden",
		headerfields: {
			articleticle: "标题",
			articleImg: "图片",
			articleaddresses: "文章地址",
			createtime: "creatTime",
			status: "是否置顶",
			id: "id",
			operation: "operation"
		},
		articleoperationmsg: "请选择文章",
		articlesorttable: {
			setselectedarticles: "设置精选文章",
			articlename: "文章名称",
			operation: "operation",
			removeaction: "移除精选",
			setselect: "设置精选",
			serialNumber: "序号",
			sort: "排序"
		},
		shelvesOperation: {
			msg: "确定上架所选文章吗？",
			success: "已上架",
			cancelMsg: "已取消",
			prompt: "上架提示"
		},
		soldOutOperation: {
			msg: "确定下架所选文章吗？",
			success: "Hidden",
			cancelMsg: "已取消",
			prompt: "下架提示"
		},
		deleteBannerMsg: "确定删除当前文章吗？",
		deleteBannerSuccess: "已成功删除该文章",
		deleteBannerErr: "删除失败",
		cancelMsg: "已取消",
		prompt: "info"
	},
	creatarticle: {
		title: "标题",
		titlePlaceholder: "请填写文章标题",
		articleImg: "图片",
		articleimgPlaceholder: "请选择图片",
		contentUrl: "文章地址",
		contentUrlPlaceholder: "请填写文章地址",
		defaultreadpeople: "默认阅读人数",
		defaultPlaceholder: "请填写默认阅读人数",
		whethertheshelf: "是否置顶",
		true: "是",
		false: "否",
		formSearch: {
			title: [{
				required: true,
				message: "请填写文章标题",
				trigger: "blur"
			}],
			coverPic: [{
				required: true,
				message: "请选择图片",
				trigger: "blur"
			}],
			contentUrl: [{
				required: true,
				message: "请填写文章地址",
				trigger: "blur"
			}],
			published: [{
				required: true,
				message: "请选择是否置顶",
				trigger: "blur"
			}]
		}
	},
	articledetail: {
		articledata: "文章数据",
		browsethenumber: "浏览人数"
	},
	intPlaceholder: "input 正整数",
	stickOptions: [{
			status: "",
			text: "all"
		},
		{
			status: 1,
			text: "是"
		},
		{
			status: 0,
			text: "否"
		}
	],
	bannertypeOptions: [{
			status: "",
			text: "all"
		},
		{
			status: 1,
			text: "首页banner"
		},
		{
			status: 2,
			text: "课程页banner"
		}
	],
	stickStatusMap: {
		0: "否",
		1: "是"
	},
	bannerTypeMap: {
		1: "首页banner",
		2: "课程页banner"
	},
	bannerOptions: [{
			status: "",
			text: "all"
		},
		{
			status: 1,
			text: "Show up"
		},
		{
			status: 0,
			text: "Hidden"
		}
	],
	oldCourseMap: {
		0: "直播课Show up",
		3: "录播课Show up",
		4: "Hidden"
	},
	oldCourseOptions: [{
			status: "0",
			text: "直播"
		},
		{
			status: "3",
			text: "录播"
		},
		{
			status: "4",
			text: "Hidden"
		}
	],
	oldcourseOperation: "请勾选选项",
	oldshelvesOperation: {
		msg: "确定Show up所选吗？",
		success: "已上架",
		cancelMsg: "已取消",
		prompt: "上架提示"
	},
	oldsoldOutOperation: {
		msg: "确定下架所选吗？",
		success: "Hidden",
		cancelMsg: "已取消",
		prompt: "下架提示"
	},
	oldCoursestatus: "课程状态",
	oldCoursestatusPlaceholder: "请选择课程状态",
	shareText: {
		addModel: "添加分享文案",
		editModel: "编辑分享文案",
		addImageModel: "添加分享",
		editImageModel: "编辑分享图",
		view: "查看",
		allSendCrowd: "all",
		manSendCrowd: "男",
		madamSendCrowd: "女",
		stateless: "--",
		true: "是",
		false: "否",
		shelfText: "请勾选文案",
		shelfImg: "请勾选分享图"
	},
	coachlayout: {
		sex: {
			male: "male",
			female: "female"
		},
		hint: "TIPS：You need to register the email address account in the APP first, and Click to read the account information, automatically read the head picture, gender, name.",
		hintErr: {
			qualification: "tips:You can enter more than one, separated by, no more than 40 characters.",
			adept: "tips:input the type, and Enter to confirm to add, recommended no more than 3",
			manifesto: "tips:Input no more than 240 characters。",
			info: "tips:Input no more than 240 characters。"
		},
		field: {
			account: "Coach account",
			profession: "job title",
			qualification: "qualification",
			adept: "areas Expertise",
			manifesto: "motto",
			info: "Introduction",
			coverPic: "default image of the workout list",
			infoPic: "default image of the workout detail",
			header: "image",
			sex: "sex",
			name: "name"
		},
		errorText: {
			account: "input coach account (email address)",
			profession: "input job title",
			qualification: "input qualification",
			adept: "input areas Expertise",
			manifesto: "input motto",
			info: "input Introduction",
			coverPic: "upload default image for the workout list",
			infoPic: "upload default image for the workout detail"
		},
		formSearch: {
			email: [{
				required: true,
				message: "input Coach account",
				trigger: "blur"
			}],
			title: [{
				required: true,
				message: "input job title",
				trigger: "blur"
			}],
			achievement: [{
				required: true,
				message: "input qualification",
				trigger: "blur"
			}],
			expertise: [{
				required: true,
				message: "input areas Expertise",
				trigger: "blur"
			}],
			signText: [{
				required: true,
				message: "input motto",
				trigger: "blur"
			}],
			introduction: [{
				required: true,
				message: "input Introduction",
				trigger: "blur"
			}],
			coverPic: [{
				required: true,
				message: "upload default image for the workout list",
				trigger: "blur"
			}],
			infoPic: [{
				required: true,
				message: "upload default image for the workout detail",
				trigger: "blur"
			}]
		},
		size: {
			cover: "Suggest size：1029*579",
			info: "Suggest size：1125*786"
		},
		btn: {
			success: "confirm",
			cancel: "cancel",
			readAccount: "load account information"
		}
	},
	optionsByFree: [{
		label: "all",
		value: ''
	},{
		label: "members",
		value: 1
	}, {
		label: "free",
		value: 2
	}],
	RecommendedCourse: RecommendedCourse,
	reducedFatModel: reducedFatModel,
	bbsqianlongModel: bbsqianlongModel,
	programList: programList,
	faqmaneger: faqmaneger,
	riding: riding,
	//2.2.5新增  挑战活动
	dareActivity: {
	  btn: {
		sort: 'Sort',
		shelf1: 'Show up',
		shelf2: 'Hidden',
		create: 'Create a Challenge'
	  },
	  field: {
		id: 'ID',
		idPlaceholder:'Please enter the  ID number',
		name: 'Name',
		namePlaceholder:'Please enter the name of the event',
		time: 'Duration',
		selctTime:'Please select duration',
		effective:'Validity period',
		type: 'Type',
		status: 'Status',
		order:'order',
		selectStatus:'Please select the status',
		target1: 'Level 1',
		targetSize1: 'Number of completed 1',
		target2: 'Level 2',
		targetSize2: 'Number of completed 2',
		target3: 'Level 3',
		targetSize3: 'Number of completed 3',
		realNumber:'Real participants',
		option: 'option',
		cover: 'Cover image',
		target: 'Level',
		targetTwo: 'Level 2',
		targetThree: 'Level 3',
		reachSize: 'Robot setup',
		waterFlooding:'Robot parameters',
		explain: 'introduction',
		end:'Ended',
		onShelf:'Show up',
		offShelf:'Hidden',
		all:'All'
	  },
	  badges:'Pictures of badges of level',
	  badgesW:'108',
	  badgesH:'138',
	  targinType:'Please select the type of challenge',
	  badgeImgRequire:'Please select target badge',
	  coverSize: 'Recommended image size',
	  targinPlaceholder: 'Please fill in the target value',
	  targinErrOne: 'target value 2 > target value 1',
	  targinErrTwo: 'target value 3 > target value 2',
	  titleRequire: 'Please enter the name of the event',
	  bgImgRequire: 'Please upload a cover image',
	  actTimeRequire: 'Please select the event time',
	  decRequire: 'Please fill in the relevant introduction',
	  coefficient: 'Please enter the expansion factor',
	  startplaceholder: 'start date',
	  endplaceholder: 'end date',
	  act: '活动',
	  edit: 'edit',
	  creat: 'create',
	  view: 'check',
	  delete:'delete',
	  creatTitle:'Create Challenge Event Management',
	  editTitle:'Edit Challenge Event Management',
	  viewTitle:'Check Challenge Event Management',
	  actTypes: {
	  		1: 'Distance', 2: 'Duration', 3: 'Days', 4: 'Rides', 5: 'Calories'
	  },
	  tips: {
	  		one: '1、Optional configurations',
	  		two: '2、Algorithm: Number of participants displayed on the front-end = Number of real participants * Watering factor on the back-end'
	  },
	  unit: { 1: 'km',2: 'min',3: 'days',4: 'rides',5: 'kcal'},
	  hint: {
	  		name: '不允许超过11个汉字。',
	  		time: 'It is recommended to use weekly/monthly/quarterly as the challenge time period',
	  		explain: 'Limited number of characters for input content: (500 characters)'
	  }
	},
};
