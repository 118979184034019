const faqmaneger = {
  description: '内容描述',
  regEmail: '邮箱',
  unprocessed: '未处理',
  enterUserName: '请输入用户昵称',
  enterUserPhone: '请输入用户手机号',
  state: '状态',
  noRecords: '暂无记录',
  sureDealWithProblem: '确定该问题已经处理了吗？',
  feedBackTime: '反馈时间',
  source: "来源",
  versionNumber: '版本号',
  feedbackType: '反馈类型',
  feedback: '反馈',
  influenceNum: '影响人数',
  replyNum: '回复数',
  feedbackDetails: '反馈详情',
  replay: '回复',
  deleteConfirmMsg: '确定删除此条回复吗?',
  phone: '手机',
  screen: '屏幕',
  suggest: '建议',
  problem: '问题',
  replaySuccessMsg: '回复成功',
  editSuccessMsg: '修改成功',
  deleteSuccessMsg: '删除成功',
  successOperation: '操作成功',
  limitText: '限制',
  character: '字符',
  deletedFeedbackText: '已被删除的反馈',
  time: '时间',
  nickName: '昵称',
  replayEmptyMag: '回复不能为空',
  dealWith: '处理',
  processed: '已处理',
  feedBackContent: '反馈内容描述',
  registerEmail: '注册邮箱',
  contactEmail: '联系邮箱'
}
export default faqmaneger
