import Layout from '@/layout'
const Usermanager = [
	{
		path: '/usermanager',
		name: 'USER_MANAGER',
		component: Layout,
		redirect: '/userlayout/list',
		// USER_LAYOUT
		meta: {
			title: 'userManeger',
			icon: 'user'
		},
		children: [
			{
				path: '/userlayout',
				name: 'USER_LAYOUT',
				hidden: true,
				component: () => import('@/views/usermanager/index'),
				redirect: '/userlayout/list'
			},
			{
				path: '/userlayout/list',
				name: 'USER_MANAGER_LIST',
				hidden: true,
				component: () =>
					import('@/views/usermanager/list/index'),
				meta: {
					title: 'userlist',
					icon: 'user'
				}
			}, {
				path: '/userlayout/detail',
				name: 'USER_MANAGER_DETAIL',
				hidden: true,
				component: () =>
					import('@/views/usermanager/userdetails/index'),
				meta: {
					title: 'userdetail',
					icon: 'user'
				}
			}]
	}
]

export default Usermanager
