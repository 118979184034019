const bbsqianlongModel = {
  bbsqianlong: {
    sort: '排序',
    pass: '审核通过',
    reject: '审核拒绝',
    delete: '删除',
    creat: '创建',
    searchData: [
      { label: "昵称", prop: "nick_name", placeholder: '请输入昵称' },
      { label: "手机号", prop: "mobile", placeholder: '请输入手机号' },
      { label: "祈愿", prop: "content", placeholder: '请输入祈愿' },
      { label: "目标", prop: "user_target_name", placeholder: '请输入目标' },
      { label: "发表时间", prop: "time_range", type: "dateRangePicker" },
      {
        label: "状态",
        prop: "status",
        type: "select",
        option: [
          { label: "全部", value: '' },
          { label: "待审核", value: '0' },
          { label: "审核通过", value: '1' },
          { label: "审核拒绝", value: '-1' }
        ]
      }
    ],
    tableColumn: [
      { label: "昵称", prop: "nickName", type: "normal" },
      { label: "手机号", prop: "mobile", type: "normal" },
      { label: "祈愿", prop: "content", type: "normal" },
      { label: "目标", prop: "userTargetName", type: "normal" },
      { label: "发表时间", prop: "createTime", type: "normal", sortable: true, order: 'descending' },
      { label: "状态", prop: "status", type: "normal" },
      {
        label: "操作",
        prop: "blackstatus",
        type: "button",
        width: "350px",
        button: [
          { label: "置顶", type: "primary", prop: "top", labelName: "已置顶", labelKey: "top", labelValue: true, limit: "limitTop", limitValue: true },
          { label: "查看", prop: "view", type: "success" },
          { label: "删除", prop: "delete", type: "danger", limit: "verify", limitValue: true },
          { label: "已加入黑名单", type: "info", limit: "blacked", limitValue: true, labelName: "黑名单", labelKey: "enableblack", labelValue: true },
        ],
        method: ["_top", "_view", "_delete", "_black"]
      }
    ],
  },
  bbsqianlonglist: {
    nickName: "昵称",
    userId: "用户ID",
    mobile: "手机号",
    createTime: "发表时间",
    content: "祈愿",
    userTargetName: "目标"
  },
  bbsqianlongtop: {
    topSuccess: "置顶成功",
    cancelTopSuccess: "取消置顶成功"
  },
  bbsqianlongverify: {
    verifySuccess: "已审核通过",
    cancelVerifySuccess: "已审核拒绝",
  },
  bbsqianlongdelete: {
    deleteSuccess: "删除成功",
    deleteCancel: "已取消删除",
  },
  bbsqianlongsort: {
    homesort: "排序",
    nickname: "昵称",
    bbsqianlong: "祈愿",
  },
  bbsqianlongcreat: {
    telephone: "手机号",
    bbsqianlong: "祈愿",
    obj: "目标",
    status: '状态',
    inputtelephone: "请输入手机号",
    inputbbsqianlong: "请输入祈愿",
    inputobj: "请输入目标",
    verify: "审核通过",
    refuse: "审核拒绝",
    ok: '确定',
    cancel: '取消',
    success: '祈愿创建成功',
  },
  bbsqianlongblack: {
    title: '加入黑名单',
    nick: "昵称",
    userId: "用户ID",
    telephone: "手机号",
    reason: "处罚原因",
    longtime: "处罚时长",
    inputplaceholder: "请输入处罚原因",
    reasonRequire: "处罚原因必填",
    longtimeRequire: "处罚时长必填",
    blackSuccess: "加入黑名单成功"
  }
}
export default bbsqianlongModel
