import Vue from "vue";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui lang
//本地语言配置
import enLocale from "./en";
import zhLocale from "./zh";
Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...elementZhLocale,
    ...zhLocale
  }
};
export function getLanguage() {
  return "zh";
  // return "en";
}
const i18n = new VueI18n({
  locale: getLanguage(),
  messages
});

export default i18n;
