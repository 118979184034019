import Layout from '@/layout'
//推送管理
const Pushmanagement = [
	{
		path: '/pushmaneger',
		name: 'PUSH_MANAGER',
		component: Layout,
		redirect: '/grouplist',
		meta: {
			title: 'pushmanager',
			icon: 'table'
		},
		children: [{
			path: '/grouplist',
			name: 'PUSH_MANAGER_GROUP_LIST',
			meta: {
				title: 'grouplist',
				icon: 'table'
			},
			component: () => import('@/views/pushmanager/grouplist/index'),
			redirect: '/grouplist/group'
		},
		{
			path: '/grouplist/group',
			name: 'PUSH_MANAGER_GROUP_LIST',
			meta: {
				title: 'grouplist',
				icon: 'table'
			},
			hidden: true,
			component: () => import('@/views/pushmanager/grouplist/group/index')
		},
		{
			path: '/grouplist/creatgroup',
			name: 'PUSH_MANAGER_CREAT_GROUP',
			hidden: true,
			component: () => import('@/views/pushmanager/grouplist/creatgroup/index'),
			meta: {
				title: 'creatGroup',
				icon: 'table'
			}
		},
		{
			path: '/grouplist/editgroup',
			name: 'PUSH_MANAGER_EDIT_GROUP',
			hidden: true,
			component: () => import('@/views/pushmanager/grouplist/editgroup/index'),
			meta: {
				title: 'editGroup',
				icon: 'table'
			}
		},
		{
			path: '/grouplist/detail',
			name: 'PUSH_MANAGER_GROUP_DETAIL',
			hidden: true,
			component: () => import('@/views/pushmanager/grouplist/detail/index'),
			meta: {
				title: 'groupDetail',
				icon: 'table'
			}
		},
		{
			path: '/pushlist',
			name: 'PUSH_MANAGER_PUSH_LIST',
			meta: {
				title: 'pushlist',
				icon: 'table'
			},
			component: () => import('@/views/pushmanager/pushlist/index'),
			redirect: '/pushlist/list',
			children: [

			]
		},
		{
			path: '/pushlist/list',
			name: 'PUSH_MANAGER_PUSH_LIST',
			meta: {
				title: 'pushlist',
				icon: 'table'
			},
			hidden: true,
			component: () => import('@/views/pushmanager/pushlist/list/index')
		},
		{
			path: '/pushlist/created',
			name: 'PUSH_CREAT',
			hidden: true,
			component: () => import('@/views/pushmanager/pushlist/createpush/index'),
			meta: {
				title: 'pushcreated',
				icon: 'table'
			}
		},
		{
			path: '/pushlist/detail',
			name: 'PUSH_DETAIL',
			hidden: true,
			component: () => import('@/views/pushmanager/pushlist/detail/index'),
			meta: {
				title: 'seeThePush',
				icon: 'table'
			}
		},
		{
			path: '/pushdatalist',
			name: 'PUSH_MANAGER_PUSH_Data',
			meta: {
				title: 'pushdatalist',
				icon: 'table'
			},
			component: () => import('@/views/pushmanager/pushdatalist/index')
		}

		]
	},
]

export default Pushmanagement
