import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import permission from './modules/permission'
import theme from "./modules/theme"
import riding from "./modules/riding"
import record from "./modules/record"
import common from "./modules/common"
import editor from './modules/editor'
import loading from './modules/loading'
Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		app,
		settings,
		user,
		permission,
		theme,
		riding,
		record,
		common,
		editor,
		loading
	},
	getters
})

export default store
