import request from '@/utils/request'

export function getRidingList(params){ 
    return request({
      url:'/courses/real_riding',
      method: 'get',
      params
    })
  } 

export function postRidingList(data){ 
    return request({
      url:'/courses/real_riding',
      method: 'post',
      data
    })
  } 

export function actionRidingList(data,shelf){ 
    return request({
      url:`/courses/real_riding/shelves/${shelf}`,
      method: 'patch',
      data
    })  
  } 

export function putRidingList(data,id){ 
    return request({
      url:`/courses/real_riding/${id}`,
      method:'put',
      data
    })  
  } 

  export function sortRidingList(data,id){ 
    return request({
      url:`/courses/real_riding/sort`,
      method:'patch',
      data
    })  
  }
  
  export function getConfigCourseList(params){ 
    return request({
      url:`/courses/real_riding/courses`,
      method: 'get',
      params
    })
  }

  export function  ridingCourseAction(data,shelf){ 
    return request({
      url:`/courses/real_riding/courses/shelves/${shelf}`,
      method: 'patch',
      data
    })
  }  


  export function sortRidingCourse(data){ 
    return request({
      url:`/courses/real_riding/courses/sort`,
      method:'patch',
      data
    })  
  }


  export function addRidingCourse(data,id){ 
    return request({
      url:`/courses/real_riding/courses/add/${id}`,
      method:'patch',
      data
    })  
  }

  export function sortCourse(data){
    return request({
      url:`/courses/real_riding/courses/sort`,
      method:'patch',
      data
    })  
  }




  

  
  



