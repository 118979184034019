const programList = {
  addPastCourse: '添加往期课',
  nearlySevenDays: '近七天',
  nextSevenDays: '后七天',
  titlePromptTIPS: 'TIPS: 添加直播课到节目单，请前往【直播课】',
  addCourseToProgram: '添加往期课到节目单',
  pastCourseId: '往期课ID',
  courseIdPlaceholder: '请输入往期课ID，必须为上架状态',
  showTime: '播出时间',
  chooseTimePlaceholder: '选择日期时间',
  showTimeTIPS: 'Tips: 播出时间必须晚于当前时间',
  offShelfConfirmText_1: '下架课程后，节目表中此节课将不会展示给用户',
  offShelfConfirmText_2: '确认从节目表中下架吗？',
  courseIdMsg: '课程ID不能为空',
  showTimeMsg: '播出时间不能为空',
  pastCourse: '往期课',
  liveBroadcast: '直播',
  streamTimeFail: '添加失败，请检查播出时间',
  weekObj: {
    sunday: '周日',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六'
  }
}
export default programList
