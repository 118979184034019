import axios from 'axios'
import { MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { logout } from '@/api/user'
import router from '@/router'
import { ElMessage } from '@/utils/handle-msg'
import i18n from '@/lang'
import Cookies from 'js-cookie'
const elMessage = new ElMessage()
let loadingInstance
let loadCount = 0
function showMessage () {
  let visibleM = true
  return function (m, t) {
    if (visibleM) {
      visibleM = false
      Message({
        message: m,
        type: t,
        duration: 5 * 1000
      })
      setTimeout(() => {
        visibleM = true
      }, 3000)
    }
  }
}

function getLanguage() {
  // const chooseLanguage = Cookies.get('language')
  // if (chooseLanguage) return chooseLanguage

  // // if has not choose language
  // const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  // const locales = Object.keys(i18n.messages)

  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return  locale
  //   }
  // }
  // 语种选择
  return 'en'
  // return 'zh'
}

const getMessage = showMessage()
// create an axios instance
const baseURL = localStorage.getItem('apihosten') ? localStorage.getItem('apihosten') : process.env.VUE_APP_BASE_API
const service = axios.create({
  baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})
console.log('baseURL===============',baseURL)
service.defaults.headers = {
  'Content-Type': 'application/json;text/plain;charset=utf-8'
}
// request interceptor
service.interceptors.request.use(
  config => {
    if (loadCount == 0) {
      loadingInstance = Loading.service({
        fullscreen: true,
        background: 'rgba(0,0,0,0.5)',
        text: i18n.t('promptObj.loadingTips')
      })
    }
    loadCount++
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'JWT' + ' ' + getToken()
      // config.headers['Content-Type'] = 'application/json;charset=UTF8'
      //语言配置
     config.headers['lang'] = getLanguage()
    }
    config.url = encodeURI(config.url)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
  * If you want to get http information such as headers or status
  * Please return response => response
  */

  /**
* Determine the request status by custom code
* Here is just an example
* You can also judge the status by HTTP Status Code
*/
  async response => {
    setTimeout(() => {
      loadCount--; if (loadCount == 0) {
        loadingInstance.close()
      }
    }, 10)
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200) {
      /*  Message({
         message: res.msg || 'Error',
         type: 'error',
         duration: 5 * 1000
       }) */
      elMessage.error(res.msg)

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      const token = getToken()
      if (res.code === 'fail' || res.code == 'auth.authentication_failed') {
        /*   Message({
            message: res.msg || 'Error',
            type: 'error',
            duration: 5 * 1000
          }) */
        // elMessage.error(res.msg)
        // getMessage(res.msg,"error")
      }
      if (res.code === 'auth.authentication_failed') {    //用户令牌token无效
        if (token) {
          const data = await store.dispatch('user/logout') //请求失败先跳转到登录页，这个逻辑还需要根据后端返回的msg做细化
          store.dispatch('permission/clearMenus') //清除菜单权限
          router.push({ path: "/login" }) //
        }
      }
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    loadingInstance.close()
    /*   Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })  */
    // getMessage(error.message,"error")
    if (error.response.status === 400) {
      elMessage.error(i18n.t('promptObj.err400msg'))
    } else {
      elMessage.error(error.message)
    }
    return Promise.reject(error)
  }
)

export default service
