import Layout from '@/layout'
import Record from "@/router/modules/record"

const Coursemanagement = [
  {
    path: '/livecourses',
    name: 'LIVING_HOUSE',
    component: Layout,
    redirect: '/course/list',
    meta: {
      title: 'livecourses',
      icon: 'table'
    },
    children: [
      {
        path: '/course',
        name: 'COURSE_LAYOUT',
        hidden: true,
        meta: {
          title: 'livecourses',
          icon: 'table'
        },
        component: () => import('@/views/livecourses/index'),
        redirect: '/course/list'
      }, 
      {
        path: '/course/list',
        name: 'LIVE_COURSES_LIST',
        component: () =>
        import('@/views/livecourses/list/index'),
        meta: {
          title: 'livecourselist',
          icon: 'table'
        }
      },
      {
        path: '/course/recyclielist',
        name: 'RECYCLING_WAREHOUSE',
        hidden: true,
        component: () =>
        import('@/views/livecourses/recyclingwarehouse/index'),
        meta: {
          title: 'recyclielist',
          icon: 'table'
        }
      },
      {
        path: '/course/creat',
        name: 'LIVE_COURSES_CREAT',
        hidden: true,
        component: () =>
        import('@/views/livecourses/createcourse/index'),
        meta: {
          title: 'createcourse',
          icon: 'table'
        }
      },
      {
        path: '/course/edit',
        name: 'LIVE_COURSES_EDIT',
        hidden: true,
        component: () =>
        import('@/views/livecourses/editcourse/index'),
        meta: {
          title: 'editcourse',
          icon: 'table'
        }
      },
      {
        path: '/course/viewcourse',
        name: 'LIVE_COURSES_DETAIL',
        hidden: true,
        component: () =>
        import('@/views/livecourses/coursedetails/index'),
        meta: {
          title: 'viewCourse',
          icon: 'user'
        }
      },
      // 实景课程
      {
        path: '/reviewed/realityCourse',
        name: 'REALITY_COURSES_LIST',
        component: () =>
        import('@/views/reviewedcourses/list/index'),
        meta: {
          title: 'realityList',
          icon: 'table'
        }
      },
      //往期课程
      {
        path: '/reviewed/reviewedlist',
        name: 'REVIEWED_COURSES_LIST',
        component: () =>
        import('@/views/reviewedcourses/list/index'),
        meta: {
          title: 'reviewedlist',
          icon: 'table'
        }
      },
			//2.3.2新增 往期课字幕配置
			{
			  path: '/reviewed/subtitleConfig',
			  name: 'REVIEWED_SUBTITLECONFIG',
			  component: () => import('@/views/reviewedcourses/subtitleConfig/index'),
			  hidden: true,
			  meta: {
			    title: 'subtitleConfig',
			    icon: 'table'
			  }
			},
      //删除课程列表
      {
        path: '/reviewed/recyclielist',
        hidden: true,
        name: 'RECYCLING_WAREHOUSE',
        component: () =>
        import('@/views/reviewedcourses/recyclingwarehouse/index'),
        meta: {
          title: 'recyclielist',
          icon: 'table'
        }
      },
      {
        path: '/reviewed/edit',
        name: 'REVIEWED_COURSES_EDIT',
        hidden: true,
        component: () =>
        import('@/views/reviewedcourses/editcourse/index'),
        meta: {
          title: 'editreviewedcourse',
          icon: 'table'
        }
      },
      {
        path: '/reviewed/creat',
        name: 'REVIEWED_COURSES_CREAT',
        hidden: true,
        component: () =>
        import('@/views/reviewedcourses/createcourse/index'),
        meta: {
          title: 'creatreviewedcourse',
          icon: 'table'
        }
      },
      {
        path: '/reviewed/viewcourse',
        name: 'REVIEWED_COURSES_DETAIL',
        hidden: true,
        component: () =>
        import('@/views/reviewedcourses/coursedetails/index'),
        meta: {
          title: 'viewCourse',
          icon: 'user'
        }
      },
      // ...Record,//录播课程
      // ...Theme,//专题课程
      {
        path: '/program/list',
        name: 'PROGRAM_LIST',
        component: () =>
        import('@/views/programList/index'),
        meta: {
          title: 'programManagement',
          icon: 'user'
        }
      },
    ]
  }
]

export default Coursemanagement
