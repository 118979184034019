import Layout from '@/layout'
const Sncodemaneger = [
	//sn模块
	{
		path: '/sncodemaneger',
		name: 'SN_CODE_MANAGER',
		component: Layout,
		redirect: '/sncodelayout/list',
		meta: {
		  title: 'sncodemaneger',
		  icon: 'table'
		},
		children: [{
		  path: '/sncodelayout',
		  name: 'SN_CODE_MANAGER_LAYOUT',
		  meta: {
			title: 'sncodelist',
			icon: 'table'
		  },
		  hidden: true,
		  component: () => import('@/views/sncodemaneger/index'),
		  redirect: '/sncodelayout/list'
		}, {
		  path: '/sncodelayout/list',
		  name: 'SN_CODE_MANAGER_LIST',
		  component: () =>
			import('@/views/sncodemaneger/list/index'),
		  meta: {
			title: 'sncodelist',
			icon: 'table'
		  }
		},
		{
		  path: 'sncodelayout/detail',
		  name: 'SN_CODE_MANAGER_DETAIL',
		  component: () => import('@/views/sncodemaneger/sncodeentry/index'),
		  meta: {
			title: 'sncodeentry',
			icon: 'table'
		  }
		}
		]
	  },
]

export default Sncodemaneger
