import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/* Layout */
import Layout from "@/layout";
import Permission from "@/router/modules/permission";
import BaseConfig from "@/router/modules/baseConfig";
import Faqmaneger from "@/router/modules/faqmaneger";
// import Record from "@/router/modules/record";
import Riding from "@/router/modules/riding";
import Usermanagement from "@/router/modules/usermanagement";
import Activitymanager from "@/router/modules/activitymanager";
// import Tagmanager from "@/router/modules/tagmanager";
// import TeachingCourse from '@/router/modules/teachingCourse';
import Bannermanagement from "@/router/modules/bannermanagement";
import Coursemanagement from "@/router/modules/coursemanagement";
import {PaidInterestManagement, ExchangeCode} from "@/router/modules/paidInterestManagement"; //2.2.7新增付费权益配置管理
import UserTagManagement from "@/router/modules/usertagManagement"; //2.2.7新增TAG列表
import UpdatePush from "@/router/modules/updatePush"; //2.2.7新增更新推送
import EmotionalCopywriting from "@/router/modules/emotionalCopywriting"; //2.2.7新增情感化文案
import CourseLabel from "@/router/modules/courseLabel"; //2.2.8新增课程标签
import Oldcourse from "@/router/modules/oldcourse";
import Pushmanagement from "@/router/modules/pushmanagement";
// import Exchangecodemaneger from "@/router/modules/exchangecodemaneger";
import Sncodemaneger from "@/router/modules/sncodemaneger";
//import MemberManagement from "@/router/modules/memberManagement.js"; //2.2.4新增 会员商品管理  (延期开发)
// import EmailSend from "@/router/modules/email.js"//邮箱模板2.2.6新增 用于运营发送谷歌邮箱
// import DragMailbox from "@/router/modules/dragMailbox.js"//邮箱模板2.2.6新增 用于运营发送谷歌邮箱
import Coachmanager from "@/router/modules/coachmanager";
// import Datavisualization from "@/router/modules/visualization"
// import Sharemaneger from '@/router/modules/sharemaneger'
import RecommendedCourse from "@/router/modules/RecommendedCourse";
import Theme from "@/router/modules/theme"; //2.3.3将计划由二级菜单变更为一级菜单
import PushNotice from "@/router/modules/pushNotice"; //2.3.3添加push通知
import UserDeviceManager from "@/router/modules/userDeviceManagement"; //用户设备绑定

export const constantRoutes = [
  {
    path: "/login",
    meta: {
      title: "login",
      icon: "table"
    },
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true
  },
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/index"),
        meta: {
          title: "dashboard",
          icon: "dashboard"
        }
      },
      {
        path: "putPassword",
        name: "PUT_PASSWORD",
        hidden: true,
        component: () => import("@/views/putPassword/index"),
        meta: {
          title: "putPassword",
          icon: "dashboard"
        }
      }
    ]
  },
  // {
  //   path: '/reviewedcourses',
  //   name: 'REVIEWED_COURSE',
  //   component: Layout,
  //   redirect: '/reviewed/list',
  //   meta: {
  //     title: 'reviewedCourses',
  //     icon: 'table'
  //   },
  //   children: [{
  //     path: '/reviewed',
  //     name: 'COURSE_LAYOUT',
  //     hidden: true,
  //     meta: {
  //       title: 'reviewedCourses',
  //       icon: 'table'
  //     },
  //     component: () => import('@/views/reviewedcourses/index'),
  //     redirect: '/reviewed/list'
  //   }, {
  //     path: '/reviewed/list',
  //     name: 'REVIEWED_COURSES_LIST',
  //     component: () =>
  //       import('@/views/reviewedcourses/list/index'),
  //     meta: {
  //       title: 'reviewedlist',
  //       icon: 'table'
  //     }
  //   },
  //   {
  //     path: '/reviewed/recyclielist',
  //     name: 'RECYCLING_WAREHOUSE',
  //     component: () =>
  //       import('@/views/reviewedcourses/recyclingwarehouse/index'),
  //     meta: {
  //       title: 'recyclielist',
  //       icon: 'table'
  //     }
  //   },
  //   {
  //     path: '/reviewed/edit',
  //     name: 'REVIEWED_COURSES_EDIT',
  //     hidden: true,
  //     component: () =>
  //       import('@/views/reviewedcourses/editcourse/index'),
  //     meta: {
  //       title: 'editreviewedcourse',
  //       icon: 'table'
  //     }
  //   },
  //   {
  //     path: '/reviewed/viewcourse',
  //     name: 'REVIEWED_COURSES_DETAIL',
  //     hidden: true,
  //     component: () =>
  //       import('@/views/reviewedcourses/coursedetails/index'),
  //     meta: {
  //       title: 'viewCourse',
  //       icon: 'user'
  //     }
  //   }
  //   ]
  // },
  ...Usermanagement, //用户管理模块路由
  ...UpdatePush,// 2.2.7新增更新推送
  // ...Tagmanager, //标签管理模块路由
  // ...TeachingCourse, //教学课程
  ...Coursemanagement, //直播库模块路由
  ...Theme,//2.3.3将计划由二级菜单变更为一级菜单
  ...PushNotice,
  ...CourseLabel,//2.2.8新增课程标签
  ...PaidInterestManagement,//付费权益配置管理 2.2.7新增
  ...ExchangeCode, //兑换码
  ...UserTagManagement,//TAG列表  2.2.7新增
  ...EmotionalCopywriting,//情感化文案 2.2.7新增
  ...Bannermanagement, //banner模块路由
  ...Coachmanager, //教练模块路由
  ...Pushmanagement, //推送模块路由
  // ...Exchangecodemaneger, //兑换码管理路由
  ...Sncodemaneger, //SN码模块路由
  // ...MemberManagement,// 2.2.4新增会员商品管理 (延期开发)
  // ...EmailSend,// 邮箱模板2.2.6新增 用于运营发送谷歌邮箱
  // ...DragMailbox,//动态拖拽
  // ...Oldcourse,//旧的课程路由
  ...Riding, //实景骑行
  // ...Record, //录播库
  ...BaseConfig, //基础配置路由
  // ...Theme, //专题包路由
  ...RecommendedCourse,
  ...Faqmaneger, //问题反馈
  // ...Sharemaneger, //分享管理
  ...Activitymanager, //活动管理路由
  ...Permission, //权限管理路由
  // ...Datavisualization, //数据分析
  ...UserDeviceManager, //用户设备绑定
  // 404 page must be placed at the end !!!
  {
    path: "*",
    redirect: "/404",
    hidden: true
  }
];



export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  {
    path: "*",
    redirect: "/404",
    hidden: true
  }
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
