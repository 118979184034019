<template>
	<div class="navbar">
		<hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

		<breadcrumb class="breadcrumb-container" />

		<div class="right-menu">
			<div style="width: 200px;height: 100%;" @click="openPassword()"></div>
			<el-dropdown class="avatar-container" trigger="click">
				<div class="avatar-wrapper">
					{{ $t('navbar.user') }}:{{ name }}
					<i class="el-icon-caret-bottom" />
				</div>
				<el-dropdown-menu slot="dropdown" class="user-dropdown">
					<router-link to="/">
						<el-dropdown-item>
							{{ $t('navbar.dashboard') }}
						</el-dropdown-item>
					</router-link>
					<el-dropdown-item divided>
						<span style="display:block;" @click="logout">{{ $t('navbar.logOut') }}</span>
					</el-dropdown-item>
					<router-link to="/putPassword">
						<el-dropdown-item>
							{{ $t('navbar.putPassword')}}
						</el-dropdown-item>
					</router-link>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import Breadcrumb from '@/components/Breadcrumb'
	import {
		Hamburger
	} from 'yesoul-ui'

	import Cookies from 'js-cookie'
	export default {
		components: {
			Breadcrumb,
			Hamburger
		},
		computed: {
			...mapGetters([
				'sidebar',
				'avatar',
				'name'
			])
		},
		data() {
			return {
				locationLang: '',
				langOptions: [],
				count: 0, //计数
			}
		},
		created() {
			this.locationLang = Cookies.get('language') || 'en'
			this.langOptions = this.$t('langOptions')
		},
		methods: {
			//打开密码
			openPassword() {
				this.count++
				if (this.count > 5) {
					this.count = 0
					this.$prompt('请输入管理密码', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						closeOnClickModal: false
					}).then(({
						value
					}) => {
						if (value == 1124) {
							this.switchLanguage()
						} else {
							this.$message({
								type: 'error',
								message: '密码错误'
							})
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '取消输入'
						})
					})
				}
			},
			//切换语言
			switchLanguage() {
				let lang = this.$i18n.locale
				let langText = ''
				if (lang == 'en') {
					langText = '中文'
				} else {
					langText = '英文'
				}
				this.$confirm(`是否要将语言环境切换为${langText}?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					if (lang === 'en') {
						this.$i18n.locale = 'zh'
					} else {
						this.$i18n.locale = 'en'
					}
					this.$message({
						type: 'success',
						message: '切换成功'
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					})
				})
			},
			chooseLanguage(locale) {
				this.$i18n.locale = locale
				Cookies.set('language', locale)
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				setTimeout(() => {
					loading.close();
				}, 1500);
			},
			toggleSideBar() {
				this.$store.dispatch('app/toggleSideBar')
			},
			async logout() {
				await this.$store.dispatch('user/logout');
				this.$store.dispatch('permission/clearMenus');
				this.$router.push(`/login?redirect=${this.$route.fullPath}`)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.navbar {
		height: 50px;
		overflow: hidden;
		position: relative;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

		.hamburger-container {
			line-height: 46px;
			height: 100%;
			float: left;
			cursor: pointer;
			transition: background .3s;
			-webkit-tap-highlight-color: transparent;

			&:hover {
				background: rgba(0, 0, 0, .025)
			}
		}

		.breadcrumb-container {
			float: left;
		}

		.right-menu {
			float: right;
			height: 100%;
			line-height: 50px;
			display: flex;

			&:focus {
				outline: none;
			}

			.right-menu-item {
				display: inline-block;
				padding: 0 8px;
				height: 100%;
				font-size: 18px;
				color: #5a5e66;
				vertical-align: text-bottom;

				&.hover-effect {
					cursor: pointer;
					transition: background .3s;

					&:hover {
						background: rgba(0, 0, 0, .025)
					}
				}
			}

			.avatar-container {
				margin-right: 30px;

				.avatar-wrapper {
					margin-top: 5px;
					position: relative;

					.user-avatar {
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 10px;
					}

					.el-icon-caret-bottom {
						cursor: pointer;
						position: absolute;
						right: -20px;
						top: 25px;
						font-size: 12px;
					}
				}
			}
		}
	}
</style>
