import { getCourseType,getConfigCourseList} from "@/api/theme";
import {
  getClassTypeList
 } from "@/api/classType";
import i18n from '@/lang'
const state = {
  courseType: [],
  sortThemeData:[],
  classTypeData:[]
};

const mutations = {
  SET_COURSE_TYPE: (state, courseType) => {
    state.courseType = courseType;
  },
  SET_SORT_DATA:(state,sortThemeData) => {
    state.sortThemeData=sortThemeData;
  },
  SET_CLASS_DATA:(state,classTypeData) => {
    state.classTypeData=classTypeData;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { user_name, password, timestamp } = userInfo;
    return new Promise((resolve, reject) => {
      login({
        user_name: user_name.trim(),
        password: password,
        timestamp: timestamp
      })
        .then(response => {
          const { data } = response;
          commit("SET_NAME", data.userName);
          commit("SET_TOKEN", data.token);
          setToken(data.token);
          setUserInfo(data.userName);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCourseType({ commit }) {
    if (state.courseType.length) {
      return new Promise(resolve => {
        resolve(state.courseType);
      });
    } else {
      return new Promise((resolve, reject) => {
        getCourseType().then(res => {
          if (res.code == "success") {
            commit("SET_COURSE_TYPE", res.data.items);
            resolve(res.data.items);
          } else {
            reject("出错了");
          }
        });
      });
    }
  },
  getSortThemeData({ commit },id){
    if (state.sortThemeData.length) {
      return new Promise(resolve => {
        resolve(state.sortThemeData);
      });
    } else {
      return new Promise((resolve, reject) => { 
       const params={
          $offset:0,
          $limit:10000//排序无限大不需要分页
        } 
        getConfigCourseList(params,id).then(res => {
          if (res.code == "success") {
            commit("SET_SORT_DATA", res.data.items);
            resolve(res.data.items);
          } else {
            reject("出错了");
          }
        });
      });
    }
  },
  getClassTypeData({ commit }){ 
    if (state.classTypeData.length) {
      return new Promise(resolve => {
        resolve(state.classTypeData);
      });
    } else {
      return new Promise((resolve, reject) => { 
       
        getClassTypeList().then(res=>{   
           console.log("获取课程大类列表",res);
           if(res.code=='success'){
            res.data.items.unshift({
              name: i18n.t('actionbuttons.allSelect'),
              id: ''
            })
            console.log(res.data)
            commit("SET_CLASS_DATA", res.data);
            resolve(res.data);
           }
          })
       
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
