import { getRidingList} from "@/api/riding"; 

const state = {
  sortRideData:[]
};

const mutations = {
  SET_COURSE_TYPE: (state, courseType) => {
    state.courseType = courseType;
  },
  SET_SORT_DATA:(state,sortRideData) => {
    state.sortRideData=sortRideData;
  }
};

const actions = {
  getSortRideData({ commit }){
      return new Promise((resolve, reject) => { 
       const params={
          $offset:0,
          $limit:10000,//排序无限大不需要分页
          $filter: `shelf eq 1`,
          $orderby:`sort desc`
        } 
        getRidingList(params).then(res => {
          if (res.code == "success") {
            commit("SET_SORT_DATA", res.data.items);
            resolve(res.data.items);
          } else {
            reject("出错了");
          }
        });
      });
    // }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
