import request from '@/utils/request'

export function getSearchCourse () {
  return request({
    url: '/courses/categories',
    method: 'get'
  })
}

export function getSearchLevel () {
  return request({
    url: '/courses/levels',
    method: 'get'
  })
}

export function deleteCourse (data, isDeleted) {
  return request({
    url: `/courses/${isDeleted}`,
    method: 'delete',
    data
  })
}

export function getTagList (params) {
  return request({
    url: '/course_tags',
    method: 'get',
    params
  })
}

export function postTagList (data, id) {
  return request({
    url: `/courses/tags/${id}`,
    method: 'post',
    data
  })
}

export function upDownCourse (data, shelf) {
  return request({
    url: `/courses/shelves/${shelf}`,
    method: 'patch',
    data
  })
}
//往期课程添加录播课 
export function addRecordCourseList (params) {
  const data = {
    'courseIds': params.courseIds,  //课程ids
    'courseKindType': params.courseKindType,       // 0=往期课 // 5 录播课
    distribId:params.distribId
  }
  return request({
    url: `/courses/change_courses`,
    method: 'post',
    data
  })
}






