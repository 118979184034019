import Layout from '@/layout'
const Oldcourse = [
	// 旧系统模块
	{
		path: '/coursemanagement',
		name: 'COURSE_MANAGER',
		component: Layout,
		redirect: '/courseyout',
		meta: {
			title: 'coursemanagement',
			icon: 'table'
		},
		children: [{
			path: '/typecourse',
			name: 'TYPEOFCOURSE',
			meta: {
				title: 'typeofcourse',
				icon: 'table'
			},
			component: () => import('@/views/oldsystemmodule/typeofcourse/index')
		},
		{
			path: '/courseyout',
			name: 'COURSE_YOUT',
			meta: {
				title: 'coursemanagementlist',
				icon: 'table'
			},
			component: () => import('@/views/oldsystemmodule/coursemanagement/index.vue'),
			redirect: '/coursemanagement/list',
			children: [{
				path: '/coursemanagement/list',
				name: 'COURSEMANAGEMENT',
				meta: {
					title: '',
					icon: 'table'
				},
				hidden: true,
				component: () => import('@/views/oldsystemmodule/coursemanagement/course/index'),
				children: []
			}, {
				path: '/coursemanagement/creatcourse',
				name: 'CREATOLDCOURSE',
				hidden: true,
				component: () => import('@/views/oldsystemmodule/coursemanagement/creatcourse/index'),
				meta: {
					title: 'creatOldCourse',
					icon: 'table'
				}
			}, {
				path: '/coursemanagement/phase',
				name: 'COURSEPHASE',
				hidden: true,
				component: () => import('@/views/oldsystemmodule/coursemanagement/phase/index'),
				meta: {
					title: 'coursePhase',
					icon: 'table'
				}
			}, {
				path: '/coursemanagement/editcourse',
				name: 'EDITCOURSE',
				hidden: true,
				component: () => import('@/views/oldsystemmodule/coursemanagement/editcourse/index'),
				meta: {
					title: 'editcourse',
					icon: 'table'
				}
			}]
		}

		]
	}
]

export default Oldcourse
