import Layout from '@/layout'
const UserTagManagement = [
	{
		path: '/userTagManagement',
		name: 'USER_TAG_MANAGEMENT',
		component: Layout,
		redirect: '/userTagManagement/list',
		meta: {
			title: 'userTagManagement',
			icon: 'table'
		},
		children: [{
			path: '/userTagManagement/list',
			name: 'USER_TAG_MANAGEMENT_LIST',
			hidden: true,
			meta: {
				title: 'userTagManagementList',
				icon: 'table'
			},
			component: () => import('@/views/userTagManagement/list')
		}]
	}
]

export default UserTagManagement