import Cookies from 'js-cookie'

const TokenKey = process.env.VUE_APP_TOKEN_KEY
const LoginKey = process.env.VUE_APP_LOGIN_KEY

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  return Cookies.get(LoginKey)
}

export function setUserInfo(user) {
  return Cookies.set(LoginKey, user)
}

export function removeUserInfo() {
  return Cookies.remove(LoginKey)
}
