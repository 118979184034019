const programList = {
  addPastCourse: 'Add past courses',
  nearlySevenDays: 'Last 7 days',
  nextSevenDays: 'Next 7 days',
  titlePromptTIPS: 'TIPS: Add a live class to Schedule. Please go to [Live Classes]',
  addCourseToProgram: 'Add a past class to Schedule',
  pastCourseId: 'Past class ID',
  courseIdPlaceholder: 'The past class must be on shelves',
  showTime: 'Stream time',
  chooseTimePlaceholder: 'select date',
  showTimeTIPS: 'Tips: The stream time must be later than the current time.',
  offShelfConfirmText_1: 'When the class has been removed, it is not available to the users in the Schedule.',
  offShelfConfirmText_2: 'Are you sure you want to remove it from Schedule?',
  courseIdMsg: 'Course ID can not be empty',
  showTimeMsg: 'Broadcast time can not be empty',
  pastCourse: 'Past workouts',
  liveBroadcast: 'live',
  streamTimeFail: 'Failed to add the class. Please check the stream time.',
  weekObj: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday'
  }
}
export default programList
