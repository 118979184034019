import { constantRoutes } from '@/router'
import {getRolesMenu} from "@/api/user"
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if( typeof roles=="string"){ 
   return false     
  }
  if (!route.hidden) {
    if (!roles) {
      return false
    } else {
      const has = roles.find(role => role.uri === route.name)
      return has
    }
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []
  routes.map(route => {
    const tmp = { ...route }
    const permisson = hasPermission(roles, tmp)
    //console.log("state",state)
    if(state.superAdmin){ 
      res.push(tmp)//超级管理员的时候不过滤 
    }
    else if(roles=='all'){ 
      res.push(tmp) //childern的权限后端没返回时默认把该级二级往后的路由全部展示
    }else if (permisson) {
      if (tmp.children) {
        const subroles = permisson.children.length ? permisson.children :'all'  //childern的权限后端没返回时默认把该级二级往后的路由全部展示 []
        tmp.children = filterAsyncRoutes(tmp.children, subroles)
      }
      tmp.role = true;
      res.push(tmp); 
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: [],
  navRoles:null,
  superAdmin:false
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    // state.routes = constantRoutes.concat(routes)
    state.routes = routes
  },
  SET_NAV_ROLES:(state,navRoles) => {
    state.navRoles = navRoles
  },
  SET_ADMIN:(state,admin) => {
    state.superAdmin = admin
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes
      if (roles.includes('admin')) {
        accessedRoutes = constantRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(constantRoutes, roles)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  getRolesMenu({ commit }) {
    if(state.navRoles){ 
      return new Promise((resolve)=>{ 
         resolve(state.navRoles)
      })
    }
    else{return new Promise(resolve => {
      const params={}
      getRolesMenu(params).then(res=>{
        console.log('获取权限',res)
        if(res.data){
        commit('SET_NAV_ROLES', res.data.items)
        commit('SET_ADMIN',!res.data.end)
        resolve(res.data.items)
        }else{ 
           
          commit('SET_ADMIN',true)//接口没返回数据，默认全部路由展示
          resolve([])  
        }
       })
     
    })
  }},
  clearMenus({ commit }) { 
    commit('SET_NAV_ROLES',null)
    commit('SET_ADMIN',false)
  }

}

const data = {
  'items': [{
    'uri': '', // 资源定位符, 唯一
    'name': '', // 资源名
    'children': [{ // 子资源
      'uri': '',
      'name': '',
      'children': [{

      }],
      'sort': 211
    }],
    'sort': 22 // 排序, 从小到大
  }],
  'end': '' // 是否是超级管理员  true 不是, false 是
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
