import Vue from 'vue'
import { parseTime } from '@/utils/index.js'
import i18n from '@/lang'
Vue.filter('NumberFormat', function(value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('parseTime', parseTime)
Vue.filter('sexFilter', (sex) => {
  if (!i18n.t('sexMap')[sex]) {
    return '--'
  }
  return i18n.t('sexMap')[sex]
})
Vue.filter('vipTypeMap', (vip) => {
  if (!i18n.t('userlist.vipTypeMap')[vip]) {
    return '--'
  }
  return i18n.t('userlist.vipTypeMap')[vip]
})
Vue.filter('UpOrDown', (status) => {
  if (!i18n.t('importanceStatusMap')[status]) {
    return ''
  }
  return i18n.t('importanceStatusMap')[status]
})

// stickMethod
Vue.filter('stickMethod', (status) => {
  if (!i18n.t('stickStatusMap')[status]) {
    return ''
  }
  return i18n.t('stickStatusMap')[status]
})
// banner type
Vue.filter('bannerType', (status) => {
  if (!i18n.t('bannerTypeMap')[status]) {
    return ''
  }
  return i18n.t('bannerTypeMap')[status]
})
