import Layout from '@/layout'
const Riding = [{
	path: '/riding',
	name: 'RIDING_SITUATION',
	component: Layout,
	meta: {
		title: 'riding',
		icon: 'user'
	},
	redirect: '/riding/list',
	children: [{
			path: 'list',
			name: 'RIDING_LIST',
			meta: {
				title: 'riding',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/riding'),
		},
		{
			path: 'ridingConfig/:id',
			name: 'RIDING_CONFIG',
			hidden: true,
			meta: {
				title: 'courseConfig',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/riding/ridingConfig'),
		},
		{
			path: 'addCourseListRecord/:id',
			name: 'RIDING_COURSE_RECORD',
			hidden: true,
			meta: {
				title: 'courseConfig',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/riding/addCourseListRecord'),
		},
		{
			path: 'addCourseListUsed/:id',
			name: 'RIDING_COURSE_USED',
			hidden: true,
			meta: {
				title: 'courseConfig',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/riding/addCourseListUsed'),
		},
		{
			path: 'create',
			name: 'RIDING_CREATE_COURSE',
			hidden: true,
			meta: {
				title: 'createcourse',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/riding/creatCourse/index'),
		},
		{
			path: 'edit',
			name: 'RIDING_EDIT_COURSE',
			hidden: true,
			meta: {
				title: 'editcourseRiding',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/riding/editRidingConfig/index'),
		},
		{
			path: 'check',
			name: 'RIDING_COURSE_DETAIL',
			hidden: true,
			meta: {
				title: 'viewCourse',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/riding/coursedetails/index'),
		}
	]
}]

export default Riding
