import request from "@/utils/request";

//计划位置设置详情
export function getLocationDetail(data) {
	return request({
		url: `/course_groups/getLocationDetail`,
		method: "post",
		data
	})
}

//添加计划位置设置
export function setLocationDetail(data) {
	return request({
		url: `/course_groups/setLocation`,
		method: "post",
		data
	})
}

export function getThemeList(params) {
	return request({
		url: "/course_groups/list",
		method: "get",
		params
	});
}
export function postThemeList(data) {
	return request({
		url: "/course_groups",
		method: "post",
		data
	});
}

export function getThemeItem(id) {
	return request({
		url: `/course_groups/${id}`,
		method: "get"
	});
}

export function deleteThemeList(data) {
	return request({
		url: `/course_groups/${data.id}`,
		method: "delete",
		data
	});
}

export function putThemeList(data) {
	return request({
		url: `/course_groups`,
		method: "patch",
		data
	});
}
export function upDownPackage(data, shelf) {
	return request({
		url: `/course_groups/shelves/${shelf}`,
		method: "post",
		data
	});
}

export function getCourseType(params) {
	//获取课程大类接口
	return request({
		url: "/courses/categories",
		method: "get",
		params
	});
}

/*专题包下课程*/
export function getConfigCourseList(params, id) {
	return request({
		url: `/course_groups/${id}/courses/list`,
		method: "get",
		params
	});
}

export function addThemeCourseList(data, id) {
	//专题包添加课程
	return request({
		url: `/course_groups/addCourses/${id}`,
		method: "post",
		data
	});
}

export function deleteThemeCourseList(data, id) {
	//专题包删除课程
	return request({
		url: `/course_groups/deleteCourses/${id}`,
		method: "post",
		data
	});
}

export function getAllCourseList(params) {
	return request({
		url: `/courses`,
		method: "get",
		params
	});
}

export function distributionLibrary(params) {
	return request({
		url: `/courses/stat`,
		method: "get",
		params
	});
}

export function sortCourse(data) {
	//专题包排序
	return request({
		url: `/course_groups/courses/order`,
		method: "post",
		data
	});
}

// 获取分发语言区 [GET] /v0.1/languages
export function getLanguages(data) {
	return request({
		url: "/languages",
		method: "get",
		params: data
	});
}
