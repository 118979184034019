const riding = {
  selected: '已选',
  ridingCategories: '跑步',
  name: '名称',
  setCourse: '配置课程',
  fewCourse: '门课程',
  addCourses: '添加课程',
  formRecordChoose: '从录播课中选择',
  formPreviousChoose: '从往期课中选择',
  sort: '排序',
  courseSort: '课程排序',
  shelf2: '未上架',
  shelf1: '上架',
  shelf0: '下架',
  shelf: '无状态',
  freeTrial: '限时免费',
  specialCourses: '专题课程',
  abdominalWheel: '健腹轮',
  name1: '名称',
  cover: '封面',
  creatTime: '创建时间',
  pleaseInput: '请输入',
  inputUserName: '请输入用户名',
  creat: '创建',
  creatRidingCourse: '创建课程',
  courseName: '课程名称:',
  status: '状态:',
  duration: '时长:',
  creatCourse: '创建课程',
  distributionLanguages: "分发语种",
  superClearAddress: '超清地址',
  HD_address: '高清地址',
  SD_address: '标清地址',
  smoothAddress: '流畅地址',
  disLanguageMsg: '请选择分发的语种',
  free: '限免',
  recordCourseImage: '录播课图片',
  superClearAddressPlaceholder: '超清视频地址',
  HD_addressPlaceholder: '高清视频地址',
  SD_addressPlaceholder: '标情视频地址',
  smoothAddressPlaceholder: '流畅视频地址',
  tableData: [
    {className: "健身"},
    {className: "瑜伽"},
    {className: "单车"},
    {className: "跑步"}
  ],
  tableColumn: [
    {label: "id", prop: "id", type: "normal"},
    {label: "课程大类", prop: "categoryName", type: "normal"},
    {label: "课程名称", prop: "courseName", type: "normal"},
    {label: "课程难度", prop: "courseLevel", type: "normal"},
    {label: "时长", prop: "courseDuration", type: "normal"},
    {label: "创建时间", prop: "createTime", type: "normal"}
  ],
  searchData: [
    {
      label: "难度",
      prop: "level",
      type: "select",
      option: [
        {label: "全部", value: ""},
        {label: "初级", value: 1},
        {label: "中级", value: 2},
        {label: "高级", value: 3}
      ]
    },
    {
      label: "状态",
      prop: "shelf",
      type: "select",
      option: [
        {label: "全部", value: ""},
        {label: "未上架", value: 2},
        {label: "上架", value: 1},
        {label: "下架", value: 0}
      ]
    },
    {
      label: "收费方式",
      prop: "group_type",
      type: "select",
      option: [
        {label: "全部", value: ""},
        {label: "vip课程", value: 1},
        {label: "免费课程", value: 2}
      ]
    },
    {label: "id", prop: "id"},
    {label: "专题课名称", prop: "group_name"},
    {
      label: "分发区",
      prop: "distrib_typeList",
      type: "select",
      option: [
        {label: "全部", value: ""},
        {label: "限时免费", value: 1},
        {label: "专题课程", value: 2},
        {label: "健腹轮", value: 3}
      ]
    }
  ],
  tableColumn_2: [
    {label: "课程名称", prop: "name", type: "normal"},
    {label: "状态", prop: "status", type: "normal"},
	{label: "收费方式", prop: "courseType", type: "normal"},
    {label: "分发语种", prop: "languageAreas", type: "tag"},
    {label: "时长", prop: "duration", type: "normal"},
    {
      label: "操作",
      prop: "action",
      type: "button",
      button: [
        {label: "查看", type: "primary"},
		{label: "编辑", type: "primary"},
      ],
      method: ["_check","_edit"]
    }
  ],
  message: {
    selectAddData: '请先选中添加数据',
    inputUserName: '请输入用户名',
    cantelDelete: '已取消删除'
  },
  confirm: {
    title: '提示',
    deleteAllCourses: '确定删除所选课程?'
  }
}
export default riding
