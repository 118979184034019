var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popconfirm",
    {
      attrs: {
        title: "Are you sure delete this task?",
        visible: _vm.visible,
        okText: "Yes",
        cancelText: "No",
      },
      on: {
        visibleChange: _vm.handleVisibleChange,
        confirm: _vm.confirm,
        cancel: _vm.cancel,
      },
    },
    [_vm._t("default", [_c("a-icon", { attrs: { type: "delete" } })])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }