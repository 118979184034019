import request from '@/utils/request'

export function getLevelList(){  //课程难度查询条件
    return request({
      url:'/courses/levels',
      method: 'get',
    })
  } 

export function files_upload (data) {
  return request({
    url: `/files/upload`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data
  })
}