import Layout from '@/layout'
export const PaidInterestManagement = [
	{
		path: '/paidInterestManagement',
		name: 'PAID_INTEREST_MANAGEMENT',
		component: Layout,
		redirect: '/equityManagement',
		meta: {
			title: 'paidInterestManagement',
			icon: 'table'
		},
		children: [{
			path: '/equityManagement',
			name: 'EQUITY_MANAGEMENT',
			meta: {
				title: 'equityManagement', //订阅/商品配置管理
				icon: 'table'
			},
			component: () => import('@/views/paidInterestManagement/equityManagement/index')
		},
		// {
		// 	path: '/exchangeCode',
		// 	name: 'EXCHANGE_CODE',
		// 	meta: {
		// 		title: 'exchangeCode', //兑换码
		// 		icon: 'table'
		// 	},
		// 	component: () => import('@/views/paidInterestManagement/exchangeCode/index')
		// }, 
		{
			path: '/autoBikeManagement',
			name: 'AUTOBIKE_MANAGEMENT',
			meta: {
				title: 'autoBikeManagement', //自由骑行管理
				icon: 'table'
			},
			component: () => import('@/views/paidInterestManagement/autoBikeManagement/index')
		},{
			path: '/paymentStrategy',
			name: 'PAYMENT_STRATEGY',
			meta: {
				title: 'paymentStrategy', //付费策略配置
				icon: 'table'
			},
			component: () => import('@/views/paidInterestManagement/paymentStrategy/index')
		}
		// ,{
		// 	path: '/discountActivities',
		// 	name: 'DISCOUNT_ACTIVITIES',
		// 	meta: {
		// 		title: 'discountActivities', //权益包折扣活动管理
		// 		icon: 'table'
		// 	},
		// 	component: () => import('@/views/paidInterestManagement/discountActivities/index')
		// }
		]
	}
]
export const ExchangeCode = [{
	path: '/exchangeCodeManage',
	name: 'RIGHT_EXCHANGE',
	component: Layout,
	redirect: '/exchangeCode',
	meta: {
		title: 'exchangeCode',
		icon: 'table'
	},
	children: [{
		path: '/exchangeCode',
		name: 'EXCHANGE_CODE',
		meta: {
			title: 'exchangeCode',
			icon: 'table'
		},
		component: () => import('@/views/paidInterestManagement/exchangeCode/index')
	}]
}]
// export default PaidInterestManagement