var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("div", {
            staticStyle: { width: "200px", height: "100%" },
            on: {
              click: function ($event) {
                return _vm.openPassword()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.$t("navbar.user")) +
                    ":" +
                    _vm._s(_vm.name) +
                    "\n\t\t\t\t"
                ),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("navbar.dashboard")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/putPassword" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("navbar.putPassword")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }