import i18n from '@/lang'
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime (time, cFormat) {
  if (!time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return i18n.t('timeUnit.weekmap')[value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime (time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return i18n.t('timeUnit.justNow')
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + i18n.t('timeUnit.minutesAgo')
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + i18n.t('timeUnit.hoursAgo')
  } else if (diff < 3600 * 24 * 2) {
    return i18n.t('timeUnit.oneDayBefore')
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      i18n.t('timeUnit.month') +
      d.getDate() +
      i18n.t('timeUnit.day') +
      d.getHours() +
      i18n.t('timeUnit.hour') +
      d.getMinutes() +
      i18n.t('timeUnit.seconds')
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj (url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}
export function formatSeconds (value) {
  let theTime = parseInt(value)// 秒
  let middle = 0// 分

  let hour = 0 // 小时
  let result = ''

  if (theTime > 60) {
    middle = parseInt(theTime / 60)

    theTime = parseInt(theTime % 60)

    if (middle >= 60) {
      hour = parseInt(middle / 60)

      middle = parseInt(middle % 60)
    }
  }

  if (parseInt(theTime) >= 10) {
    result = '0' + ':' + parseInt(theTime)
  } else {
    result = '0' + ':' + '0' + parseInt(theTime)
  }

  if (middle >= 0 && parseInt(theTime) >= 10) {
    result = parseInt(middle) + ':' + parseInt(theTime)
  } else {
    result = (parseInt(middle) < 10 ? '0' + parseInt(middle) : parseInt(middle)) + ':' + '0' + parseInt(theTime)
  }

  if (hour > 0) {
    result = '' + parseInt(hour) + ':' + result
  }

  return result
}

export function timeFilter (secdons) {
  var timeFormat = ''
  if (secdons < 60) {
    var sec
    sec = (secdons > 0) ? secdons + i18n.t('timeUnit.seconds') : ''
    timeFormat = timeFormat + sec
    return timeFormat
  } else if (secdons < 3600) {
    var min
    min = Math.floor(secdons / 60)
    // console.log(min)
    timeFormat = timeFormat + min + i18n.t('timeUnit.minutes')
    timeFilter(secdons - min * 60)
    return timeFormat
  } else {
    var hour
    hour = Math.floor(secdons / 3600)
    timeFormat = timeFormat + hour + i18n.t('timeUnit.hours')
    timeFilter(secdons - hour * 3600)
  }
  return timeFormat
}
export function formatSecond (value) {
  var secondTime = parseInt(value) || 0// 秒
  var minuteTime = 0// 分
  var hourTime = 0// 小时
  if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
    // 获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60)
    // 获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60)
    // 如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60)
      // 获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60)
    }
  }
  var result = '' + parseInt(secondTime) + i18n.t('timeUnit.seconds')

  if (minuteTime > 0) {
    result = '' + parseInt(minuteTime) + i18n.t('timeUnit.minutes') + result
  }
  if (hourTime > 0) {
    result = '' + parseInt(hourTime) + i18n.t('timeUnit.hours') + result
  }
  return result
}
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce (func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

