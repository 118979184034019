import Layout from "@/layout";

const Faqmaneger = [
  {
    path: '/faqmaneger',
    name: 'FAQMANEGER_HOUSE',
    component: Layout,
    meta: {
      title: 'faqmaneger',
      icon: 'table'
    },
    children: [
      {
        path: 'faqmanegerOld',
        name: 'FAQMANEGER',
        component: () => import("@/views/faqmanegerOld/index"),
        hidden: false,
        meta: {
          title: 'faqmanegerOld',
          icon: 'table'
        }
      },
      {
        path: 'faqmanegerNew',
        name: 'FAQMANEGER',
        component: () => import("@/views/faqmanegerNew/index"),
        meta: {
          title: 'faqmanegerNew',
          icon: 'table'
        }
      },
      {
        path: 'recycle',
        name: 'RECYCLE',
        hidden: true,
        component: () => import("@/views/faqmanegerNew/recycleBin/index"),
        meta: {
          title: 'recycleBin',
          icon: 'table'
        }
      }
    ]
  }
]

export default Faqmaneger
