/* eslint-disable no-dupe-keys */
import RecommendedCourse from "./zh_model/RecommendedCourse";
import reducedFatModel from "../lang/zh_model/fatburn";
import bbsqianlongModel from "../lang/zh_model/bbsqianlong";
import programList from "../lang/zh_model/programList";
import faqmaneger from "../lang/zh_model/faqmaneger";
import riding from "../lang/zh_model/riding";

export default {
	app: {
	  title: 'Y_Drag'
	},
	header: {
	  chineseDocument: '中文文档',
	  englishDocument: '英文文档',
	  chat: '交流群', //  Discussion Group
	  logout: '退出登录'
	},
	sidebar: {
	  myWorks: '我的作品',
	  dataCenter: '数据中心',
	  basicData: '基础数据',
	  templateCenter: '模板中心',
	  freeTemplates: '模板列表',
	  accountCenter: '账号中心'
	},
	editor: {
	  sidebar: {
	    components: '组件',
	    pages: '页面',
	    templates: '模板',
	    tree: '组件树',
	    preferences: '偏好'
	  },
	  pageManager: {
	    title: '第{index}页面',
	    action: {
	      add: '新增页面',
	      copy: '复制页面',
	      delete: '删除页面'
	    }
	  },
	  header: {
	    preview: '预览',
	    save: '保存',
	    publish: '发布',
	    setAsTemplate: '设置为模板'
	  },
	  centerPanel: {
	    mode: {
	      edit: 'Edit',
	      preview: 'Preview'
	    },
	    contextMenu: {
	      copy: '复制',
	      delete: '删除',
	      moveToTop: '置顶',
	      moveToBottom: '置底',
	      moveUp: '上移',
	      moveDown: '下移',
	      showOnlyButton: '只有按钮才显示该选项',
	      showExcludePicture: '除了图片都显示该选项'
	    }
	  },
	  fixedTool: {
	    undo: '撤销{hotkey}',
	    redo: '重做{hotkey}',
	    preview: '预览',
	    copyCurrentPage: '复制当前页面',
	    copyCurrentElement: '复制当前元素',
	    importPSD: '导入PSD',
	    zoomIn: '缩小{hotkey}',
	    zoomOut: '放大{hotkey}',
	    issues: '常见问题',
	    poster: '下载海报'
	  },
	  editPanel: {
	    tab: {
	      prop: '属性',
	      animation: '动画',
	      action: '动作',
	      script: '脚本',
	      page: '页面'
	    },
	    animation: {
	      add: '添加动画',
	      run: '运行动画',
	      type: '动画类型',
	      duration: '动画时长',
	      delay: '动画延时',
	      iteration: '运行次数',
	      inifinite: '循环播放',
	      list: '选择动画',
	      title: 'Animation {index}'
	    },
	    method: {
	      add: '使用技能(动作)',
	      run: '运行动作',
	      type: '动作类型',
	      duration: '动作时长',
	      delay: '动作延时',
	      iteration: '运行次数',
	      inifinite: '循环播放',
	      list: '选择动作',
	      title: '动作 {index}'
	    },
	    common: {
	      empty: 'Select an element first please!',
	      ConfigProps: '属性设置',
	      commonStyle: '通用样式',
	      pageMode: '页面模式'
	    }
	  },
	  tip: {
	    componentUsage: '使用提示: {0} 组件即可',
	    click: '点击或拖拽'
	  }
	},
  messages: {
    fileUploadError: "上传失败"
  },
  route: {
	dragMailbox:'动态拖拽',
	editor:'拖拽',
	dragMailboxList:'动态拖拽列表',
	dragMailboxindex:'编辑',
    login: "登录",
		paymentStrategy:'付费策略配置',//2.3.1新增
		exchangeCode:'会员权益兑换码',//2.3.2新增
	paidInterestManagement:"会员权益配置管理",//2.2.7
	equityManagement:"订阅/商品配置管理",//2.2.7    2.3.1变更为 =>  订阅/商品配置管理
	discountActivities:"权益包折扣活动管理",//2.2.7
	userTagManagement:"用户社交TAG管理", //2.2.7
	userTagManagementList:"TAG列表",//2.2.7
	emotionalCopywriting:"情感化文案-配置",//2.2.7
	emotionalCopywritingList:"情感化文案列表",//2.2.7
	updatePush:"更新推送",//2.2.7
	updatePushList:"更新推送",//2.2.7
	pushNotice:"PUSH通知",//2.3.3新增
	pushNoticeList:"PUSH通知",//2.3.3新增
	pushNoticeEdit:"创建推送",
	courseLabel:"课程标签",//2.2.8
	courseLabelDimension:"课程标签维度",//2.2.8
	courseLabelList:"课程标签列表",//2.2.8
    dashboard: "首页",
    recommended_courses: "推荐课程管理",
    documentation: "文档",
    theme: "专题课",
    sharemaneger: "分享管理",
    shareImage: "分享图",
    shareText: "分享文案",
    permission: "角色管理",
    courseConfig: "配置课程",
    checkTheme: "查看专题课",
    riding: "实景课程",
    menuManager: "资源管理",
    addThemeCourseRecord: "从录播课添加课程",
    addThemeCourseUsed: "添加课程",
    addDetailType: "创建分类",
    editDetailType: "编辑分类",
    record: "录播课",
    account: "账号管理",
    system: "系统管理",
    baseConfig: "基础配置",
    target: "目标",
    type: "分类",
    eduAction: "教案动作",
    classType: "课程大类",
    guide: "引导页",
    userManeger: "用户管理",
    list: "列表",
    userDeviceManeger: "用户设备绑定管理",
    putPassword: "修改密码",
    tagsMnager: "标签管理",
    usertag: "用户标签",
    classtag: "课程标签",
    classtaglist: "课程标签列表",
    coachmanager: "教练管理",
    coachlist: "教练配置",
    coachAdd: "添加教练",
    configmanager: "配置教练",
    editmanager: "编辑教练",
    coachingstafflist: "教练团",
    creatcoachingstaff: "创建教练团",
    editcoachingstaff: "编辑教练团",
    coachdetails: "用户详情",
    exchangecodemaneger: "兑换码管理",
    autoBikeManagement: "自由骑行管理",
    exchangecodelist: "兑换码列表",
    exchangecodeentry: "兑换码录入",
    defaultSettingsTitle: "yesoul admin",
    sncodemaneger: "SN码管理",
	memberCommodityManagement:"会员商品管理",//2.2.4新增
	memberDiscountActivities:"会员折扣活动",//2.2.4新增
    sncodelist: "SN码列表",
    sncodeentry: "SN码录入",
    pushmanager: "推送管理",
    grouplist: "分组列表",
    getGroupList: "获取分组列表",
    creatGroup: "创建分组",
    groupDetail: "分组详情",
    pushlist: "推送列表",
    push_list: "获取推送列表",
    pushcreated: "创建推送",
    seeThePush: "查看推送",
    pushdatalist: "推送数据",
    userlist: "用户列表",
    userdetail: "用户详情",
    editGroup: "编辑分组",
    addcoursecategory: "添加分类",
    typeview: "查看分类",
    editclassify: "编辑分类",
    livecourses: "课程管理",
    livecourselist: "直播课",
    createcourse: "创建课程",
	editcourseRiding: "编辑课程",
    Series: "计划",
    /*editcourse: '编辑直播课程',*/
    recyclielist: "已删除课程",
    viewCourse: "查看课程",
    realityList: "实景骑行", // 实景骑行
    programManagement: "节目单管理",
    reviewedCourses: "往期课",
    reviewedlist: "往期课",
		subtitleConfig:'字幕配置',//2.3.2新增
    creatreviewedcourse: "新增往期课程",
    editreviewedcourse: "编辑往期课程",
    /*coursemanagement: '课程管理',*/
    typeofcourse: "课程类型",
    coursemanagementlist: "课程管理列表",
    creatOldCourse: "新增-课程",
    coursePhase: "课程阶段",
    coursemanagement: "课程管理",
    editcourse: "课程-编辑",
    teachingCourse: "教学课程",
    newcomer: "新手必看",
    creatNewcomer: "新手必看-创建",
    editNewcomer: "新手必看-编辑",
    instrumentTutorials: "教学视频-器械教程",
    faqmaneger: "问题反馈",
    faqmanegerOld: "反馈（旧）",
    faqmanegerNew: "反馈（新）",
    recycleBin: "回收站",
    bannermanagement: "运营管理",
    homepagebanner: "广告位管理",
    articlemanagemer: "文章管理",
    userstory: "用户故事",
    creatUserStory: "用户故事-创建",
    editUserStory: "用户故事-编辑",
    creatbanner: "创建banner",
    editbanner: "编辑banner",
    bannerdetail: "banner详情",
    creatarticle: "创建文章",
    editarticle: "编辑文章",
    articledetail: "文章详情",
    articlelist: "文章列表",
    visualmanagement: "数据分析",
    retainedanalysis: "留存分析",
    activeanalysis: "活跃分析",
    payforanalysis: "付费分析",
    lossanalysis: "流失分析",
    creatEquipmentCourse: "器械教程-创建",
    editEquipmentCourse: "器械教程-编辑",
    scoreSet: "得分范围设置",
    qrCodeManagement: "二维码管理",
    qrCodeCreat: "二维码管理-创建",
    qrCodeEdit: "二维码管理-编辑",
	challengeActivities:"挑战活动",//2.2.5新增
	activityManagement:"活动管理",//2.2.5新增
	createChallengeActivity:'创建挑战活动'//2.2.5新增
  },
  btn: {
    ok: "确 定",
    Cancel: "取 消", //$t('btn.Cancel')
    save: "保 存", //$t('btn.ok')
    shelf1: "上架",
    shelf0: "下架",
    reset: "重置",
    submit: "提交",
    search: "搜索",
    /*through: '通过',
    noThrough: '不通过',*/
    reply: "回复",
    viewReply: "查看回复",
    delete: "删除",
    return: "返回"
  },
  timeUnit: {
    weekmap: ["日", "一", "二", "三", "四", "五", "六"],
    minutesAgo: "分钟前",
    hoursAgo: "小时前",
    justNow: "刚刚",
    oneDayBefore: "一天前",
    year: "年",
    month: "月",
    day: "日",
    hour: "时",
    minutes: "分",
    seconds: "秒",
    hours: "小时",
    minutesUnit: "分钟"
  },
  components: {
    accountDialog: {
      userName: "用户名",
      inputUserName: "请输入用户名",
      remarks: "备注",
      inputPosition: "请输入职位",
      phoneNumber: "手机号",
      inputPhoneNumber: "请输入手机号",
      inputPhoneNumber2: "请输入手机号码",
      inputCorrectPhoneNumber: "请输入正确的手机号码",
      status: "状态",
      selectStatus: "请选择状态",
      superAdministrator: "超级管理员",
      pleaseSelect: "请选择",
      operation: "运营",
      SNCodeManager: "SN码管理员",
      enable: "启用",
      disable: "禁用",
      yes: "是",
      no: "否"
    },
    accountmenuDialog: {
      userResource: "用户资源"
    },
    accountroleDialog: {
      add: "添加",
      delete: "删除",
      unlinkedRole: "未关联角色",
      associatedRoles: "已关联角色",
      data: [
        {
          id: 1,
          label: "一级 1"
        },
        {
          id: 2,
          label: "一级 2"
        },
        {
          id: 3,
          label: "一级 3"
        }
      ]
    },
    menuDialog: {
      businessType: "业务类型",
      inputBusinessType: "请输入业务类型",
      resourceLocator: "资源定位符",
      inputResourceLocator: "请输入资源定位符",
      resourceName: "资源名",
      inputResourceName: "请输入资源名",
      remarks: "备注",
      inputRemarks: "请输入备注",
      isRestricted: "是否受限",
      pleaseSelectStatus: "请选择状态",
      parentResourceId: "父资源id",
      inpoutParentResourceId: "请输入父资源id",
      sort: "排序",
      inputSort: "请输排序",
      limitOptions: [
        {
          value: 1,
          label: "受限"
        },
        {
          value: 0,
          label: "非受限"
        }
      ]
    },
    passwordDialog: {
      oldPwd: "旧密码111",
      inputOldPwd: "请输入旧密码",
      newPwd: "新密码",
      inputNewPwd: "请输入新密码",
      confirmTheNewPwd: "确认新密码",
      phoneNumber: "请输入手机号",
      reset: "重置"
    },
    permissionDialog: {
      roleName: "角色名称",
      inputRoleName: "请输入角色名称",
      remarks: "备注"
    },
    rolemenuDialog: {
      roleResource: "角色资源"
    },
    vueDraggableElUpload: {
      currentUpload: "当前最多可上传",
      images: "张图片"
    },
    message: {
      addUserSuccess: "添加成功",
      pleaseSelectData: "请先选中数据",
      deleteSuccess: "删除成功",
      airPwdError: "请输入密码",
      inputPwdAgainError: "请再次输入密码",
      pwdInconsistentError: "两次输入密码不一致!"
    }
  },
  activitymanager: {
    defatting: {
      phoneNumber: "手机号",
      inputPhone: "请输入手机号",
      auditStatus: "审核状态",
      pleaseChoose: "请选择",
      userName: "用户昵称",
      signUpSnCode: "报名SN码",
      signUpTime: "报名时间",
      options: "操作",
      viewDetails: "查看详情",
      through: "通过",
      noThrough: "不通过",
      signUpDetails: "报名详情",
      gender: "性别",
      height: "身高",
      weight: "体重",
      bmi: "BMI",
      age: "年龄",
      images: "照片",
      search: "搜索",
      male: "男",
      female: "女",
      careerCharacteristics: "职业特性",
      signPicture: "报名图片",
      challengePicture: "挑战图片",
      commitTime: "提交审核时间",
      commitReason: "审核原因",
      mentalWorker: "脑力工作者",
      serviceworker: "服务工作者",
      manualWorkers: "体力劳动者",
      /*actsocial*/
      planName: "计划名称",
      inputPlanName: "请输入计划名称",
      replayStatu: "回复状态",
      pleaseSelect: "请选择",
      submitTime: "提交时间",
      challengeDays: "挑战第几天",
      submitContent: "提交内容",
      messageDetails: "留言明细",
      submitPerson: "提交人",
      messageContent: "留言内容",
      replyContent: "回复内容",
      pleaseEnterContent: "请输入内容",
      registerInfo: "报名信息",
      newInfo: "最新信息",
      currentPlan: "目前计划",
      targetWeightLoss: "目标减重：",
      targetWeightLossTo: "目标减重到：",
      weightRegistration: "报名时体重：",
      basalMetabolism: "基础代谢：",
      historyMessages: "历史消息",
      content: "内容",
      reply: "回复",
      all: "全部",
      waitAudit: "待审核",
      responseTime: "回复时间",

      /*defatting-add*/
      activityName: "活动名称",
      activityDetails: "活动描述",
      activityBackgrountImage: "活动背景图",
      activityDetailImage: "活动详情图",
      activityRobotOptions: "活动机器人配置",
      activityRobotNumber: "活动机器人数",
      investBeansNumber: "机器人投入野豆数",
      allRobotNumber: "机器人总投入数",
      completeRobotNumber: "机器人完成数",
      activityStartSignUpTime: "活动报名开始时间",
      activityEndSignUpTime: "活动报名结束时间",
      activityStartTime: "活动开始时间",
      ctivityEndTime: "活动结束时间",
      challengeDay: "挑战天数",
      selectSignUpStartTime: "选择报名开始时间",
      selectSignUpEndTime: "选择报名结束时间",
      selectActivityStartTime: "选择活动开始时间",
      selectActivityEndTime: "选择活动结束时间",
      days: "第几天",
      uploadImageNumber: "上传几张照片",
      activityCycle: "活动周期",
      referralProgram: "每隔几天推荐计划",
      lotterySettings: "抽奖设置",
      challengeSuccess: "挑战成功：",
      challengeError: "挑战失败：",
      lotteryNumber: "抽奖次数",
      selectLottery: "选择奖券",
      rewardBeans: "奖励野豆",
      rewardBeanNumber: "奖励野豆数",
      /*defatting_list*/
      createActivity: "创建活动",
      activityStatus: "活动状态",
      URLaddress: "URL地址",
      challenge: "挑战第",
      day: "天"
    },
    btn: {
      success: "成功",
      error: "失败",
      replyNow: "立即回复",
      shutDown: "关闭",
      delete: "删除",
      addTo: "添加",
      ok: "确定",
      cancel: "取消",
      signUpAudit: "报名审核",
      challengeAudit: "挑战审核",
      interactiveMessage: "互动留言",
      lotteryList: "抽奖列表"
    },
    actapplyState: [
      { value: -1, text: "未报名" },
      { value: 0, text: "审核通过" },
      { value: 1, text: "报名成功" },
      { value: 2, text: "报名失败" },
      { value: 3, text: "报名审核中" },
      { value: 4, text: "审核不通过" },
      { value: 5, text: "提交挑战结果审核" }
    ],
    actresultState: [
      { value: -1, text: "未报名" },
      { value: 0, text: "报名审核通过" },
      { value: 1, text: "挑战成功" },
      { value: 2, text: "挑战失败" },
      { value: 3, text: "报名审核中" },
      { value: 4, text: "报名审核不通过" },
      { value: 5, text: "结果审核中" }
    ],
    actresultFiltersResult: {
      "-1": "未报名", //'未报名'
      "0": "报名审核通过", //'报名审核通过'
      "1": "结果审核成功", //'结果审核成功',
      "2": "结果审核失败", //'结果审核失败',
      "3": "报名审核中", //'报名审核中',
      "4": "报名审核不通过", //'报名审核不通过',
      "5": "结果审核中" //'结果审核中'
    },
    actsocialStatus: {
      noReply: "未回复",
      replied: "已回复"
    },
    defattingListState: {
      all: "全部",
      resignation: "离职",
      inService: "在职"
    },
    confirm: {
      title: "提示",
      content: "确定审核通过吗?",
      ok: "确定",
      cancel: "取消",
      auditSuccess: "审核成功",
      auditError: "审核失败",
      okShutDown: "确认关闭?"
    },
    prompt: {
      title: "审核操作",
      content: "请输入审核不通过原因?",
      ok: "确定",
      cancel: "取消",
      inputErrorMessage: "输入错误",
      optionSuccess: "操作成功",
      optionError: "操作失败",
      /*actresult*/
      actresultTitle: "提示",
      actresultContent: "确定批量提交审核吗?",
      auditThrough: "确定审核通过吗?",
      auditNoThrough: "确定审核不通过吗?"
    },
    message: {
      submitSuccess: "提交成功",
      submitError: "提交失败",
      messageSuccess: "回复成功",
      imagePrompt: "上传图片大小不能超过 2MB!",
      modifySuccess: "修改成功",
      addToSuccess: "添加成功"
    },
    type: {
      siginUpImage: "报名图",
      firstWeek: "第一周",
      secondWeek: "第二周",
      thirdWeek: "第三周",
      fourthWeek: "第四周"
    }
  },
  livecourses: {
    fatBurning: "Fat burning",
    lung: "Cardiopulmonary",
    endurance: "Endurance",
    power: "Power",
    first: "第",
    paragraph: "段落",
    courseEffects: [
      { name: "Fat burning", value: "", remark: "" },
      { name: "Cardiopulmonary", value: "", remark: "" },
      { name: "Endurance", value: "", remark: "" },
      { name: "Power", value: "", remark: "" }
    ]
  },
  bannermanagement: {
    tagName: "标签名称",
    homepagebanner: {
      recommendedSize: "建议尺寸:690*320",
      recommendedSize2: "建议尺寸:690*140",
      nameAlreadyExists: "该名称已存在"
    },
    importanceOptions: {
      all: "全部",
      shelf0: "下架",
      shelf1: "上架",
      shelf2: "未上架",
      shelf3: "已下架"
    },
    message: {
      imagePrompt: "上传头像图片大小不能超过 2MB!"
      //this.$t('bannermanagement.message.imagePrompt')
    }
  },
  /*baseConfig*/
  baseConfig: {
    sectionName: "小节名称",
    targetName: "目标名称",
    actionName: "动作名称",
    categoryName: "分类名称",
    bigClassName: "大类名称",
    creatUser: "创建人",
    creatTime: "创建时间",
    status: "状态",
    configurationItems: "配置项",
    category: "分类",
    lessonPlanAct: "教案动作",
    serialNumber: "序号",
    inputCategoryName: "输入分类名称",
    videoIntroduction: "视频介绍",
    VideoCover: "视频封面",
    defaultValue: "预设值",
    scoreOfTheSet: "得分设置",
    addDefaultValue: "添加预设值",
    courseIntroduction: "课程介绍",
    inputCourseIntroduction: "请输入课程介绍",
    suitablePeople: "适合人群",
    inputSuitablePeople: "请输入适合人群",
    courseEffect: "课程效果",
    Interval: "Interval",
    pleaseSelect: "请选择",
    browserCompatible: "您的浏览器不支持 HTML5 video 标签。",
    inputUserName: "请输入用户名",
    editSection: "编辑小节",
    creatSection: "创建小节",
    editAction: "编辑动作",
    creatAction: "创建动作",
    creatAims: "创建目标",
    editAims: "编辑目标",
    target: "目标",
    targetRequire: "请输入目标",
    createCategory: "创建分类",
    btn: {
      ok: "确定",
      Cancel: "取消"
    },
    effectRanges: [
      {
        name: "Fat burning",
        remark:
          "The higher the index, the longer the stay in the fat burning zone",
        value: "1"
      },
      {
        name: "Cardiopulmonary",
        remark: "The higher the index, the higher the intensity of the course",
        value: "1"
      },
      {
        name: "Endurance",
        remark: "The higher the index, the higher the intensity of the course",
        value: "1"
      },
      {
        name: "Power",
        remark:
          "The higher the index, the more obvious the intensity changes in the course",
        value: "1"
      }
    ],
    scoreSet: {
      mode: "模式",
      option: "操作",
      extendedValueInterval: "扩展值区间(前端有效得分范围)",
      preExpansionValue: "前置拓展值",
      postExpansionValue: "后置拓展值",
      scoreRangeSet: "得分范围设置",
      maxGeZero: "输入的值必须大于等于0",
      maxLe100: "输入的值必须小于等于100",
      mustNumber: "输入的值必须是数字",
      mustInput: "输入值不能为空"
    },
    /*detailType*/
    searchData: [
      { label: "目标名称", prop: "name" },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "启用", value: 1 },
          { label: "禁用", value: 0 }
        ]
      }
    ],
    searchDataClass: [
      { label: "分类名称", prop: "name" },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "启用", value: 1 },
          { label: "禁用", value: 0 }
        ]
      }
    ],
    //动作名称
    actionSearchData: [
      { label: "动作名称", prop: "word" },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "启用", value: 1 },
          { label: "禁用", value: 0 }
        ]
      }
    ],
    //小节名称
    sectionSearchData: [
      { label: "小节名称", prop: "word" },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "启用", value: 1 },
          { label: "禁用", value: 0 }
        ]
      }
    ],
    tableData2: [],
    tableColumn2: [
      { label: "分类名称", prop: "name", type: "normal" },
      { label: "预设值", prop: "defaultNameList", type: "tag" },
      { label: "创建人", prop: "creator", type: "normal" },
      { label: "创建时间", prop: "createTime", type: "normal" },
      { label: "状态", prop: "shelf", type: "switch" },
      {
        label: "操作",
        prop: "action",
        type: "button",
        button: [{ label: "编辑", type: "primary" }],
        method: ["_edit"]
      }
    ],
    statusOptions: [
      {
        value: 1,
        label: "启用"
      },
      {
        value: 0,
        label: "禁用"
      }
    ],
    /*editDetailType*/
    /*eduAction*/
    baseData: { label: "小节", prop: "word", placeholder: "请输入小节名称" },
    baseData_2: {
      label: "动作名称",
      prop: "word",
      placeholder: "请输入动作名称"
    },
    searchData2: [
      { label: "分类名称", prop: "word" },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "启用", value: 1 },
          { label: "禁用", value: 0 }
        ]
      }
    ],
    tableData3: [
      { targetName: "健身", status: false },
      { targetName: "瑜伽", status: false },
      { targetName: "单车", status: false },
      { targetName: "跑步", status: false }
    ],
    tableColumn3: [
      { label: "小节名称", prop: "word", type: "normal" },
      { label: "创建人", prop: "creator", type: "normal" },
      { label: "创建时间", prop: "createTime", type: "normal" },
      { label: "状态", prop: "shelf", type: "switch" },
      {
        label: "操作",
        prop: "action",
        type: "button",
        button: [{ label: "编辑", type: "primary" }],
        method: ["_editor"]
      }
    ],

    /*target*/
    searchData_1: [
      { label: "目标名称", prop: "name" },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "启用", value: 1 },
          { label: "禁用", value: 0 }
        ]
      }
    ],
    tableColumn_4: [
      { label: "目标名称", prop: "name", type: "normal" },
      { label: "创建人", prop: "creator", type: "normal" },
      { label: "创建时间", prop: "createTime", type: "normal" },
      { label: "状态", prop: "shelf", type: "switch" },
      {
        label: "操作",
        prop: "action",
        type: "button",
        button: [{ label: "编辑", type: "primary" }],
        method: ["_editor"]
      }
    ],
    baseData_1: {
      label: "目标名称",
      prop: "name",
      placeholder: "输入目标名称"
    },
    message: {
      keepDefaultValue: "必须保留一个预设值",
      cancelDelete: "已取消删除",
      videoSizePrompt: "上传视频文件大小不能超过 500MB!",
      /*this.$t('baseConfig.message.videoSizePrompt')*/
      uploadError: "上传失败"
    },
    confirm: {
      title: "提示",
      content: "是否删除该预设值?"
    }
  },
  permission: {
    addRole: "添加角色",
    editRole: "编辑角色",
    addAccount: "添加账号",
    addMenu: "添加资源",
    putPassword: "修改密码"
  },
  tablePage: {
    disabled: "禁用",
    useful: "启用"
  },
  navbar: {
    user: "用户",
    dashboard: "首页",
    logOut: "退出登录",
    putPassword: "修改密码"
  },
  login: {
    title: "yesoul admin",
    logIn: "登录",
    username: "账号",
    password: "密码",
    apihosten: "接口域名",
    nameMsg: "账号不能为空",
    noPassword: "密码不能为空",
    loginSuccess: "登录成功",
    loginErr: "登录失败"
  },
  dashboard: {
    dashboard: "首页"
  },
  manager: {
    editAccount: "编辑账号",
    addAccount: "添加账号",
    editResources: "编辑资源",
    addResources: "添加资源",
    minute: "分钟",
    tableColumn: [
      { label: "姓名", prop: "userName", type: "normal" },
      { label: "备注", prop: "position", type: "normal" },
      { label: "状态", prop: "status", type: "switch" },
      { label: "超级管理员", prop: "isSuper", type: "normal" },
      { label: "手机号码", prop: "mobile", type: "normal" },
      {
        label: "操作",
        prop: "action",
        width: "450px",
        type: "button",
        button: [
          { label: "编辑" },
          { label: "关联角色", type: "primary" },
          { label: "重置密码" },
          { label: "删除", type: "primary" }
          /*  { label: "关联资源",type: "primary",disabled:true} */
        ],
        method: ["_edit", "_roleManager", "_reset", "_delete" /* '_addMenu' */]
      }
    ],
    tableColumn_2: [
      { label: "资源名字", prop: "name", type: "normal" },
      { label: "资源备注", prop: "remark", type: "normal" },
      { label: "URI", prop: "uri", type: "normal" },
      {
        label: "操作",
        prop: "action",
        type: "button",
        button: [
          { label: "编辑", type: "primary", disabled: true },
          { label: "删除", disabled: true }
        ],
        method: ["_edit", "_delete"]
      }
    ],
    tableColumn_3: [
      { label: "角色", prop: "name", type: "normal" },
      { label: "备注", prop: "remark", type: "normal" },
      {
        label: "操作",
        prop: "action",
        type: "button",
        button: [
          { label: "编辑", type: "primary" },
          { label: "删除" },
          { label: "关联资源", type: "primary" }
        ],
        method: ["_edit", "_delete", "_menuAction"]
      }
    ],
    confirm: {
      title: "提示",
      deleteCurrentAccount: "确定删除当前账号?",
      isResetPassword: "是否重置密码?",
      isDeleteCurrentRole: "确定删除当前角色?"
    },
    message: {
      deleteCancel: "已取消删除",
      pwdResetPassword: "重置密码成功!",
      setUpUserResources: "设置用户资源成功",
      pwdResetComplete: "密码修改成功",
      setUpRoleResources: "设置角色资源成功"
    }
  },
  oldsystemmodule: {
    inputTagName: "请输入标签名称",
    courseEffects: [
      { name: "Fat burning", value: "" },
      { name: "Cardiopulmonary", value: "" },
      { name: "Endurance", value: "" },
      { name: "Power", value: "" }
    ]
  },
  pushmanager: {
    selectDate: "选择日期时间",
    nameAlreadyExists: "该名称已存在",
    inputTagName: "请输入标签名称",
    userTagOptions: [
      {
        value: "1",
        label: "标签1"
      },
      {
        value: "2",
        label: "标签2"
      }
    ],
    items: [],

    message: {
      inputTagName: "请输入标签名称",
      optionSuccess: "操作Success"
    }
  },
  putPassword: {
    oldPwd: "旧密码",
    inputOldPwd: "请输入旧密码",
    newPwd: "新密码",
    inputNewPwd: "请输入新密码",
    okNewPwd: "确认新密码",
    inputPhoneNumber: "请输入手机号",
    reset: "重置",
    message: {
      pwdError: "请输入密码",
      inputPwdAgainError: "请再次输入密码",
      pwdInconsistentError: "两次输入密码不一致!",
      inputOldPwd: "请输入旧密码",
      inputNewPwd: "请输入新密码",
      okNewPwd: "请确认新密码",
      editPwdSuccess: "密码修改成功"
    }
  },
  record: {
    tag: "标签",
    addTag: "添加标签",
    pleaseSelect: "请选择",
    inputUserName: "请输入用户名",
    creatCourse: "创建课程",
    shelf1: "上架",
    shelf2: "下架",
    noStatus: "无状态",
    tableData: [
      { className: "健身" },
      { className: "瑜伽" },
      { className: "单车" },
      { className: "跑步" }
    ],
    tableColumn: [
      { label: "id", prop: "id", type: "normal" },
      { label: "课程大类", prop: "categoryName", type: "normal", width: 80 },
      { label: "课程名称", prop: "courseName", type: "normal" },
      { label: "课程难度", prop: "courseLevel", type: "normal", width: 80 },
      { label: "时长", prop: "courseDuration", type: "normal" },
      { label: "创建时间", prop: "createTime", type: "normal" },
      { label: "教练名称", prop: "coachName", type: "normal" },
      { label: "分发区", prop: "distribName", type: "normal", width: 120 },
      { label: "状态", prop: "shelf", type: "normal", width: 80 },
      { label: "语言", prop: "langCode", type: "normal", width: 80 },
      {
        label: "操作",
        prop: "action",
        type: "button",
        width: "360px",
        button: [
          { label: "编辑", type: "primary" },
          { label: "查看" },
          { label: "添加标签", type: "primary" },
          { label: "删除" }
        ],
        method: ["_edit", "_check", "_addTag", "_delete"]
      }
    ],
    searchData: [
      {
        label: "课程大类",
        prop: "category_id",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "初级", value: 1 },
          { label: "中级", value: 2 },
          { label: "高级", value: 3 }
        ]
      },
      {
        label: "难度",
        prop: "level_id",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "未上架", value: 2 },
          { label: "上架", value: 1 },
          { label: "下架", value: 0 }
        ]
      },
      {
        label: "时长",
        prop: "duration",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "15分钟", value: 15 * 60 },
          { label: "20分钟", value: 20 * 60 },
          { label: "30分钟", value: 30 * 60 },
          { label: "45分钟", value: 45 * 60 },
          { label: "60分钟", value: 60 * 60 }
        ]
      },
      {
        label: "分发区",
        prop: "distrib_id",
        type: "select",
        option: [{ label: "全部", value: "" }, { label: "限时免费", value: 1 }]
      },
      { label: "课程名称", prop: "course_name" },
      { label: "id", prop: "show_id" },
      { label: "教练", prop: "coach_name" },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "下架", value: "0" },
          { label: "上架", value: "1" }
        ]
      },
      {
        label: "语言",
        prop: "langCode",
        type: "select",
        option: [
          // { label: "全部", value: "" },
        ]
      }
      // { label: "课程开始时间", prop: "time_range",type: "dateRangePicker" }
    ],
    distributeSearchData: [
      {
        label: "id",
        prop: "show_id"
      },
      { label: "课程名称", prop: "course_name" },
      { label: "教练", prop: "coach_name" },
      {
        label: "课程大类",
        prop: "category_id",
        type: "select",
        option: []
      },
      {
        label: "时长",
        prop: "duration",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "15分钟", value: 15 * 60 },
          { label: "20分钟", value: 20 * 60 },
          { label: "30分钟", value: 30 * 60 },
          { label: "45分钟", value: 45 * 60 },
          { label: "60分钟", value: 60 * 60 }
        ]
      },
      {
        label: "难度",
        prop: "level_id",
        type: "select",
        option: []
      }
      // {
      //   label: "状态",
      //   prop: "shelf",
      //   type: "select",
      //   option: [
      //     { label: "全部", value: "" },
      //     { label: "已下架", value: "0" },
      //     { label: "上架", value: "1" },
      //     { label: "未上架", value: "2" }
      //   ]
      // }
    ],
    distributeTableColumn: [
      { label: "id", prop: "id", type: "normal" },
      { label: "课程名称", prop: "courseName", type: "normal" },
      { label: "课程难度", prop: "courseLevel", type: "normal", width: 80 },
      { label: "时长", prop: "courseDuration", type: "normal" },
      { label: "教练名称", prop: "coachName", type: "normal" },
      { label: "完播率", prop: "finishNum", type: "normal", sortable: true },
      { label: "参与人数", prop: "joinNum", type: "normal", sortable: true },
      { label: "开始时间", prop: "beginTime", type: "normal" }
    ]
  },
  reviewedcourses: {
    needInstrument: "所需器械",
    courseEffects: [
      { name: "Fat burning", value: "", remark: "" },
      { name: "Cardiopulmonary", value: "", remark: "" },
      { name: "Endurance", value: "", remark: "" },
      { name: "Power", value: "", remark: "" }
    ],
    message: {
      JPGformat: "上传头像图片只能是 JPG 格式!"
    }
  },
  tagmanager: {
    open: "展开",
    close: "收起",
    courseName: "课程名称",
    inputCourseName: "请输入课程名称",
    option: "操作",
    search: "搜索"
  },
  langOptions: [
    { label: "简体中文（zh-CN）", value: "zh" },
    { label: "英语（en）", value: "en" }
    // {label:'德语（de）',value:'de'},
    // {label:'西班牙语（es）',value:'es'}
  ], //语言切换配置
  promptObj: {
    selectPlaceholder: "请选择",
    err400msg: "存在还未填写的字段",
    sizeTips: "请上传尺寸:",
    loadingTips: "加载中...",
    batchSuccess: "批量成功",
    batchError: "批量失败",
    modifyTheSuccess: "修改成功",
    modifyTheFailure: "修改失败",
    haveBeenAdded: "已添加",
    addSuccess: "添加成功",
    addError: "添加失败",
    delectSuccess: "删除成功",
    deleted: "已删除",
    cancel: "已取消",
    hasBeenOn: "已上架",
    newErrMsg: "服务异常",
    hasBeenOffTheShelves: "已下架",
    copySuccess: "复制成功！",
    copyError: "请点击生成兑换码！",
    checkRelatedOptions: "请先勾选相关内容",
    successfullyListed: "上架成功",
    successfullyRemoved: "下架成功",
    sortSuccess: "排序成功",
    deleteCurrentRow: "确定删除所选内容吗？",
    prompt: "提示",
    sortEmpty: "当前排序列表为空,请先上架相关内容",
    creatSuccess: "创建成功",
    modifiedStatusSuccess: "修改状态成功",
    offSelfAfterDelete: "存在上架内容，请先将其下架后再删除",
    uploadImageSizePrompt: "上传图片大小不能超过 200MB!",
    imageCannotBeEmpty: "图片不能为空",
    linkAddressCannotBeEmpty: "链接地址不能为空",
    uploadImageFailed: "图片上传地址获取失败，请重新上传",
    noMoreThenTwenty: "请输入名称，不超过20个字"
  },
  actionbuttons: {
    allLocations: "所有位置",
    allSelect: "全部",
	exportLessonPlan:"导出教案",//2.2.4新增 导出教案按钮
	importLessonPlans:"导入教案",//2.2.4新增 导入教案
	lessonPlansTips:"若已经添加了教案，需要把已添加的教案全部清除后才能导入新的教案",//2.2.4新增 导入教案tips
	lessonErrTips:"附件格式错误，请删除后重新上传！",//2.2.4新增 导入教案出错提示语
	lessonTips:"请上传附件！",//2.2.4新增 请上传附件！
    search: "搜索",
    addaction: "添加",
    createaction: "创建",
    editaction: "编辑",
    deleteaction: "删除",
    saveaction: "保存",
    detail: "详情",
    shelves: "上架",
    soldout: "下架",
    export: "导出",
    toview: "查看",
    viewcourse: "查看课程",
    forBack: "返回",
    confirmAction: "确定",
    cancelAction: "取消",
    closeAction: "关闭",
    moveUp: "上移",
    moveDown: "下移",
    copyAction: "复制",
    recoveryCourse: "恢复",
    hot: "热门",
    unbind: "解绑",
    resetPsw: "重置密码",
    coachSupervisor: "教练主管",
    cancelSupervisor: "取消主管",
    sortButton: "排序",
    options: "操作",
    replay: "回复",
    addToRecycle: "加入回收站",
    reEditBtn: "重新编辑",
    recycleBinBtn: "回收站",
    yesBtn: "是",
    noBtn: "否"
  },
  registrationSourceOptions: [
    {
      value: "1",
      text: "App"
    },
    {
      value: "2",
      text: "屏幕端"
    }
  ],
  sexOptions: [
    {
      value: "",
      text: "全部"
    },
    {
      value: 0,
      text: "男"
    },
    {
      value: 1,
      text: "女"
    }
  ],
  sexMap: {
    1: "女",
    0: "男",
	2: "其他"
  },
  attributeGender: {
    0: "男",
    1: "女",
    2: "全部"
  },
  deliveredUserType: {
    0: "全部用户",
    1: "标签用户"
  },
  workingState: [
    {
      value: "",
      text: "全部"
    },
    {
      value: 0,
      text: "离职"
    },
    {
      value: 1,
      text: "在职"
    }
  ],
  workingObj: {
    0: "离职",
    1: "在职"
  },
  importanceOptions: [
    {
      status: "",
      text: "全部"
    },
    {
      status: 2,
      text: "未上架"
    },
    {
      status: 1,
      text: "上架"
    },
    {
      status: 0,
      text: "下架"
    }
  ],

  importanceStatusMap: {
    2: "未上架",
    0: "已下架",
    1: "已上架"
  },

  // 兑换码添加验证
  exchange_codes_add: {
    serialNumber: [
      {
        required: true,
        message: "请点击生成兑换码",
        trigger: "blur"
      }
    ],
    expireTime: [
      {
        required: true,
        message: "请选择有效时间",
        trigger: "blur"
      }
    ],
    bindPeopleNum: [
      {
        required: true,
        message: "请填写使用次数",
        trigger: "blur"
      }
    ]
    // vipDays: [
    //   {
    //     required: true,
    //     message: '请输入赠送时长',
    //     trigger: 'blur'
    //   }
    // ]
  },
  courseEffectInterval: [
    {
      label: 1,
      value: 1
    },
    {
      label: 2,
      value: 2
    },
    {
      label: 3,
      value: 3
    },
    {
      label: 4,
      value: 4
    },
    {
      label: 5,
      value: 5
    },
    {
      label: 6,
      value: 6
    },
    {
      label: 7,
      value: 7
    },
    {
      label: 8,
      value: 8
    },
    {
      label: 9,
      value: 9
    },
    {
      label: 10,
      value: 10
    }
  ],
  notifyMsg: {
    updatesuccessfully: "更新成功",
    updatefailed: "更新失败"
  },
  // 兑换码国际化
  exchangecodemaneger: {
    conversioncode: "兑换码",
    conversionplaceholder: "请输入兑换码",
    givingduration: "会员天数",
    givingplaceholder: "请输入会员天数",
    status: "状态",
    statusplaceholder: "请选择状态",
    headerfields: {
      conversioncode: "兑换码",
      givingduration: "会员天数",
      totaltimes: "总次数",
      day: "天",
      status: "状态",
      periodvalidity: "有效期",
      changeTime: "兑换时间",
      used: "已用",
      free: "剩余",
      effectivedate: "有效日期",
      destruction: "销毁",
      operation: "操作"
    },
    destruction: {
      title: "销毁",
      unExchangeMsg: "确定销毁该兑换码？",
      destoryMsg: "该兑换码已被兑换，销毁操作会导致使用者权益失效，是否继续？",
      success: "解绑成功",
      cancel: "取消"
    },
    importanceOptions: [
      {
        status: 0,
        text: "全部"
      },
      {
        status: 1,
        text: "上架"
      },
      {
        status: 2,
        text: "未上架"
      },
      {
        status: 3,
        text: "已下架"
      }
    ],

    statusMap: {
      0: "未兑换",
      1: "已兑换"
    },
    effectiveDateTypeMap: {
      0: "不限时",
      1: "过期时间"
    },
    statuslist: [
      {
        value: "",
        label: "全部"
      },
      {
        value: 1,
        label: "已兑换"
      },
      {
        value: 0,
        label: "未兑换"
      }
    ],
    dialogfields: {
      title: "创建兑换码",
      codeDetails: "兑换码详情",
      timeLimit: "有效期",
      unlimited: "不限时",
      expireDate: "过期时间",
      editTitle: "编辑兑换码",
      givingduration: "赠送时长(天)",
      givingplaceholder: "请输入赠送时长",
      usedtimes: "使用次数",
      usedtimesplaceholder: "请输入使用次数",
      effectivedate: "有效日期",
      effectivedatePlaceholder: "请选择有效日期",
      startplaceholder: "开始日期",
      endplaceholder: "结束日期",
      to: "至",
      getcode: "生成兑换码",
      editcode: "兑换码",
      getcodeplaceholder: "点击生成兑换码",
      copy: "复制",
      confirmbtn: "确认",
      cancelbtn: "取消"
    },
    dialogexchangeinfo: {
      title: "兑换信息",
      exchangecode: "兑换码",
      duration: "时长",
      effectivedate: "有效日期",
      totaltimes: "总次数",
      used: "已用",
      free: "剩余",
      forback: "返回",
      tablefields: {
        nickname: "昵称",
        phone: "手机",
        exchangetime: "兑换时间"
      }
    }
  },
  // exchangecodeentry兑换码录入
  exchangecodeentry: {
    vipfordays: "会员天数",
    vipfordaysPlaceholder: "请输入会员天数",
    exchangecodes: "兑换码",
    exchangecodePlaceholder:
      "最多仅支持500个兑换码操作，每个之间用英文逗号分隔",
    periodofvalidity: "有效期",
    unlimited: "不限时",
    expirationdate: "时间",
    startTime: "开始时间",
    endTime: "结束时间",
    expirationdatePlaceholder: "请选择有效日期",
    numOfExchangeCodeGeneration: "兑换码生成数量",
    getcodenumplaceholder: "请输入兑换码生成数量",

    memberNumberGroup: [
      {
        label: "请选择",
        value: 0
      },
      {
        label: "7天",
        value: 7
      },
      {
        label: "30天",
        value: 30
      },
      {
        label: "60天",
        value: 60
      },
      {
        label: "365天",
        value: 365
      }
    ]
  },
  // SN码国际化
  sncodemaneger: {
    instrument: "器械",
    instrumentAll: "全部",
    snCode: "SN码",
    snCodeplaceholder: "请输入SN码",
    state: "状态",
    stateplaceholder: "请输入SN请选择状态",
    membernumber: "会员天数",
    memberplaceholder: "请输入会员天数",
    stateGroup: [
      {
        label: "全部",
        value: ""
      },
      {
        label: "已绑定",
        value: "isnotnull isnotnull"
      },
      {
        label: "未绑定",
        value: "isnull isnull"
      }
    ],
    statusMap: {
      0: "未绑定",
      1: "已绑定"
    },
    bindingInfo: {
      infoTitle: "绑定信息",
      snCode: "SN码",
      state: "状态",
      totalNumberBindings: "可绑定人数",
      numberOfBound: "已绑定人数",
      memberNumber: "会员天数",
      headerfields: {
        name: "用户名",
        phone: "手机号",
        email: "邮箱",
        bindTime: "绑定时间"
      }
    },
    headerfields: {
      snCode: "SN码",
      categoryName: "器械",
      numberofbindings: "可绑定人数",
      numberofbound: "已绑定人数",
      membernumber: "会员天数",
      state: "状态",
      operation: "操作"
    },
    unBindSn: {
      title: "解绑",
      message: "解绑操作会导致关联该SN码的所有用户权益失效，是否继续？",
      success: "解绑成功",
      cancel: "取消"
    },
    editSnCode: {
      title: "编辑SN码",
      memberNumber: "会员天数",
      memberPlaceholder: "请输入会员天数",
      custom: "自定义",
      customPlaceholder: "请输入兑换天数",
      numberOfBinding: "绑定人数",
      numberOfBindingMsg: "请输入绑定人数",
      numberOfBindingPlaceholder: "默认支持5人",
      snCode: "SN码",
      snCodePlaceholder: "最多仅支持500个SN操作,每个之间用英文逗号分隔",
      repeatSn: "SN码重复,请确认后提交",
      maxBindFives: "最多绑定5人"
    },
    memberNumberGroup: [
      {
        label: "请选择",
        value: 0
      },
      {
        label: "7天",
        value: 7
      },
      {
        label: "30天",
        value: 30
      },
      {
        label: "60天",
        value: 60
      },
      {
        label: "365天",
        value: 365
      }
    ],
    editFormatMsg: {
      memberNumber: [
        {
          required: true,
          message: "请选择会员天数",
          trigger: "blur"
        }
      ],
      custom: [
        {
          required: true,
          message: "请输入兑换天数",
          trigger: "blur"
        }
      ],
      numberOfBinding: [
        {
          required: true,
          message: "请输入绑定人数",
          trigger: "blur"
        }
      ]
    }
  },
  sncodeentry: {
    memberNumber: "会员天数",
    memberPlaceholder: "请输入会员天数",
    custom: "自定义",
    customPlaceholder: "请输入兑换天数",
    numberOfBinding: "绑定人数",
    numberOfBindingPlaceholder: "默认支持5人",
    numberOfBindingMsg: "请输入绑定人数",
    snCode: "SN码",
    instrument: "器械",
    sncodeentryplaceholder: "请选择器械",
    instrumentGroup: [
      { value: "", label: "请选择" },
      { value: 1, label: "单车" },
      { value: 2, label: "跑步机" },
      { value: 3, label: "划船机" },
      { value: 4, label: "健腹轮" }
    ],
    snCodePlaceholder: "最多仅支持500个SN操作,每个之间用英文逗号分隔",
    formSearch: {
      memberNumber: [
        {
          required: true,
          message: "请选择会员天数",
          trigger: "blur"
        }
      ],
      // custom: [
      //   {
      //     required: true,
      //     message: '请输入兑换天数',
      //     trigger: 'blur'
      //   }, { validator: /^[+]{0,1}(\d+)$/, message: '兑换天数为正整数', trigger: 'blur' }
      // ],
      numberOfBinding: [
        {
          required: true,
          message: "请输入绑定人数",
          trigger: "blur"
        }
      ],
      snCode: [
        {
          required: true,
          message: "请输入SN码",
          trigger: "blur"
        }
      ]
    }
  },
  userBodyFile: {
    weight: "体重",
    BMI: "BMI",
    KG: "KG",
    HEIGHT: "身高",
    powerThreshold: "功率阈值",
    bodyFatRate: "体脂率",
    maxHeartRate: "最大心率",
    headerfields: {
      updateTime: "更新时间",
      updateTheWay: "更新方式",
      updateValues: "更新数值"
    },
    tableNoDateMsg: {
      weightMsg: "暂无更新",
      BMIMsg: "智能匹配",
      powerThresholdMsg: "暂无更新",
      bodyFatRateMsg: "手动更新",
      maxHeartRateMsg: "暂无更新"
    }
  },
  // 推送分组管理
  grouplist: {
    groupName: "分组名称",
    groupNamePlaceholder: "请输入分组名称",
    creatGroup: "创建分组",
    headerfields: {
      groupName: "分组名称",
      userAttributes: "用户属性",
      deliveredUser: "送达用户",
      creatTime: "创建时间",
      operation: "操作"
    },
    delectInfo: {
      title: "提示",
      content: "是否删除该分组？",
      deleteGroupSuccess: "删除分组成功",
      cancel: "已取消"
    }
  },
  creatGroup: {
    title: "新建用户分组",
    groupName: "用户分组名称",
    namePlaceholder: "请输入用户分组名称",
    describe: "用户分组描述",
    describePlaceholder: "请输入用户分组描述",
    setType: "分类设置",
    userAttribute: "用户属性",
    pleaseSelect: "请选择",
    deliveredUser: "送达用户",
    continueSelect: "继续选择",
    firstLabel: "全部",
    userAttributeSelected: "已选用户属性",
    allusersLabel: "全部用户",
    tagsUserLabel: "标签用户",
    userTagName: "用户标签",
    userTagNamePlaceholder: "请选择用户标签",
    confirm: {
      title: "提示",
      addSuccess: "添加成功",
      editSuccess: "修改成功",
      cancelMsg: "已取消"
    },
    requireUserTag: "请选择用户标签",
    creatGroupRule: {
      name: [
        {
          required: true,
          message: "请输入标签名称",
          trigger: "blur"
        }
      ],
      confirmAttributeTags: [
        {
          required: true,
          message: "请选择用户属性",
          trigger: "blur"
        }
      ],
      type: [
        {
          required: true,
          message: "请选择送达用户",
          trigger: "blur"
        }
      ]
    }
  },
  pushlist: {
    groupName: "分组名称",
    groupNamePlaceholder: "请输入分组名称",
    noticeTheTitle: "通知标题",
    noticeTheTitlePlaceholder: "请输入通知标题",
    pushTheTime: "推送时间",
    startplaceholder: "开始日期",
    endplaceholder: "结束日期",
    creatPush: "创建推送",
    to: "至",
    IOS: "IOS",
    andorid: "andorid ",
    allPlatform: "全部",
    headerfields: {
      noticeTitleLabel: "通知标题",
      groupName: "推送分组",
      deliveredUser: "目标平台",
      pushTheTime: "推送时间",
      operation: "操作"
    },
    prompt: {
      title: "提示",
      content: "是否删除该推送"
    }
  },
  pushcreated: {
    selectGroupLabel: "推送分组",
    selectGroupPlaceholder: "请选择分组",
    targetPlatFormLabel: "目标平台",
    noticeTitleLabel: "通知标题",
    titlePlaceholder: "请输入通知标题",
    noticeContentLabel: "通知内容",
    contentPlaceholder: "请输入通知内容",
    pushPictureLabel: "推送图片",
    upImg: "上传图片",
    pushUrl: "url",
    urlPlaceholder: "请输入链接地址",
    followUpActionLabel: "后续动作",
    startApplication: "启动应用",
    openLinks: "打开链接",
    pushMethodLabel: "推送方式",
    timing: "定时",
    instant: "即时",
    android: "安卓",
    IOS: "IOS",
    allPlatform: "全部",
    selectDate: "时间选择",
    selectDatePlaceholder: "请选择日期",
    selectTimePlaceholder: "请选择时间",
    pushValidateCode: "短信验证码",
    sendSms: "发送短信",
    formSearch: {
      selectGroup: [
        {
          required: true,
          message: "请选择分组",
          trigger: "blur"
        }
      ],
      targetPlatForm: [
        {
          required: true,
          message: "请选择目标平台",
          trigger: "blur"
        }
      ],
      noticeTitle: [
        {
          required: true,
          message: "请输入通知标题",
          trigger: "blur"
        }
      ],
      noticeContent: [
        {
          required: true,
          message: "请输入通知内容",
          trigger: "blur"
        }
      ],
      pushUrl: [
        {
          required: true,
          message: "请输入链接地址",
          trigger: "blur"
        }
      ],
      followUpAction: [
        {
          required: true,
          message: "请选择后续动作",
          trigger: "blur"
        }
      ],
      pushMethod: [
        {
          required: true,
          message: "请选择推送方式",
          trigger: "blur"
        }
      ],
      pushValidateCode: [
        {
          required: true,
          message: "请输入短信验证码",
          trigger: "blur"
        }
      ]
    }
  },
  seeThePush: {
    titleOne: "用户信息",
    titleTwo: "推送信息"
  },
  pushdatalist: {
    groupName: "分组名称",
    groupNamePlaceholder: "请输入分组名称",
    noticeTheTitle: "通知标题",
    noticeTheTitlePlaceholder: "请输入通知标题",
    pushTheTime: "推送时间",
    startplaceholder: "开始日期",
    endplaceholder: "结束日期",
    creatPush: "创建推送",
    to: "至",
    headerfields: {
      noticeTheTitle: "通知标题",
      groupName: "推送分组",
      pushTheTime: "推送时间",
      targetPlatForm: "目标平台",
      touchFor: "触达数",
      issuedByTheNumber: "下发成功数",
      clicks: "点击数"
    },
    targetPlatForm: "目标平台",
    targetPlatFormPlaceholder: "请选择目标平台",
    targetPlatFormOption: [
      { label: "全部", value: "" },
      { label: "安卓", value: 0 },
      { label: "IOS", value: 1 }
    ]
  },
  // 0 录播 1 直播 2 往期
  is_live_map: {
    0: "录播课程",
    1: "直播课程",
    2: "往期课程"
  },

  coachmanager: {
    ID: "ID",
    idPlaceholder: "请输入ID",
    coachName: "教练名称",
    coachNamePlaceholder: "请输入教练名称",
    phone: "手机号",
    phonePlaceholder: "请输入手机号",
    email: "邮箱",
    emailPlaceholder: "请输入邮箱",
    sex: "性别",
    sexPlaceholder: "请选择性别",
    state: "状态",
    statePlaceholder: "请选择状态",
    number: "个",
    nameAlreadyExists: "该名称已存在",
    create: "添加教练",
    baseMassage: {
      coach: "教练",
      homeCover: "主页封面",
      coachImage: "教练页顶部图片",
      recommendedSize: "建议尺寸：750*440",
      recommendedSize1: "教练个人主页封面 建议尺寸：750*440",
      liveCourseCover0: "直播页封面",
      liveCourseCover: "直播顶部图",
      liveCourseCover1: "直播顶部图",
      recommendedSize2: "建议尺寸：750*524",
      recommendedSize_2: "建议尺寸：1029*579",
      recommendedSize_3: "建议尺寸：1125*786",
      recommendedSize3: "建议尺寸：1125*786",
      recommendedSize4: "建议尺寸：1029*579",
      recommendedSize5: "建议尺寸：375*284",
      pastCourseCover: "往期课封面",
      recordCourseCover: "录播课封面",
      email: "邮箱",
      inputEmail: "请输入邮箱",
      jobTitle: "职称",
      inputJobTitle: "请输入职称",
      achievement: "成就",
      separate: "多个成就用英文逗号分隔",
      areasExpertise: "擅长领域",
      enterKey: "输入后点击回车键确认",
      signature: "签名",
      inputSignature: "请输入签名",
      introduction: "简介",
      inputiIntroduction: "请输入简介",
      photoAlbum: "相册",
      uploadPhotosOrVideo: "上传照片/视频 建议尺寸：200*246"
    },
    creat: "创建",
    coachingstafflist: {
      coachTeamName: "教练团名称",
      pleaseInputName: "请输入名称",
      coachTeamAvatar: "教练团头像",
      recommendedSize: "建议尺寸：120*120",
      coach: "教练",
      pleaseSelect: "请选择",
      dynamicTags: [
        { name: "标签一" },
        { name: "标签二" },
        { name: "标签三" },
        { name: "标签四" },
        { name: "标签五" }
      ],
      searchData: [{ label: "教练团名称", prop: "groupName" }],
      tableColumn: [
        { label: "教练团名称", prop: "groupName", type: "normal" },
        { label: "团内成员", prop: "coachList", type: "tag" },
        { label: "创建时间", prop: "createTime", type: "normal" },
        { label: "状态", prop: "status", type: "switch" },
        {
          label: "操作",
          prop: "action",
          type: "button",
          width: "360px",
          button: [
            { label: "编辑", limit: "shelf", limitValue: "上架" },
            { label: "查看", type: "primary" }
          ],
          method: ["_edit", "_check"]
        }
      ],
      tableData: [
        {
          name: "test"
        }
      ]
    },
    btn: {
      return: "返 回"
    },
    message: {
      renewLogin: "请重新登录",
      repeatError: "重复领域，请确认后输入",
      formatError: "请上传正确的文件格式",
      videoSizeError: "上传视频大小不能超过500MB哦!",
      formatTypeError: "图片格式不正确,只支持jpeg,jpg和png类型图片",
      imageUpdate: "图片上传中，请稍后",
      loginError: "请重新登录",
      uploadFailedError: "上传失败，请重新上传",
      /*coachingstafflist*/
      ageError: "年龄不能为空",
      numberError: "请输入数字值",
      ageLeastError: "必须年满18岁",
      pwdError: "请输入密码",
      pwdAgainError: "请再次输入密码",
      pwdInconsistentError: "两次输入密码不一致!",
      addSuccess: "添加成功",
      addError: "添加失败",
      editSuccess: "修改成功",
      resetPswText: "重置后的密码为“123456”，确定重置密码吗？",
      cancelText: "请先取消该教练的主管职务",
      setSuccess: "设置主管成功",
      cancelSuccess: "取消主管成功",
      resetPswSuccess: "重置密码成功",
      resetPswFail: "重置密码失败",
      sureSetCoach: "确认将该教练设置为主管吗?",
      sureCancelSetCoach: "确认取消该教练的主管状态吗?",
      prompt: "提示",
      cancelOption: "已取消操作",
      inputEmail: "邮箱不能为空",
      inputIntroduction: "简介不能为空",
      inputTitle: "职称不能为空",
      exceed: "擅长领域不能超过3个"
    },
    headerfields: {
      id: "ID",
      headerImg: "头像",
      name: "姓名",
      sex: "性别",
      phone: "手机号",
      email: "邮箱",
      inTheTime: "入驻时间",
      numberOfCourse: "课程数",
      departure: "离职",
      onTheJob: "在职",
      state: "状态",
      operation: "操作",
      coverPic: "课程封面缺省图",
      infoPic: "课程详情缺省图"
    },
    confirm: {
      title: "提示",
      submitThisCoach: "确定提交新增该教练?",
      editThisCoach: "确定编辑该教练?"
    },
    coachlistEditStatus: {
      title: "提示",
      content: "确认修改该教练任职状态？",
      comfirmOkMsg: "修改成功"
    },
    creatAndEdit: {
      creatTitle: "创建教练",
      editTitle: "编辑教练",
      coachName: "教练名称",
      coachNamePlaceholder: "请输入教练名称",
      sexLabel: "性别",
      male: "男",
      female: "女",
      age: "年龄",
      agePlaceholder: "请输入年龄",
      header: "头像",
      headerCoursePic: "教练封面",
      coursePic: "直播课封面",
      videoCourseCover: "往期课封面",
      coverPlaceholder: "请上传往期课封面",
      clickUpload: "点击上传",
      phone: "手机号",
      phonePlaceholder: "请输入手机号",
      introduction: "简介",
      introPlaceholder: "请输入简介",
      address: "地址",
      addressPlaceholder: "请选择地址",
      addressDetailPlaceholder: "请填写详细地址",
      addressDetail: "详细地址",
      whetherInOffice: "是否在职",
      onTheJob: "是",
      departure: "否",
      labelRule: {
        nickname: [
          {
            required: true,
            message: "请输入教练名称",
            trigger: "blur"
          }
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur"
          }
        ],
        age: [
          {
            required: true,
            message: "请输入年龄",
            trigger: "blur"
          }
        ],
        photo: [
          {
            required: true,
            message: "请选择头像",
            trigger: "blur"
          }
        ],
        coursePic: [
          {
            required: true,
            message: "请上传课程封面",
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur"
          }
        ],
        introduction: [
          {
            required: true,
            message: "请输入简介",
            trigger: "blur"
          }
        ]
      }
    }
  },
  coachdetails: {
    baseTap: "基本信息",
    coachTap: "课程信息",
    baseTapObj: {
      state: "状态",
      phone: "手机号",
      sex: "性别",
      address: "地址",
      introduction: "简介"
    },
    coachTapObj: {
      theTotalCourse: "总课程",
      participation: "参与人数",
      manTime: "参与人次"
    },
    headerfields: {
      coachId: "课程ID",
      coachName: "课程名称",
      coachType: "课程大类",
      classTime: "上课时间",
      manTime: "参与人次",
      participation: "参与人数"
    }
  },
  usertag: {
    name: "名称",
    status: "状态",
    enterholder: "请输入名称",
    search: "搜索",
    inputTagName: "请输入标签名称",
    statePlaceholder: "请选择状态",
    shelves: "上架",
    soldOut: "下架",
    selctAddData: "请先选中添加数据",
    headerfields: {
      name: "名称",
      state: "状态",
      creator: "创建人",
      creationtime: "创建时间",
      operation: "操作",
      deleteTagMsg: "是否删除该标签？"
    },
    creattagsdialog: {
      title: "创建标签",
      confirmbtn: "确定",
      cancelbtn: "取消",
      labelName: "标签名称",
      labelNameHolder: "请填写标签名称",
      enterholder: "请填写标签名称",
      tagType: "标记类型",
      tagTypeHolder: "请填写标记类型",
      creatTitle: "新增标签",
      editTitle: "编辑标签"
    },
    deleteTagMsg: "是否删除该标签？",
    deleteTagSuccess: "已成功删除标签",
    cancelMsg: "已取消",
    prompt: "提示",
    labelRule: {
      name: [
        {
          required: true,
          message: "请输入标签名称",
          trigger: "blur"
        }
      ]
    }
  },
  classtag: {
    tagOne: "分类",
    tagTwo: "标签",
    tagName: "标签名称",
    tagNamePlaceholder: "请输入名称",
    state: "状态",
    statePlaceholder: "请选择状态",
    creatTime: "创建时间",
    startTime: "开始日期",
    endTime: "结束日期",
    hotTag: "热门",
    shelves: "上架",
    soldOut: "下架",
    deleteTagMsg: "确认删除该标签？",
    deleteTagSuccess: "已成功删除标签",
    cancelMsg: "已取消",
    prompt: "提示",
    headerfields: {
      levelOneLabel: "名称",
      levelTwoLabel: "属性名称",
      state: "状态",
      creator: "创建人",
      creatTime: "创建时间",
      operation: "操作"
    },
    levelOneDetail: {
      title: "查看分类",
      tagName: "分类名称",
      levelTwoName: "二级标签",
      tagDetail: "标签详情",
      clicks: "点击次数"
    },
    setHotTags: {
      title: "设置热门标签",
      tagName: "标签名称",
      hot: "设置热门",
      cancelHot: "取消热门",
      hotSort: "热门排序",
      operation: "操作",
      serialNumber: "序号"
    },
    addOrEdit: {
      addTitle: "添加一级标签",
      editTitle: "编辑一级标签",
      tagName: "标签名称",
      tagPlaceholder: "请填写标签名称",
      levelTags: "二级标签"
    },
    labelRule: {
      name: [
        {
          required: true,
          message: "请输入标签名称",
          trigger: "blur"
        }
      ]
    },
    shelvesOperation: {
      msg: "确定上架所选标签吗？",
      success: "已上架",
      cancelMsg: "已取消",
      prompt: "上架提示"
    },
    soldOutOperation: {
      msg: "确定下架所选标签吗？",
      success: "已下架",
      cancelMsg: "已取消",
      prompt: "下架提示"
    },
    courseTable: {
      id: "ID",
      categories: "课程大类",
      title: "课程名称",
      difficulty: "课程难度",
      duration: "时长",
      source: "来源",
      operating: "操作"
    },
    options: {
      viewCourse: "查看",
      deletCourse: "移除"
    },
    deleteCourse: {
      title: "提示",
      content: "确定删除该课程吗?",
      ok: "确定",
      no: "取消",
      okDelete: "已成功删除该课程",
      noDelect: "取消删除"
    }
  },
  classtagTwo: {
    tagName: "标签名称",
    tagNamePlaceholder: "请输入名称",
    state: "状态",
    statePlaceholder: "请选择状态",
    creatTime: "创建时间",
    startTime: "开始日期",
    endTime: "结束日期",
    hotTag: "热门",
    shelves: "上架",
    soldOut: "下架",
    deleteTagMsg: "确认删除该标签？",
    deleteTagSuccess: "已成功删除标签",
    cancelMsg: "已取消",
    prompt: "提示",
    addTagRule: {
      name: [
        {
          required: true,
          message: "请输入标签名称",
          trigger: "blur"
        }
      ],
      suitsCrowd: [
        {
          required: true,
          message: "请输入适合人群",
          trigger: "change"
        }
      ],
      courseIntroduction: [
        {
          required: true,
          message: "请输入课程介绍",
          trigger: "change"
        }
      ],
      effect: {
        value: [
          {
            required: true,
            message: "请选择燃脂区间值",
            trigger: "change"
          }
        ],
        fatBurn: [
          {
            required: true,
            message: "请选择燃脂区间值",
            trigger: "change"
          }
        ],
        cardiopulmonary: [
          {
            required: true,
            message: "请选择心肺区间值",
            trigger: "change"
          }
        ],
        endurance: [
          {
            required: true,
            message: "请选择耐力区间值",
            trigger: "change"
          }
        ],
        power: [
          {
            required: true,
            message: "请选择力量区间值",
            trigger: "change"
          }
        ]
      }
    },
    headerfields: {
      attributionTag: "归属标签",
      levelTwoLabel: "标签",
      state: "状态",
      creator: "创建人",
      creatTime: "创建时间",
      operation: "操作"
    },
    levelTwoDetail: {
      title: "查看标签",
      addTitle: "添加标签",
      editTitle: "编辑标签",
      tagName: "名称",
      tagNamePlaceholder: "请输入名称",
      introduce: "课程介绍",
      suitsCrowd: "适合人群",
      effectCourse: {
        title: "课程效果",
        fatBurn: "Fat burning",
        cardiopulmonary: "Cardiopulmonary",
        endurance: "Endurance",
        power: "Power",
        interval: "Interval",
        fatBurnPlaceholder: "请选择燃脂区间",
        cardiopulmonaryPlaceholder: "请选择心肺区间",
        endurancePlaceholder: "请选择耐力区间",
        powerPlaceholder: "请选择力量区间"
      },
      attributionTag: "归属分类",
      attributionTagPlaceholder: "请选择归属分类",
      levelTwoName: "二级标签",
      tagDetail: "标签详情",
      clicks: "点击次数"
    },
    setHotTags: {
      title: "设置热门标签",
      tagName: "标签名称",
      operation: "操作"
    },
    addOrEdit: {
      addTitle: "添加一级标签",
      editTitle: "编辑一级标签",
      tagName: "标签名称",
      tagPlaceholder: "请填写标签名称",
      levelTags: "二级标签"
    },
    labelRule: {
      name: [
        {
          required: true,
          message: "请输入标签名称",
          trigger: "blur"
        }
      ]
    },
    shelvesOperation: {
      msg: "确定上架所选标签吗？",
      success: "已上架",
      cancelMsg: "已取消",
      prompt: "上架提示"
    },
    soldOutOperation: {
      msg: "确定下架所选标签吗？",
      success: "已下架",
      cancelMsg: "已取消",
      prompt: "下架提示"
    }
  },
  userlist: {
    id: "ID",
    idPlaceholder: "请输入ID",
    nickName: "昵称",
    nickNamePlaceholder: "请输入昵称",
    phone: "手机号",
    phonePlaceholder: "请输入手机号",
    email: "邮箱",
    emailPlaceholder: "请输入邮箱",
    userTag: "用户标签",
    userTagNamePlaceholder: "请输入用户标签",
    sex: "性别",
    sexPlaceholder: "请选择性别",
    userGroup: "用户群",
    userGroupPlaceholder: "请选择用户群",
    addTag: "添加标签",
    addTagsPrompt: {
      title: "提示",
      content: "确认为所选用户添加标签？",
      noUser: "请先勾选择用户"
    },
    vipTypeMap: {
      4: "普通用户",
      8: "VIP用户"
    },
    noVip: "未开通",
    headerfields: {
      id: "ID",
      header: "头像",
      nickName: "昵称",
      phone: "手机号",
      email: "邮箱",
      sex: "性别",
      age: "年龄",
      vipType: "类型",
      vipDeadline: "vip截至",
      registrationTime: "注册时间",
      groundnuts: "野豆数",
      registrationSource: "注册来源",
      operation: "操作",
      userDetails: "用户详情"
    },
    addTagDiaDlog: {
      title: "添加标签",
      addtagName: "添加标签",
      addtagPlaceholder: "请选择标签",
      tagsRule: {
        selectedTag: [
          {
            required: true,
            message: "请输入选择标签",
            trigger: "blur"
          }
        ]
      }
    }
  },
  userGroup: [
    { value: "", text: "全部" },
    { value: 8, text: "VIP" },
    { value: 4, text: "普通" }
  ],
  userdetail: {
    baseObj: {
      title: "基本信息",
      userTag: "用户标签",
      addtagName: "添加标签",
      tagName: "用户标签",
      userId: "用户ID",
      userType: "用户类型",
      setToCoach: "设为教练",
      vip: "VIP",
      phone: "手机号",
      email: "邮箱",
      sex: "性别",
      addtagPlaceholder: "请选择标签",
      tagsRule: {
        selectedTag: [
          {
            required: true,
            message: "请输入选择标签",
            trigger: "blur"
          }
        ]
      }
    },
    userAssetsObj: {
      title: "用户资产",
      groundnuts: "野豆数",
      groundnutsUnit: "个",
      detail: "详情",
      hardwareDevices: "硬件设备",
      isBinding: "已绑定",
      unbounded: "未绑定",
      heartRateBelt: "心率带",
      coach: "野豆记录",
      exchange: "变动",
      bicycle: "单车",
      treadmill: "跑步机",
      rowingMachine: "划船机",
      watch_record_map: {
        1: "系统奖励",
        2: "打赏教练",
        3: "盲盒抽奖",
        4: "盲盒抽奖归还",
        5: "活动报名",
        6: "活动报名退还",
        7: "活动奖励",
        8: "任务奖励",
        9: "训练奖励"
      },
      watch_record_map_one: "系统奖励",
      watch_record_map_two: "系统奖励",
      watch_record_map_zero: "系统奖励"
    },
    movementDataObj: {
      title: "运动数据",
      times: "总次数",
      duration: "总时长",
      totalConsumption: "总消耗",
      totalDistance: "总路程",
      id: "课程编号",
      name: "课程名称",
      movementTime: "运动时长",
      burnCalories: "消耗卡路里",
      movementDistance: "运动路程",
      moveStart: "运动时间",
      calories: "卡路里",
      timts: "次",
      km: "公里"
    },
    bodyFlie: {
      title: "身体档案"
    }
  },
  addcoursecategory: {
    classifyName: "分类名称",
    classifyNamePlaceholder: "请填写分类名称",
    defaultValue: "预设值",
    classifyNameerr: "分类已存在",
    attrName: "属性名称",
    attrNamePlaceholder: "请填写属性名称",
    introduce: "介绍",
    introducePlaceholder: "请填写介绍",
    suitsCrowd: "适用人群",
    suitsCrowdPlaceholder: "请填写适用人群",
    effectOfCourse: "课程效果",
    effectOfCoursePlaceholder: "请选择课程效果",
    fatBurn: "Fat burning",
    cardiopulmonary: "Cardiopulmonary",
    endurance: "Endurance",
    power: "Power",
    interval: "Interval",
    fatBurnPlaceholder: "请选择燃脂区间",
    cardiopulmonaryPlaceholder: "请选择心肺区间",
    endurancePlaceholder: "请选择耐力区间",
    powerPlaceholder: "请选择力量区间",
    addTheDefaultBtn: "添加预设"
  },
  theme: {
    videoSizePrompt: "上传视频文件大小不能超过 500MB!",
    themeSearch: [
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "上架", value: 1 },
          { label: "下架", value: 0 },
          { label: "未上架", value: 2 }
        ]
      },
      { label: "id", prop: "id" },
      { label: "专题课名称", prop: "group_name" },
      {
        label: "分发语种",
        prop: "language_area",
        type: "select",
        option: [] // 服务端获取
      },
      { ////难度
        label: "难度",
        prop: "level",
        type: "select",
        option: [
        { label: "全部", value: "" },
        { label: "Y1", value: 1 },
        { label: "Y2", value: 2 },
        { label: "Y3", value: 3 },
        { label: "Y4", value: 4 },
        ]
      },
      { //2.2.6新增
        label: "运动目的",
        prop: "sports_purpose",
        type: "select",
        option: [
        { label: "全部", value: "" },
        { label: "燃脂瘦身", value: 1 },
        { label: "减压放松", value: 2 },
        { label: "提升骑行能力", value: 3 }
        ]
      },
      { //2.2.7新增
        label: "收费方式",
        prop: "group_type",
        type: "select",
        option: [
        { label: "全部", value: "" },
        { label: "VIP", value: 1 },
        { label: "免费", value: 2 },
        ]
      }
    ],
    themeList: [
      { label: "id", prop: "id", type: "normal" },
      { label: "系列名称", prop: "groupName", type: "normal" },
	    { label: "难度", prop: "level", type: "normal" },//2.2.6新增
      { label: "状态", prop: "shelf", type: "normal" },
	  { label: "训练周数", prop: "recommendedTrainingWeeks", type: "normal",width: "80px" },//2.2.6新增
      { label: "课程数", prop: "courseNum", type: "normal" ,width: "80px" },
	  { label: "运动目的", prop: "courseGroupSportsPurposeList", type: "normal",width: "156px",align:"center" },//2.2.6新增
      { label: "分发语言区", prop: "languageAreas", type: "tag" },
	  { label: "收费方式", prop: "groupType", type: "normal" },//2.2.7新增
      { label: "创建时间", prop: "createTime", type: "normal", width: "180px" },
      {
        label: "上架时间",
        prop: "shelfTime",
        type: "normal",
        width: "180px"
      },
      {
        label: "操作",
        prop: "action",
        type: "button",
        width: "360px",
        button: [
          { label: "配置课程", type: "primary" },
          { label: "编辑", limit: "shelf", limitValue: "上架" },
          { label: "查看", type: "primary" },
          { label: "删除", limit: "shelf", limitValue: "上架" }
        ],
        method: ["_config", "_edit", "_check", "_delete"]
      }
    ],
    searchData: [
      {
        label: "ID",
        prop: "show_id"
      },
      { label: "课程名称", prop: "course_name" },
      // { label: "教练", prop: "coach_name" },
      // {
      //   label: "课程大类",
      //   prop: "category_id",
      //   type: "select",
      //   option: []
      // },
      {
        label: "时长",
        prop: "duration",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "15分钟", value: 15 * 60 },
          { label: "20分钟", value: 20 * 60 },
          { label: "30分钟", value: 30 * 60 },
          { label: "45分钟", value: 45 * 60 },
          { label: "60分钟", value: 60 * 60 }
        ]
      },
      {
        label: "难度",
        prop: "level_id",
        type: "select",
        option: []
      },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "已下架", value: "0" },
          { label: "上架", value: "1" },
          { label: "未上架", value: "2" }
        ]
      }
    ],
    searchData_2: [
      { label: "课程名称", prop: "course_name" },
      {
        label: "课程大类",
        prop: "category_id",
        type: "select",
        option: []
      },
      {
        label: "状态",
        prop: "shelf",
        type: "select",
        option: [
          { label: "全部", value: "" },
          { label: "已下架", value: 0 },
          { label: "上架", value: 1 },
          { label: "未上架", value: 2 }
        ]
      }
    ],
    levelOtion: [
      { label: "初级", value: 1 },
      { label: "中级", value: 2 },
      { label: "高级", value: 3 }
    ],
    tableColumn: [
      { label: "", prop: "courseSeq", type: "normal" },
      { label: "课程名称", prop: "courseName", type: "normal" },
      { label: "课程难度", prop: "levelName", type: "normal" },
      { label: "时长", prop: "duration", type: "normal" }
    ],
    courseUsedTableColumn: [
      { label: "id", prop: "id", type: "normal" },
      { label: "课程名称", prop: "courseName", type: "normal" },
      { label: "课程难度", prop: "courseLevel", type: "normal" },
      { label: "时长", prop: "courseDuration", type: "normal" },
      { label: "课程开始时间", prop: "beginTime", type: "normal" }
    ],
    tableColumn_2: [
      { label: "课程ID", prop: "courseId", type: "normal" },
      { label: "课程名称", prop: "courseName", type: "normal" },
      { label: "课程难度", prop: "levelName", type: "normal" },
      { label: "时长", prop: "duration", type: "normal" },
	  { label: "位置", prop: "positionText", type: "normal" },
      {
        label: "操作",
        prop: "action",
        type: "button",
        button: [
		  { label: "位置设置", type: "primary" },
          { label: "查看", type: "primary" },
          { label: "删除", limit: "shelf", limitValue: "上架" }
        ],
        method: ["_locationView", "_check", "_delete"]
      }
    ],
    first: "序号",
    festival: "节",
    difficulty: "难度",
    minute: "分钟",
    courseSort: "课程排序",
    editTopics: "编辑计划",
    addTopics: "添加计划",
    creat: "创建",
    addCourseListUsed: {
      courseCategories: "课程大类",
      enterCategoryName: "输入分类名称",
      selectStatus: "请选择状态",
      chargeMethod: "收费方式",
      free: "免费",
      vip: "vip",
      distributionArea: "分发区",
      grade: "等级",
      projectType: "专题类型",
      zeroFoundationProject: "零基础专题课",
      topicCover: "图片",
      topicCoverError: "请选择图片",
	  recommendedTrainingWeeks:"训练周数", //2.2.3新增
	  recommendedTrainingWeeksError:"请输入训练周数", //2.2.3新增
      decimalProhibited:"禁止输入小数以及负数",//2.2.3新增
	  integerGreater:"数字范围1-4",//2.2.3新增
      topicCourseName: "计划名称",
      inputTopicCourse: "请输入系列名称",
      topicIntroduction: "分发语言区",
      inputContent: "请输入内容",
      suitablePeople: "适合人群",
      precautions: "计划简介",
      precautionsPlaceholder: "请输入简介",
      videoIntroduction: "视频简介",
      videoContent: "视频介绍",
      videoCover: "视频封面",
      participatePeople: "注水参与人数",
      participatePeoplePlaceholder: "请输入注水参与人数",
      browserCompatible: "您的浏览器不支持 HTML5 video 标签。",
      needInstrument: "所需器械",
      coach: "教练",
      courseDetails: "课程详情"
    },
    courseConfig: {
      batchDelete: "批量删除",
      creatCourse: "创建课程",
      addCourse: "添加课程",
      sort: "排序",
      formRecordChoose: "从录播课中选择",
      formPreviousChoose: "从往期课中选择",
      formRealChoose: "从实景骑行选择",
      shelf2: "未上架",
      shelf1: "上架",
      shelf0: "下架",
      shelf: "无状态",
      shelf2New: "未上架",
      shelf1New: "上架",
      shelf0New: "下架",
      shelfNew: "无状态",
      freeTrial: "限时免费",
      specialCourses: "专题课程",
      abdominalWheel: "健腹轮",
      noCategoryFound: "未找到分类",
      allLang: "全部"
    },
    confirm: {
      title: "提示",
      isDelete: "是否删除该课程?",
      isDeleteTopics: "是否删除该专题课?",
      isDeleteSelect: "确定删除所选课程?"
    },
    message: {
      cancelDelete: "已取消删除",
      inputUserName: "请输入用户名"
    }
  },
  usermanager: {
    inputSelectTag: "请输入选择标签",
    inputTagName: "请输入标签名称"
  },
  livecourselist: {
    pushTitle: "推流地址",
    languagePlaceholder: "请选择语言",
    id: "ID",
    idPlaceholder: "请输入ID",
    tagName: "标签",
    courseStartTime: "课程开始时间",
    tagNamePlaceholder: "请输入标签名称",
    courseName: "课程名称",
    coursePlaceholder: "请输入课程名称",
    coachaName: "教练名称",
    coachNamePlaceholder: "请输入教练名称",
    categoryName: "课程大类",
    categoryNamePlacholder: "请选择课程大类",
    coachDifficulty: "难度",
    difficultyPlacholder: "请选择难度",
    timing: "时长",
    timingPlacholder: "请选择时长",
    status: "状态",
    statusPlacholder: "请选择状态",
    addTag: "添加标签",
    delectedSection: "已删除课程",
    distributionLanguages: "分发语种",
    fileName: "课程数据表.xls",
    addCourseBtn: "新增",
    charge: "收费方式",
    chargePlacholder: "请选择收费方式",
    lang: "分发语种",
    langPlacholder: "请选择分发语种",
    headerfields: {
      id: "ID",
      courseName: "课程名称",
      categoryName: "分类名称",
      distributionLanguages: "分发语种",
      language: "语言",
      copyCourse: "复制",
      coachDifficulty: "课程难度",
      timing: "时长",
      startTime: "开始时间",
      status: "状态",
      coachName: "教练名称",
      operation: "操作",
      addTag: "添加标签",
      langArea: "分发语种",
      lang: "语言",
      showTag: "展示标签",
	  sourceName: "来源" // 2.2.3 新增 source字段
    },
    pusbFields: {
      ausAddress: "澳大利亚-推流地址",
      serbiaAddress: "塞尔维亚-推流地址"
    },
    shelvesOperation: {
      msg: "确定上架所选直播课程吗？",
      success: "已上架",
      cancelMsg: "已取消",
      prompt: "上架提示"
    },
    checkTheCourse: "请先勾选课程",
    soldOutOperation: {
      msg: "确定下架所选直播课程吗？",
      success: "已下架",
      cancelMsg: "已取消",
      prompt: "下架提示"
    },
    previousCourseShelvesOperation: {
      msg: "确定上架所选往期课程吗？",
      success: "已上架",
      cancelMsg: "已取消",
      prompt: "上架提示"
    },
    previousCourseSoldOutOperation: {
      msg: "确定下架所选往期课程吗？",
      success: "已下架",
      cancelMsg: "已取消",
      prompt: "下架提示"
    },
    restoreOperation: {
      msg: "确定恢复所选直播课程吗？",
      success: "已恢复",
      cancelMsg: "已取消",
      prompt: "恢复提示"
    },
    addCourse: {
      title: "添加标签",
      placeholder: "请选择标签"
    },
    prompt: {
      title: "提示",
      content: "确认删除所选课程吗？",
      success: "已成功删除课程",
      error: "课程删除失败"
    },
    showTagObj: {
      title: "展示标签",
      coursrName: "课程名称",
      classifyName: "分类",
      tagName: "标签名称",
      operation: "操作",
      comfirm: "确认",
      cancel: "取消"
    }
  },
  coachDifficulty: [
    { value: "", text: "全部" },
    { value: "Y1", text: "Y1" },
    { value: "Y2", text: "Y2" },
    { value: "Y3", text: "Y3" },
    { value: "Y4", text: "Y4" },
    { value: "Y5", text: "Y5" },
    { value: "Y6", text: "Y6" }
  ],
  creatcoachDifficulty: [
    { value: "Y1", text: "Y1" },
    { value: "Y2", text: "Y2" },
    { value: "Y3", text: "Y3" },
    { value: "Y4", text: "Y4" },
    { value: "Y5", text: "Y5" },
    { value: "Y6", text: "Y6" }
  ],
  coachInterval: [
    { value: "Y1.1", text: "Y1.1" },
    { value: "Y1.2", text: "Y1.2" },
    { value: "Y1.3", text: "Y1.3" },
    { value: "Y1.4", text: "Y1.4" },
    { value: "Y1.5", text: "Y1.5" },
    { value: "Y1.6", text: "Y1.6" }
  ],
  coachDuration: [
    { value: "", text: "全部" },
    { value: "5", text: "5分钟" },
    { value: "10", text: "10分钟" },
    { value: "15", text: "15分钟" },
    { value: "20", text: "20分钟" },
    { value: "30", text: "30分钟" },
    { value: "45", text: "45分钟" },
    { value: "60", text: "60分钟" }
  ],
  creatCoachDuration: [
    { value: "5", text: "5分钟" },
    { value: "10", text: "10分钟" },
    { value: "15", text: "15分钟" },
    { value: "20", text: "20分钟" },
    { value: "30", text: "30分钟" },
    { value: "45", text: "45分钟" },
    { value: "60", text: "60分钟" }
  ],
  createcourse: {
    btnAdd: "添加",
    hint:
      "TIPS:直播课程结束后，将云平台获取本节课的直播转录视频文件地址黏贴到此字段并保存，才能实现直播转往期课",
    sizeHint:
      "TIPS:1、填写数值后，前端的课程预约人数=真实预约人数+注水参与人数。2、转码完成的往期课，则前端展示的参与人数=真实参与人数+注水参与人数",
    transVideoUrl: "转码视频文件地址",
    transVideoUrlPlaceHolder: "请输入直播转码视频文件地址",
    endTimeErr: "往期课的结束时间不能是未来时间",
    endTimeString: "课程结束时间",
    language: "语言",
    languagePlaceholder: "请选择语言",
    pastCover: "往期课课程封面",
    pastCoverSize: "建议尺寸：1029*579",
    pastCoverRequire: "请选择往期课课程封面",
    pastDetailCover: "往期课课程详情图",
    pastDetailCoverRequire: "请选择往期课课程详情图",
    pastDetailCoverSize: " 建议尺寸：1125*786",
    courseName: "课程名称",
    courseLessonPlans: "课程教案",
    coursePlaceholder: "请填写课程名称",
    videoUrlPlaceholder: "请填写转码视频地址",
    distributionLanguages: "分发语种",
    distributionLanguagesRequire: "请勾选分发语种",
    type: "课程大类",
    typeRequire: "请选择课程大类",
    courseTarget: "课程目标",
    liveRoom: "直播间",
    distributeLang: "分发语种",
    courseTargetRequire: "请选择课程目标",
    liveRoomRequire: "请选择直播间",
    distributeLangRequire: "请选择分发语种",
    courseClass: "课程分类",
    courseClassRequire: "请选择课程分类",
	sourceName:"来源", //2.2.3新增 source字段
	sourceNameRequire:"请选择来源",//2.2.3新增 source字段
	suitableLevel1:"难度",//
	suitableLevel:"适合水平",//2.2.6新增适合水平
	suitableLevelRequire1:"请选择适合难度",
	suitableLevelRequire:"请选择适合水平",//2.2.6新增
	sportsPurpose:"运动目的",//2.2.6新增运动目的
	sportsPurposeRequire:"请选择运动目的",//2.2.6新增
	weeklyCourse:"每周课程数",//2.2.6新增
	weeklyCourseRequire:"请输入每周课程数量",//2.2.6新增
	weeklyLimit:"数字范围: 2-7",//2.2.6新增
	suitableLevelArr1:{
		1: "Y1",
		2: "Y2",
		3: "Y3" ,
		4: "Y4" ,
	},
	suitableLevelArr:{
		1: "新手入门",
		2: "进阶提升",
		3: "挑战自我" 
	},//2.2.6新增适合水平选择数组
	suitableLevelArrSearch:[
		{value:'',text:'全部'},
		{value:1,text:'新手入门'},
		{value:2,text:'进阶提升'},
		{value:3,text:'挑战自我'},
	],//2.2.6新增适合水平筛选数组
	sportsPurposeArr:{
		1: "燃脂瘦身",
		2: "减压放松",
		3: "提升骑行能力"
	},//2.2.6新增运动目的选择数组
	sportsPurposeArrSearch:[
		{value:'',text:'全部'},
		{value:1,text:'燃脂瘦身'},
		{value:2,text:'减压放松'},
		{value:3,text:'提升骑行能力'},
	],//2.2.6新增运动目的筛选数组
    startTypeAttr: "是否启用预设值",
    typeAttr: "分类属性",
    attrTrue: "是",
    attrFalse: "否",
    courseIntroduce: "课程说明",
    courseIntroducePlaceholder: "请填写课程说明",
    suitsCrowd: "适合人群",
    suitsCrowdPlaceholder: "请填写适合人群",
    smartInstrument: "智能器械",
    smartInstrumentRequire: "请选择智能器械",
    nonIntelligentInstrument: "非智能器械",
    nonIntelligentInstrumentRequire: "请选择器械",
    noequipment: "无需器械",
    delectTitle: "提示",
    delectsubSection: "此操作将删除小节，是否继续？",
    nodelect: "此数据不可删除",
    delectSection: "此操作将删除段落，是否继续？",
    delectTrain: "此操作将删除训练，是否继续？",
    scoreSelect: [{ value: 1, label: "是" }, { value: 0, label: "否" }],
    dragCoefficient: '坡度',
    cycleIndexName: {
      2: "踏频(指标)",
      3: "浆频(指标)",
      4: "速度(指标)" // 1: '心率(指标)'
    },
    rangeValueHeads: {
      "0": {
        maxTargetHeartRate: "最高目标功率（指标）",
        minTargetHeartRate: "最低目标功率（指标）"
      },
      "1": {
        maxTargetHeartRate: "最高目标心率（指标）",
        minTargetHeartRate: "最低目标心率（指标）"
      },
      "5": {
        maxTargetHeartRate: "最高目标心率（指标）",
        minTargetHeartRate: "最低目标心率（指标）"
      },
      "6": {
        maxTargetHeartRate: "最高目标心率（指标）",
        minTargetHeartRate: "最低目标心率（指标）"
      }
    },
    fatremark:
      "The higher the index, the longer the stay in the fat burning zone",
    cardiopulmonaryremark:
      "The higher the index, the more obvious the intensity changes in the course",
    effectCourse: {
      heartMinMsg: "最低目标心率应大于",
      powerMinMsg: "最低目标功率应大于",
      heartHightAndLow: "最低目标心率不得高于最高目标心率",
      powerHightAndLow: "最低目标功率不得高于最高目标功率",
      title: "课程效果",
      fatBurn: "Fat burning",
      cardiopulmonary: "Cardiopulmonary",
      endurance: "Endurance",
      power: "Power",
      // interval: 'Interval',
      requiredEquipment: "所需器械",
      fatBurnPlaceholder: "请选择燃脂区间",
      fatremarkPlaceholder: "课程说明",
      cardiopulmonaryPlaceholder: "请选择心肺区间",
      endurancePlaceholder: "请选择耐力区间",
      powerPlaceholder: "请选择力量区间",
      trainingMode: "训练模式",
      trainingModePlaceholder: "请选择训练模式",
      distributionArea: "分发区",
      freeExperience: "限时免费",
      rate: "功率",
      heartRate: "心率",
      steppedFrequency: "踏频",
      speedTndicator: "速度",
      speed: '速度km/h',
      plasmaFrequency: "浆频",
      expect: "预计",
      calories: "卡路里",
      expectCalories: "请填写预计卡路里值",
      intCalories: "卡路里为正整数",
      courseInstructor: "课程教练",
      courseInstructorPlaceholder: "请选择课程教练",
      courseImage: "直播课图片",
      courseImage0: "直播页封面图",
      courseImage1: "直播课程详情图",
      courseImage2: "课程详情顶部图",
      coursePicPlaceholder: "请上传往期课程封面",
      courseImagePlaceholder: "请上传直播课图片",
      courseCoverImagePlaceholder: "请上传直播课程封面",
      courseInfoImagePlaceholder: "请上传直播课程详情图",
      videoCourseImage: "往期课课程封面",
      videoCourseImageIntro: "往期课课程详情图",
      recordCourseImage: "录播课图片",
      videoCourseImagePlaceholder: "请上传往期课课程封面",
      videoCourseImageIntroPlaceholder: "请上传往期课课程详情图",
      videoCourseImageIntroPlaceholder1: "请上传课程详情图",
      recordCourseImagePlaceholder: "请上传录播课图片",
      wayToCharge: "收费方式",
      wayToChargePlaceholder: "请选择收费方式",
      free: "免费",
      free2: "免费",
      vip: "VIP",
      participation: "注水参与人数",
      participationPlaceholder: "请输入注水参与人数",
      thumbUpNumber: "点赞人数",
      difficultyOfCourse: "课程难度",
      difficultyRequire: "请选择课程难度",
      intervalRequire: "请选择区间",
      interval: "Interval",
      intervalPlaceholder: "请选择区间",
      startTime: "开始时间",
      startTimePlaceholder: "请选择开始时间",
      courseDuration: "课程时长",
      courseDurationPlaceholder: "请选择课程时长",
      courseTarget: "课程目标",
      subSection: "小节",
      subSectionPlaceholder: "请选择小节",
      section: "动作组",
      copySectionTips: "(复制当前组)",
      train: "动作",
      music: "音乐",
      musicPlaceholder: "请填写音乐",
      RPM: "RPM",
      RPMPlaceholder: "请填写RPM",
      totalTeachingTime: "总时长",
      totalTime: "小节时长",
      add: "添加",
      totalTimePlaceholder: "请填写总时长",
      daration: "时长(秒)",
      darationPlaceholder: "请填写该训练时长",
      actionName: "动作名称",
      actionNamePlaceholder: "请选择动作名称",
      targetPower: "踏频（指标）",
      pace: "步频",
      stepRatePlaceholder: "请输入步频",
      isScore: "是否评分",
      isScoreRequire: "请选择是否评分",
      isBarrage: "是否弹幕",
      teachingLessonPlans: "教学教案",
      allPushTime: "总推次",
      allsectionTime: "推次",
      fPush: "正推",
      lPush: "左推",
      rPush: "右推",
      fPushPlaceholder: "请输入正推",
      lPushPlaceholder: "请输入左推",
      rPushPlaceholder: "请输入右推",
      isBarrageRequire: "请选择是否弹幕",
      targetPowerPlaceholder: "请填写",
      targetPowerMaxPlaceholder: "请填写目标功率最大值",
      SteppedFrequency: "踏频",
      SteppedFrequencyPlaceholder: "请填写该训练踏频",
      resistance: "阻力",
      resistancePlaceholder: "请填写该训练阻力",
      color: "颜色",
      colorPlaceholder: "请填写该训练目标颜色",
      videoInfo: "视频信息",
      transcodingVideoAddress: "转码视频地址",
      transcodingVideoPlaceholder: "请填写转码视频地址",
      orgVideoLink: "录播高清地址",
      orgVideoLinkPlaceholder: "请填写录播高清地址",
      clearLink: "标清链接",
      clearLinkPlaceholder: "请填写标清链接",
      highDefinitionLink: "高清链接",
      highDefinitionLinkPlaceholder: "请填写高清链接",
      liveVideoHigh: "直播高清",
      liveVideoStandard: "直播标清",
      originalVideoHigh: "录播高清",
      originalVideoStandard: "录播标清",
      liveVideoHighPlaceholder: "请输入直播高清",
      liveVideoStandardPlaceholder: "请输入直播标清",
      originalVideoHighPlaceholder: "请输入录播高清",
      originalVideoStandardPlaceholder: "请输入录播标清",
      addressSupportChinese: "视频地址不支持中文",
      historyVideoDelayTimeSeconds: "历史视频延时时间秒",
      delatSecondPlacholder: "请填写历史视频延时时间",
      delaySecondIntroduce:
        "0:正常；<0:直播视频过早录制的秒数,客户端需要跳过前此值;>0：课程过晚录制的时间，客户端的运动数据需要跳过的秒数。",
      liveRankingParticipants: "直播排名参与人数",
      liveParticipantsPlacholder: "请输入直播排名参与人数",
      remark: "课程备注",
      effectivePowerValue: "请输入有效指标值"
    }
  },
  viewCourse: {
    courseName: "课程名称",
    courseDuration: "课程时间",
    peoples: "参与人数",
    reservationNumber: "预约人数",
    coachScore: "教练评分",
    theSeedingRate: "完播率",
    departureRate30: "离开率（0-30%）",
    departureRate50: "离开率（30%-50%）",
    departureRate80: "离开率（50%-80%）",
    averageClassTime: "平均上课时长",
    courseDetail: "课程详情",
    courseData: "课程数据"
  },
  reviewedlist: {
    setHotCourse: "设置热门课程",
    courseName: "课程名称",
    operation: "操作",
    cancelHot: "取消热门"
  },
  classtaglist: {
    setHotTypeMsg: "请先勾选分类",
    setHotTagMsg: "请先勾选标签",
    shelvesOperation: {
      msg: "确定上架所选分类吗？",
      success: "已上架",
      cancelMsg: "已取消",
      prompt: "上架提示"
    },
    soldOutOperation: {
      msg: "确定下架所选分类吗？",
      success: "已下架",
      cancelMsg: "已取消",
      prompt: "下架提示"
    },
    deleteTag: {
      prompt: "提示",
      msg: "确定删除该标签吗？",
      success: "已删除",
      cancelMsg: "已取消"
    }
  },
  typeofcourse: {
    typeName: "类型名称",
    typeIcon: "类型图标",
    typeIntroduce: "类型介绍",
    suitsTheCrowd: "适合人群",
    typeNameOpen: "是否启用课程名称",
    status: "状态",
    isHot: "是否热门",
    operation: "操作",
    edit: "编辑",
    new: "新增",
    reload: "刷新",
    newTypeCourse: "新增课程类型",
    editTypeCourse: "编辑课程类型",
    saveBtn: "保存内容",
    cencel: "取消",
    addFORM: {
      typeName: "类型名称",
      introduction: "类型介绍",
      for_the_crowd: "适合人群",
      recommend: "课程名称启用状态",
      is_hot: "热门",
      icon_url: "图标",
      upImg: "上传图片",
      status: "状态",
      isHot: "是",
      notHot: "否",
      recommendenable: "启用名称",
      recommenddisable: "禁用名称",
      statusenable: "启用状态",
      statusdisable: "禁用状态"
    },
    typeCourseRules: {
      name: [{ required: true, message: "请输入类型名称", trigger: "blur" }],
      introduction: [
        { required: true, message: "请输入课程介绍", trigger: "blur" }
      ],
      for_the_crowd: [
        { required: true, message: "请输入适合人群", trigger: "blur" }
      ],
      recommend: [
        {
          required: true,
          message: "请选择课程名称启用状态",
          trigger: "change"
        }
      ],
      is_hot: [
        { required: true, message: "请选择是否热门", trigger: "change" }
      ],
      icon_url: [
        { required: true, message: "请上传图标图片", trigger: "blur" }
      ],
      status: [{ required: true, message: "请启用选择状态", trigger: "blur" }]
    }
  },
  coursemanagementlist: {
    courseDate: "课程日期",
    courseTag: "课程标签",
    courseTagPlacholder: "请选择课程标签",
    today: "今天",
    model: {
      0: "功率训练",
      1: "心率训练"
    },
    headerfields: {
      courseName: "课程名称",
      couchName: "教练名称",
      startTime: "课程开始时间",
      endTime: "课程结束时间",
      model: "模式",
      status: "课程状态",
      operation: "操作",
      edit: "编辑",
      phase: "阶段"
    }
  },
  creatOldCourse: {
    targetCalories: "目标卡路里",
    targetCaloriesPlacholder: "请填写目标卡路里",
    intCalories: "请输入有效目标卡路里",
    calories: "卡路里",
    isfree: "是否免费",
    startTime: "课程开始时间",
    startTimePlaceholder: "请选择课程开始时间",
    courseType: "课程类型",
    courseTypePlacholder: "请选择课程类型",
    coursePic: "课程封面",
    videoPic: "视频封面",
    uploadPic: "上传图片",
    liveStandardVideo: "直播标清视频地址",
    livePlaceholder: "请输入直播标清视频地址",
    liveHdVideoAddress: "直播高清视频地址",
    liveHdPlaceholder: "请输入直播高清地址",
    vodMarkedVideoAddress: "點播标清视频地址",
    vodMarkedPlaceholder: "请输入點播标清视频地址",
    hdVideoOnDemandAddress: "點播高清视频地址",
    hdVideoOnDemandPlaceholder: "请输入點播高清视频地址",
    courseNotes: "课程备注",
    courseNotesPlacholder: "请输入内容",
    coursedifferent: "课程难度",
    coursesCover: "课程封面",
    coursesCoverPlaceholder: "请上传课程封面",
    videoCover: "视频封面",
    videoCoverPlaceholder: "请上传视频封面",
    coursedifferentPlacholder: "请选择课程难度",
    submitImmediately: "立即提交",
    content: "确认提交课程信息是否正确？",
    title: "提示"
  },
  coursePhase: {
    content: "确认保存阶段",
    title: "提示"
  },
  editclassify: {
    content: "确认移除该预设值",
    title: "提示",
    remove: "已移除"
  },
  homepagebanner: {
		recommendationReason:"推荐理由",//2.2.6新增
		reasonPlaceholder:"请输入推荐理由",//2.2.6新增
		distributionTheater:"分发大区",//2.2.6新增
		distributionTheaterPl:"请至少选择一个分发大区",//2.2.6新增
		toastTips:"当前配置课程数不等于设定的总课程数，当前课程数=【训练周数】* 每周课程数",//2.2.6新增
		open:"开启",//2.2.6新增
		close:"关闭",//2.2.6新增
    name: "名称",
    name2: "昵称",
    namePlaceholder: "请输入名称",
    doNotExceed: "请不要超过40个字",
    uploadImage: "请上传图片",
    homePageTap: "首页tab",
	  homeRecommend: "首页-顶部",//2.2.6新增
    livePageTap: "直播tab",
    myTab: "我的tab",
    startPage: "启动页",
    loginPopup: "登录弹窗",
    floatButton: "浮动按钮",
    yeDouCenter: "野豆中心",
    exerciseRecord: "运动记录",
    padpopwindow: "屏幕端弹窗",
    courseGuanggao: "课中广告位",
    status: "状态",
    homeLocation: "位置",
    homeLocationPlacholder: "请选择位置",
    type: "banner类型",
    statusPlacholder: "请选择状态",
    homesort: "排序",
    wait: "等",
    tagUsers: "个标签用户",
    visible: "可见",
    invisible: "不可见",
    popUpsNumber: "弹窗次数",
    each: "每次",
    once: "一次",
    all: "全部",
    buttonImage: "按钮图片",
    countDown: "倒计时",
    enterCountDown: "请输入倒计时",
    mustNumber: "倒计时必须为数字值",
    countDownIsTen: "倒计时最大为10秒",
    countDownIsZero: "倒计时最小为0秒",
    visibleState: "可见状态",
    second: "秒",
    location: "所在位置",
    home: "首页",
    recommendSize: "建议尺寸",
    recommendAdd: 'and equal scale size',
    headerfields: {
      bannername: "banner名称",
      bannerImg: "图片",
      type: "banner类型",
      skiplinks: "链接",
      status: "状态",
	  contentType:"内容类型",
	  contentId:"内容ID",
	  recommendReason:"推荐理由",
      operation: "操作",
      whereTab: "所在tab",
      liveBroadcast: "直播",
      location: "位置",
      find: "发现",
      mine: "我的"
    },
    banneroperationmsg: "请勾选选项",
    homesorttable: {
      title: "首页banner排序",
      bannername: "banner名称",
      bannerImg: "banner图片",
      operation: "操作",
      removeaction: "移除",
      serialNumber: "序号",
      sort: "排序"
    },
    shelvesOperation: {
      msg: "确定上架所选banner吗？",
      success: "已上架",
      cancelMsg: "已取消",
      prompt: "上架提示"
    },
    soldOutOperation: {
      msg: "确定下架所选banner吗？",
      success: "已下架",
      cancelMsg: "已取消",
      prompt: "下架提示"
    },
    deleteBannerMsg: "确定删除当前banner吗？",
    deleteBannerSuccess: "已成功删除banner",
    deleteBannerErr: "删除失败",
    cancelMsg: "已取消",
    prompt: "提示"
  },
  instrumentTutorials: {
    videoLink: "视频链接",
    inputVideoLink: "视频链接不能为空",
    placeholderVideoLink: "请输入视频链接",
    placeholderTitle: "请输入标题",
    placeholderSubTitle: "请输入副标题",
    videoCourseSort: "视频教程排序",
    subTitle: "副标题",
    inputTitle: "标题不能为空",
    inputSubTitle: "副标题不能为空",
    deviceType: "器械类型",
    selectDeviceType: "请选择器械类型"
  },
  creatbanner: {
    name: "名称",
    namePlaceholder: "请填写banner名称",
    bannerImg: "图片",
    bannerimgPlaceholder: "请选择图片",
    type: "banner类型",
    typePlaceholder: "请选择banner类型",
    skiplinks: "跳转链接",
    skipTypeOrgPlaceholder: "请填写原生链接",
    skiplinksPlaceholder: "请填写跳转链接",
    whethertheshelf: "是否上架",
    homebanner: "首页banner",
    coursebanner: "课程页banner",
    true: "是",
    false: "否",
    skipTypeOrg: "原生链接",
    skipTypeLink: "跳转链接",
    linkType: "链接类型",
    linkAddress: "链接地址",
    formSearch: {
      name: [
        {
          required: true,
          message: "请输入banner名称",
          trigger: "blur"
        }
      ],
      bannerImg: [
        {
          required: true,
          message: "请选择图片",
          trigger: "blur"
        }
      ]
      // type: [
      //   {
      //     required: true,
      //     message: '请选择banner类型',
      //     trigger: 'blur'
      //   }
      // ],
      // skiplinks: [
      //   {
      //     required: true,
      //     message: '请填写跳转链接',
      //     trigger: 'blur'
      //   }
      // ],
      // status: [
      //   {
      //     required: true,
      //     message: '请选择是否上架',
      //     trigger: 'blur'
      //   }
      // ]
    }
  },
  bannerdetail: {
    bannerclicks: "banner点击数据"
  },
  articlelist: {
    title: "标题",
    titlePlaceholder: "请输入标题名称",
    status: "是否置顶",
    statusPlacholder: "请选择状态",
    select: "精选",
    shelves: "上架",
    soldOut: "下架",
    headerfields: {
      articleticle: "标题",
      articleImg: "图片",
      articleaddresses: "文章地址",
      createtime: "创建时间",
      status: "是否置顶",
      id: "id",
      operation: "操作"
    },
    articleoperationmsg: "请选择文章",
    articlesorttable: {
      setselectedarticles: "设置精选文章",
      articlename: "文章名称",
      operation: "操作",
      removeaction: "移除精选",
      setselect: "设置精选",
      serialNumber: "序号",
      sort: "排序"
    },
    shelvesOperation: {
      msg: "确定上架所选文章吗？",
      success: "已上架",
      cancelMsg: "已取消",
      prompt: "上架提示"
    },
    soldOutOperation: {
      msg: "确定下架所选文章吗？",
      success: "已下架",
      cancelMsg: "已取消",
      prompt: "下架提示"
    },
    deleteBannerMsg: "确定删除当前文章吗？",
    deleteBannerSuccess: "已成功删除该文章",
    deleteBannerErr: "删除失败",
    cancelMsg: "已取消",
    prompt: "提示"
  },
  creatarticle: {
    title: "标题",
    titlePlaceholder: "请填写文章标题",
    articleImg: "图片",
    articleimgPlaceholder: "请选择图片",
    contentUrl: "文章地址",
    contentUrlPlaceholder: "请填写文章地址",
    defaultreadpeople: "默认阅读人数",
    defaultPlaceholder: "请填写默认阅读人数",
    whethertheshelf: "是否置顶",
    true: "是",
    false: "否",
    formSearch: {
      title: [
        {
          required: true,
          message: "请填写文章标题",
          trigger: "blur"
        }
      ],
      coverPic: [
        {
          required: true,
          message: "请选择图片",
          trigger: "blur"
        }
      ],
      contentUrl: [
        {
          required: true,
          message: "请填写文章地址",
          trigger: "blur"
        }
      ],
      published: [
        {
          required: true,
          message: "请选择是否置顶",
          trigger: "blur"
        }
      ]
    }
  },
  articledetail: {
    articledata: "文章数据",
    browsethenumber: "浏览人数"
  },
  intPlaceholder: "请输入正整数",
  stickOptions: [
    {
      status: "",
      text: "全部"
    },

    {
      status: 1,
      text: "是"
    },
    {
      status: 0,
      text: "否"
    }
  ],
  bannertypeOptions: [
    {
      status: "",
      text: "全部"
    },

    {
      status: 1,
      text: "首页banner"
    },
    {
      status: 2,
      text: "课程页banner"
    }
  ],
  stickStatusMap: {
    0: "否",
    1: "是"
  },
  bannerTypeMap: {
    1: "首页banner",
    2: "课程页banner"
  },
  bannerOptions: [
    {
      status: "",
      text: "全部"
    },

    {
      status: 1,
      text: "上架"
    },
    {
      status: 0,
      text: "下架"
    }
  ],
  oldCourseMap: {
    0: "直播课上架",
    3: "录播课上架",
    4: "下架"
  },
  oldCourseOptions: [
    {
      status: "0",
      text: "直播"
    },

    {
      status: "3",
      text: "录播"
    },
    {
      status: "4",
      text: "下架"
    }
  ],
  oldcourseOperation: "请勾选选项",
  oldshelvesOperation: {
    msg: "确定上架所选吗？",
    success: "已上架",
    cancelMsg: "已取消",
    prompt: "上架提示"
  },
  oldsoldOutOperation: {
    msg: "确定下架所选吗？",
    success: "已下架",
    cancelMsg: "已取消",
    prompt: "下架提示"
  },
  oldCoursestatus: "课程状态",
  oldCoursestatusPlaceholder: "请选择课程状态",
  shareText: {
    addModel: "添加分享文案",
    editModel: "编辑分享文案",
    addImageModel: "添加分享",
    editImageModel: "编辑分享图",
    view: "查看",
    allSendCrowd: "全部",
    manSendCrowd: "男",
    madamSendCrowd: "女",
    stateless: "--",
    true: "是",
    false: "否",
    shelfText: "请勾选文案",
    shelfImg: "请勾选分享图"
  },
  coachlayout: {
    sex: {
      male: "男",
      female: "女"
    },
    hint:
      "TIPS：如果未注册YESOUL SPROTS，需要在APP端注册该email地址帐号。点击读取帐号信息后，自动读取头像、性别、姓名。",
    hintErr: {
      qualification: "tips:可输入多个，通过‘,’区隔，不超过40字符。",
      adept: "tips:输入1类课程，Enter确认添加，建议不超过3个",
      manifesto: "tips:输入不超过240字符。",
      info: "tips:输入不超过240字符。"
    },
    field: {
      account: "教练账号",
      profession: "职称",
      qualification: "资质",
      adept: "擅长领域",
      manifesto: "教练宣言",
      info: "教练介绍",
      coverPic: "课程封面缺省图",
      infoPic: "课程详情缺省图",
      header: "头像",
      sex: "性别",
      name: "名字"
    },
    errorText: {
      account: "请输入教练账号",
      profession: "请输入职称",
      qualification: "请输入资质",
      adept: "请输入擅长领域",
      manifesto: "请输入教练宣言",
      info: "请输入教练介绍",
      coverPic: "请添加课程封面缺省图",
      infoPic: "请添加课程详情缺省图"
    },
    formSearch: {
      email: [{ required: true, message: "请输入教练账号", trigger: "blur" }],
      title: [{ required: true, message: "请输入职称", trigger: "blur" }],
      achievement: [{ required: true, message: "请输入资质", trigger: "blur" }],
      expertise: [
        { required: true, message: "请输入擅长课程", trigger: "blur" }
      ],
      signText: [
        { required: true, message: "请输入教练宣言", trigger: "blur" }
      ],
      introduction: [
        { required: true, message: "请输入教练介绍", trigger: "blur" }
      ],
      coverPic: [
        { required: true, message: "请添加课程封面缺省图", trigger: "blur" }
      ],
      infoPic: [
        { required: true, message: "请添加课程详情缺省图", trigger: "blur" }
      ]
    },
    size: {
      cover: "建议尺寸：1029*579",
      info: "建议尺寸：1125*786"
    },
    btn: {
      success: "确定",
      cancel: "取消",
      delete: '删除',
      readAccount: "读取账号信息"
    }
  },
  optionsByFree: [{ label: "全部", value: '' }, { label: "VIP", value: 1 }, { label: "免费", value: 2 }],
  RecommendedCourse: RecommendedCourse,
  reducedFatModel: reducedFatModel,
  bbsqianlongModel: bbsqianlongModel,
  programList: programList,
  faqmaneger: faqmaneger,
  riding: riding,
	
	//2.2.5新增  挑战活动
	dareActivity: {
	  btn: {
		sort: '排序',
		shelf1: '上架',
		shelf2: '下架',
		create: '创建'
	  },
	  field: {
		id: 'ID',
		idPlaceholder:'请输入活动ID',
		name: '名称',
		namePlaceholder:'请输入活动名称',
		time: '时间',
		selctTime:'请选择时间',
		effective:'有效期',
		type: '类型',
		status: '状态',
		order:'顺序',
		selectStatus:'请选择状态',
		target1: '目标1',
		targetSize1: '达成人数(真实)1',
		target2: '目标2',
		targetSize2: '达成人数(真实)2',
		target3: '目标3',
		targetSize3: '达成人数(真实)3',
		realNumber:'真实参与人数',
		option: '操作',
		cover: '封面图',
		target: '目标',
		targetTwo: '目标2',
		targetThree: '目标3',
		reachSize: '注水设置',
		waterFlooding:'注水系数',
		explain: '介绍',
		end:'已结束',
		onShelf:'上架',
		offShelf:'下架',
		all:'全部',
	  },
	  badges:'目标徽章',
	  badgesW:'108',
	  badgesH:'138',
	  targinType:'请选择挑战类型',
	  badgeImgRequire:'请选择目标徽章',
	  coverSize: '建议尺寸',
	  targinPlaceholder: '请填写目标值',
	  targinErrOne: '目标2需大于目标1',
	  targinErrTwo: '目标3需大于目标2',
	  titleRequire: '请输入活动名称',
	  bgImgRequire: '请上传封面图',
	  actTimeRequire: '请选择活动时间',
	  decRequire: '请填写活动说明',
	  coefficient: '请输入注水系数',
	  startplaceholder: '开始日期',
	  endplaceholder: '结束日期',
	  act: '活动',
	  edit: '编辑',
	  creat: '创建',
	  view: '查看',
	  delete:'删除',
	  creatTitle:'创建挑战活动',
	  editTitle:'编辑挑战活动',
	  viewTitle:'查看挑战活动',
	  actTypes: {
		1: '里程挑战', 2: '时长挑战', 3: '天数挑战', 4: '次数挑战', 5: '卡路里挑战'
	  },
	  tips: {
		one: '1、可选配置。',
		two: '2、算法：前端展示的参与者人数 = 真实参与人数*后台注水系数'
	  },
	  unit: { 1: '公里',2: '分钟',3: '天',4: '次',5: '卡路里'},
	  hint: {
		name: '不允许超过11个汉字。',
		time: '建议使用周/月/季度作为挑战周期',
		explain: '输入内容限定字符数:(500字符)'
	  }
	},
  battlepass: {
    activityName: '活动名称',
    userTag: '用户标签',
    applyTime: '活动报名时间',
    time: '活动进行时间',
    status: '状态',
    createActivity: '创建活动',
    editActivity: '编辑活动',
    checkActivity: '查看活动',
    form: {
      information: '活动信息',
      backgroundPicture: '活动背景页图',
      rulesPicture: '规则页',
      certificatePicture: '完成证书背景图片',
      activityPicture: '活动中心图',
      smallPicture: '活动小图',
      themeColor: '主题颜色',
      bgColor: '背景底色',
      protocolColor: '规则颜色',
      timeTypes: {
        1: '自然时间', 2: '固定天数（7*24小时）', 3: '固定天数（次日生效）',
      },
      configTitle: '目标/奖励配置',
      targetType: '活动目标类型',
      qualification: '限制条件',
      target: '目标',
      targetQualification: '达成目标限制条件（可多选，必填其一）',
      timeLabel: '时长',
      kacl: '卡路里',
      mileage: '里程',
      unit: {
        km: '公里',
        kacl: '卡路里',
        day: '天',
        minute: '分钟',
        time: '次'
      },
      isAppointedCont: '是否指定内容',
      yes: '是',
      no: '否',
      appointedCont: '指定内容',
    },
  }
};
