import Layout from '@/layout'
const Coachmanager = [
	//教练模块
	{
		path: '/coachmanager',
		name: 'COACH_MANAGER',
		component: Layout,
		redirect: '/coachlayout/list',
		meta: {
		  title: 'coachmanager',
		  icon: 'table'
		},
		children: [{
		  path: '/coachlayout',
		  name: 'COACH_LAYOUT',
		  hidden: true,
		  meta: {
			title: 'coachmanager',
			icon: 'table'
		  },
		  component: () => import('@/views/coachmanager/index'),
		  redirect: '/coachlayout/list'
		}, {
		  path: '/coachlayout/list',
		  name: 'COACH_MANAGER_LIST',
		  component: () =>
			import('@/views/coachmanager/list/index'),
		  meta: {
			title: 'coachlist',
			icon: 'table'
		  }
		},
      {
        path: '/coachlayout/add',
        name: 'COACH_MANAGER_ADD',
        hidden: true,
        component: () =>
          import('@/views/coachmanager/create/index'),
        meta: {
          title: 'coachAdd',
          icon: 'table'
        }
      },
		// 教练团列表coachingstafflist
		{
		  path: '/coachlayout/coachingstafflist',
		  name: 'COACHINGSTAFFLIST',
		  component: () =>
			import('@/views/coachmanager/coachingstafflist/index'),
		  meta: {
			title: 'coachingstafflist',
			icon: 'table'
		  }
		},
		{
		  path: '/coachlayout/creatcoachingstaff',
		  name: 'CREATCOACHINGSTAFF',
		  hidden: true,
		  component: () =>
			import('@/views/coachmanager/coachingstafflist/creat'),
		  meta: {
			title: 'creatcoachingstaff',
			icon: 'table'
		  }
		}, {
		  path: '/coachlayout/editcoachingstaff/:id',
		  name: 'EDITCOACHINGSTAFF',
		  hidden: true,
		  component: () =>
			import('@/views/coachmanager/coachingstafflist/edit'),
		  meta: {
			title: 'editcoachingstaff',
			icon: 'table'
		  }
		},
		{
		  path: '/coachlayout/checkcoachingstaff/:id',
		  name: 'CHECK_COACH_GROUP',
		  hidden: true,
		  component: () =>
			import('@/views/coachmanager/coachingstafflist/checkCoachGroup'),
		  meta: {
			title: 'editcoachingstaff',
			icon: 'table'
		  }
		},
		// 配置教练
		{
		  path: '/coachlayout/addcoach',
		  name: 'COACH_CONFIG_ADD',
		  hidden: true,
		  component: () =>
			import('@/views/coachmanager/creatcoach/index'),
		  meta: {
			title: 'configmanager',
			icon: 'user'
		  }
		},
		// 编辑教练
		{
		  path: '/coachlayout/editcoach',
		  name: 'COACH_CONFIG_EDIT',
		  hidden: true,
		  component: () =>
			import('@/views/coachmanager/editcoach/index'),
		  meta: {
			title: 'editmanager',
			icon: 'user'
		  }
		},
		{
		  path: '/coachlayout/coachdetails',
		  name: 'COACH_MANAGER_DETAIL',
		  hidden: true,
		  component: () =>
			import('@/views/coachmanager/coachdetails/index'),
		  meta: {
			title: 'coachdetails',
			icon: 'user'
		  }
		}
		]
	  },
]

export default Coachmanager
