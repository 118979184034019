const reducedFatModel = {
	shelf1: '上架',
	shelf2: '下架',
	sortBtn: '排序',
	deletbtn: '删除',
	recordImport: '从录播课导入',
	toIssueTheImportCourse: '从往期课程导入',
	editTitle: '编辑课程',
	viewTitle: '编辑课程',
	searchData: [
		{ label: "课程ID", prop: "courseId", placeholder: '请输入课程ID' },
		{ label: "课程名称", prop: "courseName", placeholder: '请输入课程名称' },
		{
			label: "器械类型",
			prop: "deviceType",
			type: "select",
			option: [
				{ label: "全部", value: '' },
				{ label: "单车", value: '1' },
				{ label: "无器械", value: '2' },
				// { label: "瑜伽", value: '3' },
			]
		},
		{
			label: "课程时长",
			prop: "duration",
			type: "select",
			option: [
				{ label: "全部", value: '' },
				{ label: '7分钟', value: 7 * 60 },
				{ label: '10分钟', value: 10 * 60 },
				{ label: "15分钟", value: 15 * 60 },
				{ label: "20分钟", value: 20 * 60 },
				{ label: "30分钟", value: 30 * 60 },
				{ label: "45分钟", value: 45 * 60 },
				{ label: "60分钟", value: 60 * 60 },
				{ label: "90分钟", value: 90 * 60 }
			]
		},
		{
			label: "课程标签",
			prop: "courseTag",
			type: "select",
			option: [
				// { label: "全部", value: '' },
				// { label: "激活身体", value: '1' },
				// { label: "有氧基础", value: '2' },
				// { label: "有氧效率", value: '3' },
				// { label: "有氧耐力", value: '4' },
				// { label: "心肺基础", value: '5' },
			]
		},
		{
			label: "状态",
			prop: "shelf",
			type: "select",
			option: [
				{ label: "全部", value: '' },
				{ label: "上架", value: '1' },
				{ label: "下架", value: '0' }
			]
		},
	],
	tableColumn: [
		{ label: "课程ID", prop: "courseId", type: "normal", width: 120 },
		{ label: "课程名称", prop: "courseName", type: "normal" },
		{ label: "器械类型", prop: "deviceType", type: "normal", width: 120 },
		{ label: "课程时长", prop: "duration", type: "normal", width: 80 },
		{ label: "课程标签", prop: "courseTag", type: "normal", width: 120 },
		{ label: "状态", prop: "shelf", type: "normal", width: 80 },
		{
			label: "操作",
			prop: "action",
			type: "button",
			width: "240px",
			button: [
				{ label: "查看", type: "success" },
				{ label: "编辑", type: "primary", limit: "disabled", limitValue: true },
				{ label: "删除", type: "danger", limit: "disabled", limitValue: true }
			],
			method: ["_check", "_edit", "_delete"]
		}
	],
	configurationCourseTableColumn: [
		{ label: "id", prop: "id", type: "normal" },
		// { label: "课程大类", prop: "categoryName", type: "normal" },
		{ label: "课程名称", prop: "courseName", type: "normal" },
		{ label: "课程难度", prop: "courseLevel", type: "normal" },
		{ label: "时长", prop: "courseDuration", type: "normal" },
		{ label: "教练名称", prop: "coachName", type: "normal" },
		{
			label: "课程标签", prop: "courseTag", type: "select", options: [
				// { label: "请选择", value: '' },
				// { label: "激活身体", value: '1' },
				// { label: "有氧基础", value: '2' },
				// { label: "有氧效率", value: '3' },
				// { label: "有氧耐力", value: '4' },
				// { label: "心肺基础", value: '5' },
			]
		},
		// { label: "BMI值", prop: "bmi", type: "select",options:[{label:'111',value:'1'},{label:'2',value:'2'}] },
		// { label: "课程建议", prop: "advice", type: "input",placeholder:'请输入课程建议'},
	],
	addSearchData: [
		{ label: "id", prop: "id", placeholder: '请输入id' },
		{ label: "课程名称", prop: "courseName", placeholder: '请输入课程名称' },
		{ label: "教练", prop: "coach_name", placeholder: '请输入教练姓名' },
		{
			label: "课程大类",
			prop: "category_id",
			type: "select",
			option: [
				{ label: "全部", value: "" },
				{ label: "无器械", value: "0" },
				{ label: "瑜伽", value: "1" },
				{ label: "单车", value: "3" },
			]
		},
		{
			label: "时长",
			prop: "duration",
			type: "select",
			option: [
				{ label: "全部", value: '' },
				{ label: '7分钟', value: 7 * 60 },
				{ label: '10分钟', value: 10 * 60 },
				{ label: "15分钟", value: 15 * 60 },
				{ label: "20分钟", value: 20 * 60 },
				{ label: "30分钟", value: 30 * 60 },
				{ label: "45分钟", value: 45 * 60 },
				{ label: "60分钟", value: 60 * 60 },
				{ label: "90分钟", value: 90 * 60 }
			]
		},
		{
			label: "难度",
			prop: "level_id",
			type: "select",
			option: [
				{ label: "全部", value: "" },
				{ label: "Y1", value: "0" },
				{ label: "Y2", value: "1" },
				{ label: "Y3", value: "2" },
				{ label: "Y4", value: "3" },
				{ label: "Y5", value: "4" },
			]
		}
	],
}
export default reducedFatModel