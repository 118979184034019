import Layout from '@/layout'
const PushNotice = [
	{
		path: '/pushNotice',
		name: 'PUSH_NOTICE',
		component: Layout,
		redirect: '/pushNotice/list',
		meta: {
			title: 'pushNotice',
			icon: 'table'
		},
		children: [{
			path: '/pushNotice/list',
			name: 'PUSH_NOTICE_LIST',
			hidden: true,
			meta: {
				title: 'pushNoticeList',
				icon: 'table'
			},
			component: () => import('@/views/pushNotice/list')
		},{
			path: '/pushNotice/edit',
			name: 'PUSH_NOTICE_EDIT',
			hidden: true,
			meta: {
				title: 'pushNoticeEdit',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/pushNotice/edit'),
		}]
	}
]

export default PushNotice