import Layout from '@/layout'
const BaseConfig = [
  {
    path: '/baseConfig',
    name: 'BASE_CONFIG',
    component: Layout,
    meta: {
      title: 'baseConfig',
      icon: 'user'
    },
    redirect: '/baseConfig/classType',
    children: [
      {
        path: 'classType',
        name: 'CLASS_TYPE',
        meta: {
          title: 'classType',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/baseConfig/classType'),
      },
      {
        path: 'target/:id',
        name: 'TARGET',
        hidden: true,
        meta: {
          title: 'target',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/baseConfig/target'),
      },
      {
        path: 'detailType/:id',
        name: 'DETAIL_TYPE',
        hidden: true,
        meta: {
          title: 'type',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/baseConfig/detailType'),
      },
      {
        path: 'eduAction/:id',
        name: 'EDU_ACTION',
        hidden: true,
        meta: {
          title: 'eduAction',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/baseConfig/eduAction'),
      },
      {
        path: 'addDetailType/:id',
        name: 'ADD_DETAIL_Type',
        hidden: true,
        meta: {
          title: 'addDetailType',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/baseConfig/addDetailType'),
      },
      {
        path: 'editDetailType/:id',
        name: 'EDIT_DETAIL_TYPE',
        hidden: true,
        meta: {
          title: 'editDetailType',
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/baseConfig/editDetailType'),
      },
      {
        path: 'scoreSet',
        name: 'SCORE_SET',
        hidden: true,
        meta: {
          title: 'scoreSet',/*得分设置*/
          icon: 'user',
          noCache: true
        },
        component: () => import('@/views/baseConfig/scoreSet'),
      },
    ]
  }
]

export default BaseConfig
