import request from '@/utils/request'
import { base } from './userBase'

//获取动态模板 列表
export function getWorkList (data) {
	data.filter = `is_templated eq ${data.is_templated} and is_deleted eq 0`
	data.offset = (data.page - 1) * (data.size || 1)
	data.limit = data.size
	return request({
		url: `${base}/works/list?$filter=${data.filter}&$offset=${data.offset || 0}&$limit=${data.limit}`,
		method: 'get'
	})
}

//获取动态模板 count
export function getWorkCount (data) {
	data.filter = `is_templated eq ${data.is_templated}`
	data.offset = (data.page - 1) * (data.size || 1)
	data.limit = data.size
	return request({
		url: `${base}/works/count?$filter=${data.filter}&$offset=${data.offset || 0}&$limit=${data.limit}`,
		method: 'get'
	})
}

//添加动态模板
export function addWorks (data) {
	const params = { ...data }
	return request({
		url: `${base}/works`,
		method: 'post',
		data: params
	})
}

//查询动态模板详情
export function getWorksInfo (ID) {
	return request({
		url: `${base}/works/${ID}`,
		method: 'get'
	})
}

//删除动态模板
export function deleteWorks (id) {
  return request({
    url: `${base}/works/${id}`,
    method: 'delete'
  })
}

//更新动态模板
export function updataWorks (data) {
	const params = { ...data }
	return request({
		url: `${base}/works/${data.id}`,
		method: 'put',
		data: params
	})
}

//预览返回页面地址
export function getPreviewUrl (ID) {
	return request({
		url: `${base}/works/getPreviewUrl/${ID}`,
		method: 'get'
	})
}