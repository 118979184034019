const faqmaneger = {
  description: 'Content description',
  regEmail:'Email address',
  unprocessed: 'Untreated',
  enterUserName: 'input user name',
  enterUserPhone: 'input 用户phoneNumber',
  state: 'Status',
  noRecords: 'no data',
  sureDealWithProblem: 'Are you sure the problem has been solved?',
  feedBackTime: 'Feedback time',
  source: "Source",
  versionNumber: 'Version',
  feedbackType: 'Feedback type',
  feedback: 'Feedback',
  influenceNum: 'Affected persons',
  replyNum: 'Replies',
  feedbackDetails: 'Feedback details',
  replay: 'reply',
  deleteConfirmMsg: 'Are you sure you want to delete this reply?',
  phone: 'Phone',
  screen: 'Screen',
  suggest: 'Suggestion',
  problem: 'Problem',
  replaySuccessMsg: 'Replied successfully',
  editSuccessMsg: 'Edited successfully',
  deleteSuccessMsg: 'successfully deleted',
  successOperation: 'Operation succeeded',
  limitText: 'Limit',
  character: 'characters',
  deletedFeedbackText: 'Deleted feedbacks',
  time: 'Time',
  nickName: 'Nickname',
  replayEmptyMag: 'Reply cannot be empty',
  dealWith: 'handle',
  processed: 'Processed',
  feedBackContent: 'content description',
  registerEmail: 'Registered mailbox',
  contactEmail: 'Contact email'
}
export default faqmaneger
