const RecommendedCourse = {
    del:'删除',
    putShelf:'上架',
    offShelf:'下架',
    sortTable:'排序',
    creatCourse:'创建',
    importCourse:'导入',
    userTag:'用户标签',
    recommendedStandard:'设置推荐标准',
    userID:'ID',
    userName:'名称',
    courseDifficulty:'难度',
    courseType:'大类',
    courseDuration:'时长',
    chargingMethod:'收费方式',
    langs: '分发语种',
    shelf:'状态',
    recommendedCrowd:'推荐人群',
    coursePeople:'上课人数',
    finishRate:'标签 ',
    userNumber:'好评率',
    completionRate:'评价数>',
    courseTime:'开始时间',
    editTime:'修改后将于当日23：00生效',
    MaxFont:'超出字数限制',
    inputTitle:'请输入标题',
    inputIntroduction:'请输入简介',
    cate: '请选择适用品类',
    inputDescription:"请输入说明",
    textAll: '全部',
    textTag: '标签ClassTag',
    searchData: [
        { label: "名称", prop: "courseName", placeholder: "请输入名称" },
        {
            label: "难度",
            prop: "levelId",
            type: "select",
            option: [
                // { label: "全部", value: "" },
                // { label: "一阶", value: '1' },
                // { label: "二阶", value: '2' },
                // { label: "三阶", value: '3' },
                // { label: "四阶", value: '4' },
                // { label: "五阶", value: '5' }
            ]
        },
       /* {
            label: "大类",
            prop: "labels",
            type: "select",
            option: []
        },*/
        // {
        //     label: "标签",
        //     prop: "status",
        //     type: "cascader",
        //     option: [
        //         { label: "不限", value: "" },
        //         { label: "VIP", value: '1' },
        //         { label: "限免", value: '0' }
        //     ]
        // }
    ],

    tableColumn:[
        { label: "ID" , prop: "courseId" , type:"normal"},
        { label: "名称" , prop: "courseName" , type:"normal"},
        { label: "难度" , prop: "levelId" , type:"normal"},
        /*{ label: "大类" , prop: "categoryId" , type:"normal"},*/
        { label: "时长" , prop: "courseDuration" , type:"normal"},
      { label: "分发语种" , prop: "languageAreas" , type:"tag"},
        { label: "状态" , prop: "shelf" , type:"normal"},
       /* { label: "好评率" , prop: "praiseRate" , type:"normal"},
        { label: "标签" , prop: "labels" , type:"normal"},*/
        { label: "操作" , prop: "control" , type:"button",width:"400px",
        button:[
            /*{ label: "查看", type:"primary", prop:"lookList"},
            { label: "编辑标签", type:"success", prop:"editList", limit: "verify", limitValue: true},
            { label: "移除", type:"danger", prop:"removeList", limit: "verify", limitValue: true},*/
            { label: "置顶", type: "primary", prop: "top", labelName: "取消置顶", labelKey: "top", labelValue: false}
        ],
        method: ["_top"]    //"_view","_management","_delete",
        }
    ],
    importSearchData:[
        { label: "id", prop: "id", placeholder: "请输入课程id" },
        {
            label: "难度",
            prop: "level_id",
            type: "select",
            option: [
                // { label: "全部", value: "" },
                // { label: "一阶", value: '0' },
                // { label: "二阶", value: '1' },
                // { label: "三阶", value: '2' }
            ]
        },
        {
            label: "时长",
            prop: "duration",
            type: "select",
            option: [
                { label: "全部", value: '' },
                { label: "15分钟", value: 15*60 },
                { label: "20分钟", value: 20*60 },
                { label: "30分钟", value: 30*60 },
                { label: "45分钟", value: 45*60 },
                { label: "60分钟", value: 60*60 },
                { label: "90分钟", value: 90*60 }
            ]
        },
        /*{
            label: "大类",
            prop: "category_id",
            type: "select",
            option: [
                // { label: "全部", value: "" },
                // { label: "一阶", value: '1' },
                // { label: "二阶", value: '0' },
                // { label: "三阶", value: '2' }
            ]
        }*/
    ],
    importTableColumn:[
        { label: "ID" , prop: "id" , type:"normal"},
        { label: "名称" , prop: "courseName" , type:"normal"},
        { label: "难度" , prop: "courseLevel" , type:"normal"},
        /*{ label: "大类" , prop: "categoryName" , type:"normal"},*/
        { label: "时长" , prop: "courseDuration" , type:"normal"},
        { label: "分发语种" , prop: "languageAreas" , type:"tag"},
    ],
  btn: {
      cancel: '取消',
      save: '保存',
      back: '返回',
      ok: '确定'
  },
  placeholder: {
      def: '请输入',
      beginTime: '开始日期',
      endTime: '结束日期'
  },
  message: {
      success: '保存成功',
      sortSuccess: '排序成功',
      noSelect: '请选择要删除的列表'
  }
}

export default RecommendedCourse
