import request from '@/utils/request'

export function getClassTypeList(){
    return request({
      url:'/courses/categories',
      method: 'get',
    })
  }
/*获取目标列表*/
export function getTargetList(params){
    return request({
      url:'/courses/categories/targets',
      method: 'get',
      params
    })
  }

  export function putTargetList(data,id){
    return request({
      url:`/courses/categories/targets/${id}`,
      method: 'put',
      data
    })
  }

export function postTargetList(data){
  return request({
    url:`/courses/categories/targets`,
    method: 'post',
    data
  })
}

export function putStatus(data,id){
  return request({
    url:`/courses/categories/targets/${id}`,
    method: 'patch',
    data
  })
}

export function getDetailType(params){
  return request({
    url:`/courses/categories/classes`,
    method:'get',
    params
  })
}

export function putTypeStatus(data,id){
  return request({
    url:`/courses/categories/classes/${id}`,
    method:'patch',
    data
  })
}

export function postDetailType(data){
  return request({
    url:`/courses/categories/classes`,
    method: 'post',
    data
  })
}
export function putDetailType(data,id){
  return request({
    url:`/courses/categories/classes/${id}`,
    method: 'put',
    data
  })
}
export function getDetailTypeItem(id){
  return request({
    url:`/courses/categories/classes/${id}`,
    method: 'get'
  })
}


/**
 * 课程大类-分类排序
 * 小分类排序 [PATCH] /courses/categories/classes/sort
 * params  sortList
 */

export function subClassesSort(data){
  return request({
    url:`/courses/categories/classes/sort`,
    method: 'patch',
    data
  })
}
/*教案动作*/

export function getEduList(params){
  return request({
    url:`/courses/categories/words`,
    method: 'get',
    params
  })
}

export function postEduList(data){
  return request({
    url:`/courses/categories/words`,
    method: 'post',
    data
  })
}

export function putEduList(data,id){
  return request({
    url:`/courses/categories/words/${id}`,
    method: 'put',
    data
  })
 }
export function putEduStatus(data,id){
  return request({
    url:`/courses/categories/words/${id}`,
    method: 'patch',
    data
  })
}
/*修改目标 [PUT] /courses/categories/modes/{id}*/
export function editScoreSet(id, data){
  return request({
    url:`/courses/categories/modes/${id}`,
    method: 'put',
    data
  })
}

export function getLevelList(){  //课程难度查询条件
  return request({
    url:'/courses/levels',
    method: 'get',
  })
}









