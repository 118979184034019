import Layout from '@/layout'
const Theme = [{
	path: '/theme',
	name: 'THEME_COURSE',
	component: Layout,
	// meta: {
	// 	title: 'theme',
	// 	icon: 'user'
	// },
	redirect: '/theme/list',
	children: [{
			path: '/theme/list',
			name: 'THEME_LIST',
			meta: {
				title: 'Series',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/theme'),
		},
		{
			path: '/theme/courseConfig/:id',
			name: 'THEME_COURSE_CONFIG',
			hidden: true,
			meta: {
				title: 'Series',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/theme/courseConfig'),
		},
		{
			path: '/theme/addCourseListRecord/:id',
			name: 'ADD_THEME_COURSE_RECORD',
			hidden: true,
			meta: {
				title: 'addThemeCourseRecord',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/theme/addCourseListRecord'),
		},
		{
			path: '/theme/addCourseListUsed/:id',
			name: 'ADD_THEME_COURSE_USED',
			hidden: true,
			meta: {
				title: 'addThemeCourseUsed',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/theme/addCourseListUsed'),
		},
		{
			path: '/theme/checkTheme/:id',
			name: 'THEME_CHECK_THEME',
			hidden: true,
			meta: {
				title: 'checkTheme',
				icon: 'user',
				noCache: true
			},
			component: () => import('@/views/theme/checkTheme'),
		},
		{
			path: '/theme/create',
			name: 'RECORD_CREATE_COURSE',
			hidden: true,
			meta: {
				title: 'createcourse',
				icon: 'user',
				noCache: true
			},
			component: () =>
				import('@/views/livecourses/createcourse/index'),
		},
		{
			path: '/theme/check',
			name: 'THEME_CHECK_COURSE',
			hidden: true,
			meta: {
				title: 'viewCourse',
				icon: 'user',
				noCache: true
			},
			component: () =>
				import('@/views/reviewedcourses/coursedetails/index'),
		}]
	}
]
export default Theme
