import { getTagList} from "@/api/record"; 

const state = {
  tagData:[]
};

const mutations = {
  SET_TAG_DATA: (state, tagData) => {
    state.tagData = tagData;
  },
};

const actions = {
    getTags({ commit }){
    if (state.tagData.length) {
      return new Promise(resolve => {
        resolve(state.tagData);
      });
    } else {
      return new Promise((resolve, reject) => { 
       const params={
          $offset:0,
          $limit:10000,//排序无限大不需要分页
          $orderby:`create_time desc`,
          $filter:`shelf eq 1`
        }
        getTagList(params).then(res => {
          if (res.code == "success") {
            commit("SET_TAG_DATA", res.data.items);
            resolve(res.data.items);
          } else {
            reject("出错了");
          }
        });
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
