import Layout from '@/layout'
const CourseLabel = [
	{
		path: '/courseLabel',
		name: 'COURSE_LABEL',
		component: Layout,
		redirect: '/courseLabel/dimension',
		meta: {
			title: 'courseLabel',
			icon: 'table'
		},
		children: [{
			path: '/courseLabel/dimension',
			name: 'COURSE_LABEL_DIMENSION',
			meta: {
				title: 'courseLabelDimension',
				icon: 'table'
			},
			component: () => import('@/views/courseLabel/dimension')
		},{
			path: '/courseLabel/list',
			name: 'COURSE_LABEL_LIST',
			meta: {
				title: 'courseLabelList',
				icon: 'table'
			},
			component: () => import('@/views/courseLabel/list')
		}]
	}
]

export default CourseLabel