import Layout from '@/layout'
const UserDeviceManager = [
	{
		path: '/userdevice',
		name: 'USER_DEVICE_MANAGER',
		component: Layout,
		redirect: '/userdevice/list',
		// USER_LAYOUT
		meta: {
			title: 'userDeviceManeger',
			icon: 'user'
		},
		children: [
			{
				path: '/userdevice/list',
				name: 'USER_DEVICE_LIST',
				hidden: true,
				component: () =>
					import('@/views/userDevice/list/index'),
				meta: {
					title: 'list',
					icon: 'table'
				}
			}]
	}
]

export default UserDeviceManager
