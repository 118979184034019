import Layout from '@/layout'
//活动管理
const Activitymanager = [
	{
		path: '/activitymanager',
		name: 'ACTIVITY_MANAGER',
		component: Layout,
		redirect: '/activitymanager/challengeactivities/list',
		meta: { title: 'activityManagement', icon: 'example' },
		children: [
			// {
			// 	path: '/activitymanager/defatting/defatting_edit',
			// 	name: 'ACTIVITY_DEFATTING_EDIT',
			// 	hidden: true,//2.2.5关闭 原因 发现该页面之前就未开发完(历史遗留问题)
			// 	meta: { title: '打卡活动', icon: 'table' },
			// 	component: () => import('@/views/activitymanager/defatting/defatting_edit')
			// }, 
			// {
			// 	path: '/activitymanager/defatting/defatting_list',
			// 	name: 'ACTIVITY_DEFATTING_LIST',
			// 	hidden: false,
			// 	meta: { title: '减脂活动', icon: 'table' },
			// 	component: () => import('@/views/activitymanager/defatting/defatting_list')
			// }, 
			{
				path: '/activitymanager/challengeactivities/list',
				name: 'CHALLENGE_ACTIVITIES',
				hidden: false,
				meta: { title: 'challengeActivities', icon: 'table' },
				component: () => import('@/views/activitymanager/challengeactivities/index')//2.2.5新增 挑战活动 列表页
			}, {
				path: '/activitymanager/challengeactivities/edit',
				name: 'ACTIVITY_DEFATTING_EDIT',
				hidden: true,
				meta: { title: '创建挑战活动', icon: 'table' },
				component: () => import('@/views/activitymanager/challengeactivities/Edit')//2.2.5新增 挑战活动 新增页
			}, {
				path: '/activitymanager/defatting/defatting_add',
				name: 'ACTIVITY_DEFATTING_ADD',
				hidden: true,
				meta: { title: '减脂活动添加', icon: 'table' },
				component: () => import('@/views/activitymanager/defatting/defatting_add')
			}, {
				path: '/activitymanager/defatting/actapply_list',
				name: 'ACTIVITY_DEFATTING_ACTAPPLY_LIST',
				hidden: true,
				meta: { title: '减脂活动报名列表', icon: 'table' },
				component: () => import('@/views/activitymanager/defatting/actapply_list')
			}, {
				path: '/activitymanager/defatting/actresult_list',
				name: 'ACTIVITY_DEFATTING_ACTRESULT_LIST',
				hidden: true,
				meta: { title: '挑战审核结果列表', icon: 'table' },
				component: () => import('@/views/activitymanager/defatting/actresult_list')
			}, {
				path: '/activitymanager/defatting/actsocial_list',
				name: 'ACTIVITY_DEFATTING_ACTSOCIAL_LIST',
				hidden: true,
				meta: { title: '活动留言互动', icon: 'table' },
				component: () => import('@/views/activitymanager/defatting/actsocial_list')
			}, {
				path: '/activitymanager/defatting/actreward_list',
				name: 'ACTIVITY_DEFATTING_ACREWARD_LIST',
				hidden: true,
				meta: { title: '活动抽奖', icon: 'table' },
				component: () => import('@/views/activitymanager/defatting/actreward_list')
			},
			// 战令活动
			{
				path: '/activitymanager/battlepass/list',
				name: 'ACTIVITY_BATTLEPASS_LIST',
				meta: { title: '战令活动', icon: 'table' },
				component: () => import('@/views/activitymanager/battlepass/list')
			}, {
				path: '/activitymanager/battlepass/edit',
				name: 'ACTIVITY_BATTLEPASS_EDIT',
				hidden: true,
				meta: { title: '创建活动', icon: 'table' },
				component: () => import('@/views/activitymanager/battlepass/edit')
			},
			{
				path: '/activitymanager/datalist/orderlist',
				name: 'ACTIVITY_ORDER_INDEX',
				meta: { title: '活动订单', icon: 'table' },
				component: () => import('@/views/activitymanager/order/index')
			},
		]

	},
]

export default Activitymanager
